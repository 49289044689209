import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CloseSvg from '../images/close-banner.svg';
import { getLocale } from '../utils/locale';

const TEMP_BANNER_KEY = 'temp_banner_close';

class TempBanner extends Component {
  constructor(props) {
    super(props);

    const lastTime = localStorage.getItem(TEMP_BANNER_KEY);
    // 6 hours
    if (lastTime && Date.now() - lastTime < 1000 * 60 * 60 * 6) {
      props.hideTempBanner();
    }
  }

  closeBanner = () => {
    localStorage.setItem(TEMP_BANNER_KEY, Date.now());
    this.props.hideTempBanner();
  };

  render() {
    const { tempBannerStyle } = this.props;

    return (
      <div className="temp-top-banner" style={{ ...tempBannerStyle }}>
        <div className="ttb-contents">
          <p>
            {getLocale() == 'fr' ? (
              <p>Êtes-vous une femme à la recherche d'un soutien en matière de santé mentale ? Explorez dès aujourd'hui les options de thérapie gratuite proposées par <a href="https://roomforher.ca/fr/" target='_blank'>Espac<em>elle</em></a>.</p>
              ) : (
                <p>Are you a woman looking for mental health support? Explore our complimentary therapy options through Room for Her today at <a href="https://roomforher.ca/" target='_blank'>roomforher.ca</a>.</p>
            )}
          </p>
        </div>
        <button
          className="ttb-close-button"
          onClick={() => this.closeBanner()}
          tabIndex="0"
          type="button"
        >
          <img src={CloseSvg} alt="" />
        </button>
      </div>
    );
  }
}

TempBanner.propTypes = {
  hideTempBanner: PropTypes.func.isRequired,
  tempBannerStyle: PropTypes.object.isRequired,
};

export default TempBanner;
