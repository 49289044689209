const LOCALE_KEY = 'selectedLanguage';

export function getLocale() {
  let selectedLanguage = 'en';
  if (typeof window !== 'undefined') {
    selectedLanguage = localStorage.getItem(LOCALE_KEY);
  }

  return selectedLanguage;
}

export const setLanguage = lang => {
  localStorage.setItem(LOCALE_KEY, lang);
  window.location.reload();
};