import React from 'react';
import PropTypes from 'prop-types';
import hamburgerBlack from '../../images/_hamburger.svg';

const ButtonStyle = {
  background: 'none',
}

function Hamburger({ onClick, customHamburger }) {
  return (
    <div className="navigation-bar-right-mobile">
      <button
        role="presentation"
        onClick={onClick}
        style={ButtonStyle}
      >
        <img
          style={{ marginTop: '5px', cursor: 'pointer' }}
          src={customHamburger || hamburgerBlack}
          alt="hamburger"
        />
      </button>
    </div>
  )
}

Hamburger.propTypes = {
  onClick: PropTypes.func,
  hamburger: PropTypes.string,
};
Hamburger.defaultProps = {
  onClick: () => { },
  hamburger: hamburgerBlack
};

export default Hamburger;
