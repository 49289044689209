/* eslint-disable no-return-assign */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { MAILCHIMP_URL } from '../../utils/environment';
import DataLoadingSpinner from '../DataLoadingSpinner';

// a basic form
const CustomForm = ({ status, onValidated, message }) => {
  let email;
  let fname;
  let lname;
  let country;
  const submit = () =>
    email &&
    fname &&
    lname &&
    country &&
    email.value.indexOf('@') > -1 &&
    onValidated({
      EMAIL: email.value,
      FNAME: fname.value,
      LNAME: lname.value,
      COUNTRY: country.value,
    });

  return (
    <div>
      {status === 'success' ? (
        <div>
          <p style={{ fontWeight: 700, marginTop: '50px' }}>
            <FormattedMessage
              id="meditation.thanks"
              defaultMessage="Thanks for registering for Inkblot Meditation."
            />
          </p>
          <p>
            <FormattedMessage
              id="meditation.shortly"
              defaultMessage="You will receive an email in a few days with details of upcoming sessions."
            />
          </p>
          <Link
            to="/"
            className="sign-up-button pink-gradient-button"
            style={{ marginTop: '50px' }}
          >
            <FormattedMessage
              id="meditation.goToInkblot"
              defaultMessage="Go to Inkblot"
            />
          </Link>
        </div>
      ) : (
        <div className="form-container sign-up-form">
          <div className="input-row">
            <div className="input-container">
              <p className="label">
                <FormattedMessage id="auth.firstName" />
              </p>
              <input
                type="text"
                name="name"
                placeholder="Isabelle"
                ref={node => (fname = node)}
              />
            </div>
            <div className="input-container">
              <p className="label">
                <FormattedMessage id="auth.lastName" />
              </p>
              <input
                type="text"
                name="name"
                placeholder="Herrera"
                ref={node => (lname = node)}
              />
            </div>
          </div>
          <div className="input-row">
            <div className="input-container full-width-input">
              <p className="label">
                <FormattedMessage id="auth.email" />
              </p>
              <input
                type="text"
                placeholder="Your email"
                ref={node => (email = node)}
              />
            </div>
          </div>
          <div className="input-row">
            <div className="input-container full-width-input">
              <p className="label">
                <FormattedMessage id="auth.countrySelection" />
              </p>
              <select
                type="text"
                placeholder="Your country"
                ref={node => (country = node)}
                className="referral-dropdown"
              >
                <option value="Canada" >Canada</option>
                <option value="United States" >United States</option>
              </select>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <button
              onClick={submit}
              className="sign-up-button pink-gradient-button"
            >
              <FormattedMessage
                id="meditation.register"
                defaultMessage="Register"
              />
            </button>
            {status === 'sending' &&
              <div style={{ visibility: 'visible', marginBottom: '35px' }}>
                <DataLoadingSpinner />
              </div>
            }
          </div>
          <p className={status === 'error' ? 'error-text' : 'hidden'}>
            {message}
          </p>
        </div>
      )}
    </div>
  );
};

const MailchimpSignup = () => (
  <div>
    <MailchimpSubscribe
      url={MAILCHIMP_URL}
      render={({ subscribe, status, message }) => (
        <CustomForm
          status={status}
          message={message}
          onValidated={formData => subscribe(formData)}
        />
        )}
    />
  </div>
);

CustomForm.propTypes = {
  status: PropTypes.string.isRequired,
  onValidated: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default MailchimpSignup;
