// Logos
import wellLogo from '../../images/custom/logos/well-logo.png';

const referrals = [
  // well patients through website
  {
    marketingURL: 'wellreferral',
    path: 'wellreferral',
    title: 'Well',
    logo: wellLogo,
  },
  // well employees
  {
    marketingURL: 'well',
    path: 'wellemployee',
    title: 'Well',
    logo: wellLogo,
  },
  // wellposter is a source saying that sign up is through through posters
  {
    marketingURL: 'inkwell',
    path: 'wellposter',
    title: 'Well',
    logo: wellLogo,
  },
  // wellreferral through docotrs handout
  {
    marketingURL: 'welldoctor',
    path: 'welldoctor',
    title: 'Well',
    logo: wellLogo,
  },
];

export default referrals;
