/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useStaticQuery, graphql } from 'gatsby';
import seoData from './seoData';
import laptopMain from '../images/laptop-main.png';
import Favicon from '../images/favicons/favicon-96x96.png';

const SEO = ({ title, description, lang, pathname, image }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl: url
          defaultImage: image
          twitterUsername
        }
      }
    }
  `);

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || defaultImage,
    url: `${siteUrl}${pathname || '/'}`,
  };

  const stripTrailingSlash = str => {
    if (str.substr(-1) === '/') {
      return str.substr(0, str.length - 1);
    }
    return str;
  };

  return (
    <Helmet
      title={seo.title}
      titleTemplate={titleTemplate}
      htmlAttributes={{ lang }}
      link={[{ rel: 'icon', type: 'image/ico', href: Favicon }]}
      meta={[
        // Basic SEO
        {
          name: 'title',
          content: title || defaultTitle,
        },
        {
          name: 'description',
          content: description || defaultDescription,
        },
        {
          name: 'image',
          content: image || laptopMain,
        },
        // OpenGraph tags
        {
          name: 'og:url',
          content: pathname
            ? `${seoData.default.url}${stripTrailingSlash(pathname)}`
            : seoData.default.url,
        },
        {
          name: 'og:title',
          content: title || seoData.default.title,
        },
        {
          name: 'og:description',
          content: description || defaultDescription,
        },
        {
          name: 'og:image',
          content: image || laptopMain,
        },
        {
          name: 'og:image:alt',
          content: title || defaultTitle,
        },
        // Twitter Card tags
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:creator',
          content: '@inkblottherapy',
        },
        {
          name: 'twitter:title',
          content: title || seoData.default.title,
        },
        {
          name: 'twitter:description',
          content: description || seoData.default.description,
        },
        {
          name: 'twitter:image',
          content: image || laptopMain,
        },
        {
          name: 'google-site-verification',
          content: 'by8nCJD0DRxUcYJdG11iJyl5jUDJO1fAPahhWmsKVFw',
        }
      ]}
    />
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }),
};

SEO.defaultProps = {
  title: '',
  description: null,
  image: null,
  lang: 'en',
};

export default SEO;
