/* eslint-disable max-len */
// Logos
import React from 'react';
import { COUNTRY } from '../../utils/environment';

import PurposeMedLogo from '../../images/custom/logos/purpose-med-logo.png';
import SuttonLogo from '../../images/custom/logos/sutton-logo.jpeg';
import FppmEmployeeLogo from '../../images/custom/logos/fppm-e-logo.jpeg';
import ModernNiagaraLogo from '../../images/custom/logos/modern-niagara-logo.png';
import ShawLogo from '../../images/custom/logos/shaw-logo.jpeg';
import LeicaLogo from '../../images/custom/logos/leica-logo.png';
import StorageSystemLogo from '../../images/custom/logos/3d-storage-logo.png';
import ThriveLogo from '../../images/custom/logos/thrive-logo.png';
import EssentialLogo from '../../images/custom/logos/essential-logo.jpeg';
import BouchierGroupLogo from '../../images/custom/logos/bouchier-logo.jpeg';
import BluMetricLogo from '../../images/custom/logos/blu-metric-logo.jpeg';
import NDSLogo from '../../images/custom/logos/nds-logo.png';
import DeltaQLogo from '../../images/custom/logos/deltaq-logo.jpeg';
import NaesLogo from '../../images/custom/logos/naes-logo.png';
import VictaulicLogo from '../../images/custom/logos/victaulic-logo.png';
import BluenoseLogo from '../../images/custom/logos/bluenose-logo.jpeg';
import FWHLogo from '../../images/custom/logos/fwh-logo.png';
import MindseaLogo from '../../images/custom/logos/mindsea-logo.png';
import RoyalBeefLogo from '../../images/custom/logos/royal-beef-logo.png';
import ReachLogo from '../../images/custom/logos/reach-logo.jpeg';
import WallLogo from '../../images/custom/logos/wall-logo.png';
import EdmontonElksLogo from '../../images/custom/logos/edmontonElksLogo.png';
import AdvicaLogo from '../../images/custom/logos/advica-logo.jpeg';
import InconfidenceEnLogo from '../../images/custom/logos/inconfidence-en-logo.png';
import InconfidenceFrLogo from '../../images/custom/logos/inconfidence-fr-logo.png';
import EurekaLogo from '../../images/custom/logos/eureka-logo.png';
import mapleleaffoodsLogo from '../../images/custom/logos/mapleleaffoods-logo.png';
import atbLogo from '../../images/custom/logos/atb-colour-logo.jpg';
import dmzLogo from '../../images/custom/logos/dmz-logo.png';
import velocityLogo from '../../images/custom/logos/velocity-logo.png';
import pelmorexLogo from '../../images/custom/logos/pelmorex-logo.png';
import prosperLogo from '../../images/custom/logos/prosper-logo.png';
import uberLogo from '../../images/custom/logos/uber-logo.png';
import wellLogo from '../../images/custom/logos/well-logo.png';
import esgLogo from '../../images/custom/logos/esg-logo.png';
import partnerLogo from '../../images/custom/logos/partner-logo.png';
import actoLogo from '../../images/custom/logos/acto-logo.png';
import streetContxtLogo from '../../images/custom/logos/street-contxt-logo.png';
import loopioLogo from '../../images/custom/logos/loopio-logo.png';
import partnerStackLogo from '../../images/custom/logos/partnerstack-logo.svg';
import leagueLogo from '../../images/custom/logos/league-logo.svg';
import indigoLogo from '../../images/custom/logos/indigo-logo.png';
import diamondLogo from '../../images/custom/logos/diamond-logo.png';
import techstarsLogo from '../../images/custom/logos/techstars-logo.png';
import tribalscaleLogo from '../../images/custom/logos/tribalscale-logo.png';
import sleepCountryLogo from '../../images/custom/logos/sleep-country-logo.png';
import tspLogo from '../../images/custom/logos/TSP_Logo.jpg';
import ratioLogo from '../../images/custom/logos/ratio-city-logo.png';
import afiliasLogo from '../../images/custom/logos/afilias-logo.png';
import reunionLogo from '../../images/custom/logos/reunion-logo.png';
import tebcoLogo from '../../images/custom/logos/tebco-logo.jpg';
import flipgiveLogo from '../../images/custom/logos/flipgive-logo.jpg';
import uhfLogo from '../../images/custom/logos/uhf-logo.jpg';
import iqOfficesLogo from '../../images/custom/logos/iq-offices-logo.png';
import minesenseLogo from '../../images/custom/logos/minesense-logo.png';
import questicaLogo from '../../images/custom/logos/questica-logo.png';
import morguardLogo from '../../images/custom/logos/morguard-logo.jpg';
import thinkingApeLogo from '../../images/custom/logos/thinking-ape-logo.png';
import fortressLogo from '../../images/custom/logos/fortress-logo.png';
import blanclabsLogo from '../../images/custom/logos/blanclabs-logo.png';
import hoemLogo from '../../images/custom/logos/hoem-logo.png';
import mywellnessLogo from '../../images/gallivan/_myWellnessLogo.png';
import dreamLogo from '../../images/custom/logos/dream-logo.png';
import citronLogo from '../../images/custom/logos/citron-logo.png';
import valleyviewLogo from '../../images/custom/logos/valleyview-logo.jpg';
import forestviewLogo from '../../images/custom/logos/forestview-logo.jpg';
import sciLtdLogo from '../../images/custom/logos/sci-ltd-logo.png';
import carbon60Logo from '../../images/custom/logos/carbon60-logo.png';
import newtopiaLogo from '../../images/custom/logos/newtopia-logo.png';
import csaLogo from '../../images/custom/logos/csa-logo-en.jpg';
import csaLogoFr from '../../images/custom/logos/csa-logo-fr.jpg';
import sinkingShipLogo from '../../images/custom/logos/sinking-ship-logo.png';
import ioofLogo from '../../images/custom/logos/ioof-logo.png';
import csLogo from '../../images/custom/logos/cs-logo.jpg';
import kingcoleducksLogo from '../../images/custom/logos/king-cole-ducks-logo.jpg';
import ochLogo from '../../images/custom/logos/och-logo.png';
import brightandearlyLogo from '../../images/custom/logos/brightandearly-logo.png';
import thinkonLogo from '../../images/custom/logos/thinkon-logo.png';
import weLogo from '../../images/custom/logos/we-logo.png';
import bonfireLogo from '../../images/custom/logos/bonfire-logo.png';
import sensibillLogo from '../../images/custom/logos/sensibill-logo.jpg';
import varicentLogo from '../../images/custom/logos/varicent-logo.png';
import caesarsLogo from '../../images/custom/logos/caesars-logo.png';
import intellijointLogo from '../../images/custom/logos/intellijoint-logo.png';
import ncaLogo from '../../images/custom/logos/nca-logo.png';
import rbLogo from '../../images/custom/logos/rb-logo.png';
import sunwingLogo from '../../images/custom/logos/sunwing-logo.png';
import edealerLogo from '../../images/custom/logos/edealer-logo.png';
import cosmoLogo from '../../images/custom/logos/cosmo-logo.jpg';
import grewLogo from '../../images/custom/logos/gcrew-logo.png';
import alliancefranchiseLogo from '../../images/custom/logos/alliance-franchise-logo.png';
import canadiantireLogo from '../../images/custom/logos/canadian-tire-logo.png';
import ibigroupLogo from '../../images/custom/logos/ibi-group-logo.jpg';
import intelexLogo from '../../images/custom/logos/intelex-logo.png';
import bciLogo from '../../images/custom/logos/bci-logo.png';
import cdlsiLogo from '../../images/custom/logos/cdlsi-logo.png';
import appcentricaLogo from '../../images/custom/logos/appcentrica-logo.png';
import campbrainLogo from '../../images/custom/logos/campbrain-logo.jpg';
import kiraSystemsLogo from '../../images/custom/logos/kira-systems-logo.png';
import baycrestLogo from '../../images/custom/logos/baycrest-logo.png';
import aeconLogo from '../../images/custom/logos/aecon-logo.png';
import willfulLogo from '../../images/custom/logos/willful-logo.png';
import canadalearningcodeLogo from '../../images/custom/logos/canadalearningcode-logo.png';
import endyLogo from '../../images/custom/logos/endy-logo.jpg';
import securitycompassLogo from '../../images/custom/logos/securitycompass-logo.png';
import hvInvestmentsLogo from '../../images/custom/logos/hv-investments-logo.jpg';
import hvPropertiesLogo from '../../images/custom/logos/hv-properties-logo.jpg';
import insightLogo from '../../images/custom/logos/insight-logo.png';
import dsb1Logo from '../../images/custom/logos/dsb1-logo.png';
import ocpLogo from '../../images/custom/logos/ocp-logo.png';
import daviesLogo from '../../images/custom/logos/davies-logo.jpg';
import fireflyLogo from '../../images/custom/logos/firefly-logo.jpeg';
import unifiedAlloysLogo from '../../images/custom/logos/unified-alloys-logo.png';
import altusLogo from '../../images/custom/logos/altus-logo.png';
import cglLogo from '../../images/custom/logos/cgl-logo.png';
import moseyLogo from '../../images/custom/logos/mosey-logo.jpg';
import yorkLogo from '../../images/custom/logos/york-logo.jpg';
import kearneyLogo from '../../images/custom/logos/kearney-logo.png';
import thgLogo from '../../images/custom/logos/thg-logo.png';
import niLogo from '../../images/custom/logos/ni-logo.jpg';
import benchLogo from '../../images/custom/logos/bench-logo.png';
import fullyManagedLogo from '../../images/custom/logos/fully-managed-logo.png';
import benevityLogo from '../../images/custom/logos/benevity-logo.png';
import humiLogo from '../../images/custom/logos/humi-logo.png';
import kohoLogo from '../../images/custom/logos/koho-logo.png';
import fugroLogo from '../../images/custom/logos/fugro-logo.jpg';
import brandHeroesLogo from '../../images/custom/logos/brandhero-logo.jpeg';
import flippLogo from '../../images/custom/logos/flipp-logo.png';
import cnbLogo from '../../images/custom/logos/cnb-logo.jpg';
import kasianLogo from '../../images/custom/logos/kasian-logo.png';
import macgregorsLogo from '../../images/custom/logos/macgregors-logo.jpg';
import nbplLogo from '../../images/custom/logos/nbpl-logo.jpg';
import colliganLogo from '../../images/custom/logos/colligan-logo.jpg';
import mccoLogo from '../../images/custom/logos/mcco-logo.png';
import velaLogo from '../../images/custom/logos/vela-logo.png';
import omvicLogo from '../../images/custom/logos/omvic-logo.jpg';
import wirthLogo from '../../images/custom/logos/wirth-logo.png';
import atpLogo from '../../images/custom/logos/atp-logo.png';
import clearcoLogo from '../../images/custom/logos/clearco-logo.png';
import hooppLogo from '../../images/custom/logos/hoopp-logo.png';
import nanoleafLogo from '../../images/custom/logos/nanoleaf-logo.png';
import viafouraLogo from '../../images/custom/logos/viafoura-logo.png';
import intervalLogo from '../../images/custom/logos/interval-logo.jpg';
import browzeLogo from '../../images/custom/logos/browze-logo.jpeg';
import neoLogo from '../../images/custom/logos/neo-logo.jpg';
import astraLogo from '../../images/custom/logos/astra-logo.png';
import krpLogo from '../../images/custom/logos/krp-logo.jpg';
import bosleyLogo from '../../images/custom/logos/bosley-logo.png';
import covenantHouseLogo from '../../images/custom/logos/covenant-house-logo.png';
import earnestLogo from '../../images/custom/logos/eanest-logo.png';
import earnestSecondLogo from '../../images/custom/logos/earnest-second-logo.png';
import cameronLogo from '../../images/custom/logos/cameron-logo.png';
import cosaLogo from '../../images/custom/logos/cosa-logo.png';
import hansenLogo from '../../images/custom/logos/hanson-logo.png';
import digLogo from '../../images/custom/logos/dig-logo.png';
import swiftLogo from '../../images/custom/logos/swift-logo.png';
import venngageLogo from '../../images/custom/logos/venngage-logo.png';
import publicLogo from '../../images/custom/logos/public-logo.jpeg';
import carscallenLogo from '../../images/custom/logos/carscallen-logo.jpeg';
import onePropertiesLogo from '../../images/custom/logos/one-properties-logo.jpeg';
import canadapoochLogo from '../../images/custom/logos/canadapooch-logo.png';
import oscLogo from '../../images/custom/logos/osc-logo.png';
import stepLogo from '../../images/custom/logos/step-logo.png';
import westJetLogo from '../../images/custom/logos/west-jet-logo.jpg';
import heritageGreenLogo from '../../images/custom/logos/heritage-green-logo.jpg';
import mircomLogo from '../../images/custom/logos/mircom-logo.jpg';
import LumerateLogo from '../../images/custom/logos/lumerate-logo.jpeg';
import rfrkLogo from '../../images/custom/logos/rfrk-logo.png';
import chemtradeLogo from '../../images/custom/logos/chemtrade-logo.png';
import vidyardLogo from '../../images/custom/logos/vidyard-logo.jpeg';
import mountforestfhtLogo from '../../images/custom/logos/mount-forest-family-health-team-logo.png';
import rldatixLogo from '../../images/custom/logos/rldatix-logo.jpeg';
import snapFinancialLogo from '../../images/custom/logos/snap-financial-logo.png';
import staffyLogo from '../../images/custom/logos/staffy-logo.jpg';
import inkblotLogo from '../../images/custom/logos/inkblot-logo.png';
import elevateLogo from '../../images/custom/logos/elevate-logo.png';
import mcdonaldsLogo from '../../images/custom/logos/mcdonalds-logo.png';
import sanofiLogo from '../../images/custom/logos/sanofi-logo.jpg';
import sanofiLogoFr from '../../images/custom/logos/sanofi-logo-fr.jpg';
import synnexLogo from '../../images/custom/logos/synnex-logo.jpeg';
import monerisLogo from '../../images/custom/logos/moneris-logo.png';
import reasononeLogo from '../../images/custom/logos/reasonone-logo.png';
import gscLogoEn from '../../images/custom/logos/gsc-logo.png';
import gscLogoFr from '../../images/custom/logos/gsc-logo-fr.png';
import futurpreneurLogo from '../../images/custom/logos/futurpreneur-logo.png';
import dealmakerLogo from '../../images/custom/logos/dealmaker-logo.png';
import nextDimensionLogo from '../../images/custom/logos/nextdimension-logo.jpg';
import exchangeIndustriesLogo from '../../images/custom/logos/exchangeindustries-logo.png';
import helpWithAddictionsLogo from '../../images/custom/logos/helpWithAddictions-logo.png';
import larwayLogo from '../../images/custom/logos/larway-logo.png';
import chemeLogo from '../../images/custom/logos/cheme-logo.png';
import acctLogo from '../../images/custom/logos/acct-logo.png';
import tealbookLogo from '../../images/custom/logos/tealbook-logo.png';
import bridgeLogo from '../../images/custom/logos/bridge-logo.png';
import leslieGroupLogo from '../../images/custom/logos/leslie-group-logo.jpg';
import snapCommerceLogo from '../../images/custom/logos/snap-commerce-logo.jpg';
import mintLogo from '../../images/custom/logos/mint-logo.png';
import cyclicaLogo from '../../images/custom/logos/cyclica-logo.png';
import borderGlassLogo from '../../images/custom/logos/border-glass-logo.jpeg';
import grantbookLogo from '../../images/custom/logos/grantbook-logo.png';
import flexEDLogo from '../../images/custom/logos/flex-ed-logo.png';
import medullaCoLogo from '../../images/custom/logos/medullaco-logo.png';
import inkboxLogo from '../../images/custom/logos/inkbox-logo.png';
import northpineLogo from '../../images/custom/logos/northpine-logo.png';
import atbPgaLogo from '../../images/custom/logos/atb-pga-logo.jpg';
import benecaidLogo from '../../images/custom/logos/benecaid-logo.png';
import honeybeeLogo from '../../images/custom/logos/honeybee-logo.png';
import benecaidFrLogo from '../../images/custom/logos/benecaid-logo-fr.png';
import honeybeeFrLogo from '../../images/custom/logos/honeybee-logo-fr.png';
import environicsAnalyticsLogo from '../../images/custom/logos/environics-analytics-logo.jpg';
import motionCaresLogo from '../../images/custom/logos/motion-cares-logo.jpeg';
import yorkvilleEducationLp from '../../images/custom/logos/yorkville-education-lp.png';
import theJuneMotelLogo from '../../images/custom/logos/the-june-motel-logo.png';
import myodetoxLogo from '../../images/custom/logos/myodetox-logo.png';
import zuvaLogo from '../../images/custom/logos/zuva-logo.png';
import takeItEasyLogo from '../../images/custom/logos/take-it-easy-logo.png';
import agnicoEagleLogo from '../../images/custom/logos/agnico-eagle-logo.png';
import craftPublicRelationsLogo from '../../images/custom/logos/craft-public-relations-logo.jpeg';
import talentmindedLogo from '../../images/custom/logos/talentminded-logo.jpeg';
import teamsTransportLogo from '../../images/custom/logos/teams-transport-logo.png';
import barcoHoldingsLogo from '../../images/custom/logos/barco-holdings-logo.png';
import roseRocketLogo from '../../images/custom/logos/rose-rocket-logo.png';
import cubertLogo from '../../images/custom/logos/cubert-logo.png';
import associatedambulanceLogo from '../../images/custom/logos/aa-logo.jpeg';
import sangLogo from '../../images/custom/logos/sang-logo.png';
import fikaLogo from '../../images/custom/logos/fika-logo.png';
import mainframeLogo from '../../images/custom/logos/mainframe-logo.jpeg';
import vendorheroLogo from '../../images/custom/logos/notch-logo.png';
import bcspcaLogo from '../../images/custom/logos/bcspca-logo.png';
import austinpowderLogo from '../../images/custom/logos/austinpowder-logo.jpeg';
import createActionLogo from '../../images/custom/logos/createaction-logo.jpg';
import artaLogo from '../../images/custom/logos/arta-logo.png';
import tygusIncLogo from '../../images/custom/logos/tygus-inc-logo.png';
import chitChatsLogo from '../../images/custom/logos/chit-chats-logo.png';
import martinDeerlineLogo from '../../images/custom/logos/martin-deerline-logo.jpg';
import martinMotorSportsLogo from '../../images/custom/logos/martin-motor-sports-logo.jpg';
import cfrLogo from '../../images/custom/logos/cfr-logo.png';
import cfrSecondLogo from '../../images/custom/logos/cfr-second-logo.jpg';
import rfhLogo from '../../images/custom/logos/rfh-logo.png';
import rfhLogoFr from '../../images/custom/logos/rfh-logo-fr.png';
import hansensLogo from '../../images/custom/logos/hansens-go-logo.jpg';
import rexallLogo from '../../images/custom/logos/rexall-logo.jpg';
import turnerLogo from '../../images/custom/logos/turner-logo.png';
import clarkBuildersLogo from '../../images/custom/logos/clark-builders-logo.jpg';
import netXLogo from '../../images/custom/logos/netx-logo.png';
import accesEmploymentLogo from '../../images/custom/logos/acces-employment-logo.jpg';
import lenbrookLogo from '../../images/custom/logos/lenbrook-logo.jpg';
import twigLogo from '../../images/custom/logos/twig-logo.png';
import sidefxLogo from '../../images/custom/logos/sidefx-logo.png';
import hollyfrontierLogo from '../../images/custom/logos/hollyfrontier-logo.png';
import orbisLogo from '../../images/custom/logos/orbis-logo.jpeg';
import gallagherLogo from '../../images/custom/logos/gallagher-logo.png';
import menashaLogo from '../../images/custom/logos/menasha-logo.png';
import creationLogo from '../../images/custom/logos/creation-logo.png';
import cppLogo from '../../images/custom/logos/cpp-logo.png';
import adiLogo from '../../images/custom/logos/adi-logo.jpeg';
import jalfLogo from '../../images/custom/logos/jalf-logo.png';
import amplifonLogo from '../../images/custom/logos/amplifon-logo.png';
import vtrLogo from '../../images/custom/logos/vtr-logo.png';
import imvLogo from '../../images/custom/logos/imv-logo.png';
import junoLogo from '../../images/custom/logos/juno-logo.png';
import johnstoneLogo from '../../images/custom/logos/johnstone-logo.png';
import elementLogo from '../../images/custom/logos/element-logo.png';
import pursuitLogo from '../../images/custom/logos/pursuit-logo.jpeg';
import braneLogo from '../../images/custom/logos/brane-logo.png';
import longueuilLogo from '../../images/custom/logos/longueuil-logo.jpeg';
import iSightLogo from '../../images/custom/logos/i-sight-logo.png';
import stClairLogo from '../../images/custom/logos/st-clair-logo.png';
import bimmLogo from '../../images/custom/logos/bimm-logo.png';
import gfsLogo from '../../images/custom/logos/gfs-logo.png';
import workforcewindsoressexLogo from '../../images/custom/logos/workforcewindsoressex-logo.png';
import clearConceptsLogo from '../../images/custom/logos/clearconcepts-logo.png';
import sentinelLogo from '../../images/custom/logos/sentinel-logo.png';
import demoaccesscompanyLogo from '../../images/custom/logos/demoaccesscompany-logo.png';
import demoaccesspluscompanyLogo from '../../images/custom/logos/demoaccesspluscompany-logo.png';
import demoeapcompanyLogo from '../../images/custom/logos/demoeapcompany-logo.png';
import cccLogo from '../../images/custom/logos/ccc-logo.png';
import widerfunnelLogo from '../../images/custom/logos/widerfunnel-logo.png';
import calgaryzooLogo from '../../images/custom/logos/calgaryzoo-logo.jpg';
import genesisLogo from '../../images/custom/logos/genesis-logo.png';
import selectpathLogo from '../../images/custom/logos/selectpath-logo.jpg';
import etr407Logo from '../../images/custom/logos/407etr-logo.jpeg';
import mcaconsultantsLogo from '../../images/custom/logos/mcaconsultants-logo.jpg';
import mccLogo from '../../images/custom/logos/mcc-logo.png';
import waxmanLogo from '../../images/custom/logos/waxman-logo.jpg';
import parcolympiqueLogo from '../../images/custom/logos/parcolympique-logo.jpg';
import fieldingenvironmentalLogo from '../../images/custom/logos/fieldingenvironmental-logo.png';
import qvbcLogo from '../../images/custom/logos/qvbc-logo.png';
import newmarketLogo from '../../images/custom/logos/newmarket-logo.png';
import avantiLogo from '../../images/custom/logos/avanti-logo.png';
import hrpaLogo from '../../images/custom/logos/hrpa-logo.png';
import ballisticArtsLogo from '../../images/custom/logos/ballisticArts-logo.jpeg';
import cornerstonecourierLogo from '../../images/custom/logos/cornerstonecourier-logo.jpeg';
import oldOakLogo from '../../images/custom/logos/old-oak-logo.jpg';
import impLogo from '../../images/custom/logos/imp-logo.png';
import p4gLogo from '../../images/custom/logos/p4g-logo.png';
import commonGoodSolutionsLogo from '../../images/custom/logos/coomon-good-solutoins-logo.png';
import novAtelLogo from '../../images/custom/logos/novatel-logo.jpg';
import wohsLogo from '../../images/custom/logos/william-osler-health-systems.jpg';
import knitLogo from '../../images/custom/logos/knit-logo.png';
import luxyLogo from '../../images/custom/logos/luxy-logo.png';
import orbitLogo from '../../images/custom/logos/orbit-logo.png';
import blazingLogo from '../../images/custom/logos/blazing-logo.png';
import quasarPratusLogo from '../../images/custom/logos/quasar-pratus-logo.png';
import litcolawLogo from '../../images/custom/logos/litcolaw-logo.png';
import lamsarLogo from '../../images/custom/logos/lamsar-logo.png';
import rocklandLogo from '../../images/custom/logos/rockland-logo.jpg';
import laneFourLogo from '../../images/custom/logos/lanefour-logo.png';
import mlseLogo from '../../images/custom/logos/mlse-logo.png';
import dyspatchLogo from '../../images/custom/logos/dyspatch-logo.png';
import pixelunionLogo from '../../images/custom/logos/pixel-union-logo.png';
import dtssabLogo from '../../images/custom/logos/dtssab-logo.png';
import halifaxPartnershipLogo from '../../images/custom/logos/halifaxPartnership-logo.png';
import ablemploymentLogo from '../../images/custom/logos/ablemployment-logo.png';
import esiLogo from '../../images/custom/logos/esi-logo.png';
import enginuityLogo from '../../images/custom/logos/enginuity-logo.png';
import lyceeLogo from '../../images/custom/logos/lycee-logo.png';
import redbrickLogo from '../../images/custom/logos/redbrick-logo.png';
import fortisLogo from '../../images/custom/logos/fortis-logo.png';
import chathamKentHealthTeamLogo from '../../images/custom/logos/chatham-kent-health-team-logo.png';
import zavitzLogo from '../../images/custom/logos/zavitz-logo.jpg';
import apexprLogo from '../../images/custom/logos/apexpr-logo.png';
import markhamFertilityCentreLogo from '../../images/custom/logos/markham-fertility-centre-logo.png';
import coastalreignLogo from '../../images/custom/logos/coastalreign-logo.png';
import coversLogo from '../../images/custom/logos/covers-logo.jpeg';
import michelsLogo from '../../images/custom/logos/michels-logo.jpg';
import guustoLogo from '../../images/custom/logos/guusto-logo.png';
import newscorpLogo from '../../images/custom/logos/newscorp-logo.png';
import blueJLogo from '../../images/custom/logos/blue-j-logo.png';
import partnershipLogo from '../../images/custom/logos/partnership-logo.png';
import gillforLogo from '../../images/custom/logos/gillfor-logo.jpg';
import thenaturalstepLogo from '../../images/custom/logos/thenaturalstep-logo.png';
import demoDomainCompanyLogo from '../../images/custom/logos/demo-domain-company.png';
import demoIdCompanyLogo from '../../images/custom/logos/demo-id-company.png';
import yellowstoneToYukonConservationInitiativeLogo from '../../images/custom/logos/yellowstone-to-yukon-conservation-initiative-logo.jpg';
import eastpointLogo from '../../images/custom/logos/eastpoint-logo.png';
import champlainLogo from '../../images/custom/logos/champlain-logo.png';
import vhaLogo from '../../images/custom/logos/vha-logo.png';
import hexagonLogo from '../../images/custom/logos/hexagon-logo.jpg';
import concordLogo from '../../images/custom/logos/concord-logo.png';
import phenomicLogo from '../../images/custom/logos/phenomic-logo.png';
import kidsabilityLogo from '../../images/custom/logos/kidsability-logo.jpg';
import mindlanceLogo from '../../images/custom/logos/mindlance-logo.png';
import sunspaceLogo from '../../images/custom/logos/sunspace-logo.png';
import milkmoovementLogo from '../../images/custom/logos/milkmoovement-logo.jpeg';
import springboardclinicLogo from '../../images/custom/logos/springboardclinic-logo.jpeg';
import hrbLogo from '../../images/custom/logos/hrb-logo.png';
import feminuityLogo from '../../images/custom/logos/feminuity-logo.png';
import bluearthLogo from '../../images/custom/logos/bluearth-logo.png';
import wwsLogo from '../../images/custom/logos/wws-logo.jpg';
import tsesteelLogo from '../../images/custom/logos/tsesteel-logo.jpeg';
import saftcabLogo from '../../images/custom/logos/saftcab-logo.jpg';
import semaphoreLogo from '../../images/custom/logos/semaphore-logo.png';
import vanderlandeLogo from '../../images/custom/logos/vanderlande-logo.jpg';
import cmawLogo from '../../images/custom/logos/cmaw-logo.jpg';
import slalomLogo from '../../images/custom/logos/slalom-logo.png';
import marinerLogo from '../../images/custom/logos/mariner-logo.png';
import scopesecurityLogo from '../../images/custom/logos/scopesecurity-logo.png';
import realstarLogo from '../../images/custom/logos/realstar-logo.jpg';
import redthreadLogo from '../../images/custom/logos/rtl-logo.png';
import foremanLogo from '../../images/custom/logos/foreman-logo.png';
import onenetLogo from '../../images/custom/logos/onenet-logo.png';
import alphactLogo from '../../images/custom/logos/alphact-logo.png';
import kellyservicesLogo from '../../images/custom/logos/kelly-services-canada-logo.png';
import silverorangeLogo from '../../images/custom/logos/silver-orange-logo.png';
import accedoLogo from '../../images/custom/logos/accedo-logo.png';
import jccgvLogo from '../../images/custom/logos/jccgv-logo.png';
import sourceenergyLogo from '../../images/custom/logos/sourceenergy-logo.png';
import wcswrLogo from '../../images/custom/logos/wcswr-logo.png';
import controlsequipmentLogo from '../../images/custom/logos/controlsequipment-logo.png';
import cintasFRLogo from '../../images/custom/logos/cintas-fr-logo.jpg';
import cintasLogo from '../../images/custom/logos/cintas-logo.jpg';
import dllLogo from '../../images/custom/logos/dll-logo.jpeg';
import blueprintLogo from '../../images/custom/logos/blueprint-logo.png';
import penmorebenefitsLogo from '../../images/custom/logos/penmorebenefits-logo.png';
import timbercreekLogo from '../../images/custom/logos/timbercreek-logo.png';
import canadagamescenterLogo from '../../images/custom/logos/canadagamescenter-logo.png';
import cineplexenglishLogo from '../../images/custom/logos/cineplexenglish-logo.png';
import cineplexfrenchLogo from '../../images/custom/logos/cineplexfrench-logo.png';
import bryancoLogo from '../../images/custom/logos/bryan-co-logo.jpg';
import connectionsLogo from '../../images/custom/logos/connections-logo.png';
import tecvalcoLogo from '../../images/custom/logos/tecvalco-logo.jpg';
import canadiannaturalresourceslimitedLogo from '../../images/custom/logos/canadian-natural-resources-limited-logo.jpg';
import mapleLogo from '../../images/custom/logos/maple-logo.png';
import smlsLogo from '../../images/custom/logos/smls-logo.png';
import alpineHelicopterLogo from '../../images/custom/logos/alpine-helicopter-logo.jpg';
import fdLogo from '../../images/custom/logos/fd-logo.png';
import brcfLogo from '../../images/custom/logos/BRCF-logo.png';
import niMasterLogo from '../../images/custom/logos/NI-MASTER-logo.png';
import bluIvyLogo from '../../images/custom/logos/bluIvy-logo.png';
import rglLogo from '../../images/custom/logos/RLG-logo.png';
import celLogo from '../../images/custom/logos/cel-logo.jpg';
import bmlLogo from '../../images/custom/logos/bml-logo.jpg';
import canadianBankNoteLogo from '../../images/custom/logos/canadian_bank_note_company_logo.png';
import SLCSLogo from '../../images/custom/logos/SLCS-logo.png';
import TimiskamingHealthUnitLogo from '../../images/custom/logos/TimiskamingHealthUnit-logo.jpg';
import PEHCLogo from '../../images/custom/logos/PEHC-logo.png';
import medaviebsbEnLogo from '../../images/custom/logos/medaviebsb-en-logo.png';
import medaviebsbFrLogo from '../../images/custom/logos/medaviebsb-fr-logo.png';
import pianopianoLogo from '../../images/custom/logos/pianopiano-logo.jpeg';
import EORNLogo from '../../images/custom/logos/eorn-logo.jpg';
import massdmgLogo from '../../images/custom/logos/massdmg-logo.png';
import otfLogo from '../../images/custom/logos/otf-logo.jpg';
import valenceLogo from '../../images/custom/logos/valence-logo.png';
import ArcticGlacierLogo from '../../images/custom/logos/arctic-glacier-logo.jpeg';
import ASEBPLogo from '../../images/custom/logos/ASEBP-logo.png';
import UccLogo from '../../images/custom/logos/ucc-logo.png';
import LLTLogo from '../../images/custom/logos/llt-logo.jpg';
import KoboldLogo from '../../images/custom/logos/kobold-logo.png';
import TegusLogo from '../../images/custom/logos/tegus-logo.jpg';
import TchcLogo from '../../images/custom/logos/tchc-logo.png';
import FPPMLogo from '../../images/custom/logos/fppm-logo.jpg';
import NadaLogo from '../../images/custom/logos/nada-Logo.png';
import LogixxLogo from '../../images/custom/logos/logixx-logo.jpg';
import WorleyLogo from '../../images/custom/logos/worley-logo.jpg';
import GenrusLogo from '../../images/custom/logos/genrus-logo.jpg';
import EcadLogo from '../../images/custom/logos/ecad-logo.png';
import RefuelLogo from '../../images/custom/logos/4refuel-logo.jpg';
import FinningLogo from '../../images/custom/logos/finning-logo.jpg';
import TokioLogo from '../../images/custom/logos/tokio-logo.png';
import RenfrewLogo from '../../images/custom/logos/renfrew-logo.jpeg';
import FreshCityLogo from '../../images/custom/logos/freshcity-logo.png';
import SteamWhistleLogo from '../../images/custom/logos/steamwhistle-logo.png';
import GuardianCapitalLogo from '../../images/custom/logos/guardiancapital-logo.png';
import WajaxLogo from '../../images/custom/logos/wajax-logo.jpeg';
import FSDLogo from '../../images/custom/logos/fsd-logo.png';
import uvLogo from '../../images/custom/logos/uv-logo.png';
import uvFrLogo from '../../images/custom/logos/uv-fr-logo.png';
import EnmaxLogo from '../../images/custom/logos/enmax-logo.jpeg';
import MelchersLogo from '../../images/custom/logos/melchers-logo.jpeg';
import MonarchLogo from '../../images/custom/logos/monarch-house-logo.png';
import FoglerLogo from '../../images/custom/logos/fogler-rubinoff-logo.png';
import CreditCounsellingLogo from '../../images/custom/logos/credit-counselling-society-logo.png';
import RatesLogo from '../../images/custom/logos/rates-logo.png';
import TrilliumLogo from '../../images/custom/logos/trillium-mutual-logo.jpeg';
import NoibuLogo from '../../images/custom/logos/noibu-logo.png';
import FCICLogo from '../../images/custom/logos/fcic-logo.png';
import FCICFrLogo from '../../images/custom/logos/fcic-fr-logo.png';
import StorckLogo from '../../images/custom/logos/storck-logo.jpeg';
import NavBlueLogo from '../../images/custom/logos/navblue-logo.png';
import ChinookLogo from '../../images/custom/logos/chinook-logo.jpeg';
import BurnsideLogo from '../../images/custom/logos/burnside-logo.jpeg';
import EthosLogo from '../../images/custom/logos/ethos-automation-logo.jpeg';
import TravellersInsuranceLogo from '../../images/custom/logos/travellers-insurance-logo.jpeg';
import PrairieMeatsLogo from '../../images/custom/logos/prairie-meats-logo.jpeg';
import AlbertaCollegeLogo from '../../images/custom/logos/alberta-college-of-paramedics-logo.png';
import AlenticLogo from '../../images/custom/logos/alentic-logo.png';
import AudienceViewLogo from '../../images/custom/logos/audience-view-logo.png';
import CairnsLogo from '../../images/custom/logos/cairnsoneil-logo.png';
import TownOfCollingwoodLogo from '../../images/custom/logos/collingwood-logo.gif';
import WildForkLogo from '../../images/custom/logos/wildfork-logo.png';
import LeatherFactoryLogo from '../../images/custom/logos/leatherfactory-logo.jpeg';
import NorrLogo from '../../images/custom/logos/norr-logo.png';
import OliveLogo from '../../images/custom/logos/olive-logo.png';
import AirdrieLogo from '../../images/custom/logos/airdrie-logo.png';
import AcumenexLogo from '../../images/custom/logos/acumenex-logo.jpeg';
import OakenLogo from '../../images/custom/logos/oaken-logo.png';
import WestrockLogo from '../../images/custom/logos/westrock-logo.png';
import FNZLogo from '../../images/custom/logos/FNZ-logo.png';
import CISLogo from '../../images/custom/logos/investigation-logo.png';
import ClearviewLogo from '../../images/custom/logos/clearview-logo.png';
import ShiftLogo from '../../images/custom/logos/shift-logo.png';
import BadalLogo from '../../images/custom/logos/badal-logo.png';
import VillageLogo from '../../images/custom/logos/village-logo.jpeg';
import FreseniusLogo from '../../images/custom/logos/fresenius-logo.jpeg';
import GeocomplyLogo from '../../images/custom/logos/geocomply-logo.png';
import DucaLogo from '../../images/custom/logos/duca-logo.jpeg';
import StickerYouLogo from '../../images/custom/logos/stickeryou-logo.png';
import SaltWireLogo from '../../images/custom/logos/saltwire-logo.jpeg';
import ApartmentLoveLogo from '../../images/custom/logos/apartmentlove-logo.png';
import LVMHLogo from '../../images/custom/logos/lvmh-logo.jpeg';
import StPaulLogo from '../../images/custom/logos/stpaul-logo.png';
import PHSDLogo from '../../images/custom/logos/phsd-logo.jpeg';
import MavenLogo from '../../images/custom/logos/maven-logo.png';
import LululemonLogo from '../../images/custom/logos/lululemon-logo.png';
import HallmarkLogo from '../../images/custom/logos/hallmark-logo.png';
import LoneWolfLogo from '../../images/custom/logos/lone-wolf-logo.jpeg';
import YCULogo from '../../images/custom/logos/ycu-logo.jpeg';
import MMGLogo from '../../images/custom/logos/mmg-logo.png';
import PASLogo from '../../images/custom/logos/pas-logo.jpeg';
import CrayolaLogo from '../../images/custom/logos/crayola-logo.png';
import EricssonLogo from '../../images/custom/logos/ericsson-logo.png';
import ThinkwellLogo from '../../images/custom/logos/thinkwell-logo.png';
import ArbutusLogo from '../../images/custom/logos/arbutus-logo.jpeg';
import ExchangeSolutionsLogo from '../../images/custom/logos/es-logo.png';
import PureLogicLogo from '../../images/custom/logos/purelogic-logo.jpeg';
import HavenLogo from '../../images/custom/logos/haven-logo.jpeg';
import WorldsourceLogo from '../../images/custom/logos/worldsource-logo.jpeg';
import PalliserLogo from '../../images/custom/logos/palliser-logo.jpeg';
import AxisLogo from '../../images/custom/logos/axis-logo.png';
import CountyLogo from '../../images/custom/logos/county-logo.jpeg';
import TelusSparkLogo from '../../images/custom/logos/teluspark-logo.png';
import PCBLogo from '../../images/custom/logos/pcb-logo.png';
import NurevaLogo from '../../images/custom/logos/nureva-logo.png';
import NKSLogo from '../../images/custom/logos/nks-logo.png';
import CIMALogo from '../../images/custom/logos/cima.png';
import ABRLogo from '../../images/custom/logos/abr-logo.jpeg';
import FMPSDLogo from '../../images/custom/logos/FMPSD-logo.png';
import TerrapexLogo from '../../images/custom/logos/terrapex-logo.png';
import BlueberryLogo from '../../images/custom/logos/blueberry-logo.jpeg';
import FoodWaterLogo from '../../images/custom/logos/food-water-logo.png';
import AptosLogo from '../../images/custom/logos/aptos-logo.png';
import AdmareLogo from '../../images/custom/logos/admareLogo.png';
import HaldimandLogo from '../../images/custom/logos/haldimandLogo.png';
import MaraLogo from '../../images/custom/logos/MaraLogo.png';
import SperlingLogo from '../../images/custom/logos/sperlingLogo.png';
import TitanLogo from '../../images/custom/logos/titanLogo.jpeg';
import ActivisionBlizzardLogo from '../../images/custom/logos/activision-blizzard-logo.png';
import AccionaLogo from '../../images/custom/logos/acciona-logo.jpeg';
import DevengLogo from '../../images/custom/logos/deveng-logo.png';
import LongshoreLogo from '../../images/custom/logos/longshore-logo.jpeg';
import CPCSLogo from '../../images/custom/logos/CPCS-logo.png';
import YYCLogo from '../../images/custom/logos/YYC-logo.jpg';
import EngCompLogo from '../../images/custom/logos/engcomp-logo.png';
import EsteeLauderLogo from '../../images/custom/logos/estee-lauder-logo.png';
import TractionRecLogo from '../../images/custom/logos/rec-logo.png';
import newGscLogoEN from '../../images/custom/logos/new-gsc-logo-en.png';
import newGscLogoFR from '../../images/custom/logos/new-gsc-logo-fr.png';

const companies = [
  // Company with Employee ID template
  {
    marketingURL: 'wcl',
    path: 'wcl',
    title: 'Caesars Windsor',
    logo: caesarsLogo,
    logoClass: 'small-logo',
    dependants: true,
    minutes: '5 hours',
    EFAP: true,
    EAP: true,
    employeeIdSignup: true,
  },
  {
    marketingURL: 'canadiantire',
    path: 'canadiantire',
    title: 'Canadian Tire',
    logo: canadiantireLogo,
    logoClass: 'small-logo',
    dependants: true,
    employeeIdSignup: true,
    frenchPage: true,
    subsequentSessions:
      'Subsequent sessions cost just $90/hour which may be covered by your private health insurance, your CTC company benefit plan or paid privately.',
    subsequentSessionsFr:
      'Votre première consultation individuelle est gratuite. Les séances subséquentes ne coûtent que 90 $ l’heure. Elles peuvent être couvertes par votre assurance maladie privée, votre régime de garanties de CTC ou un régime privé.',
  },
  {
    marketingURL: 'cnb',
    path: 'cnb',
    title: 'City of North Bay',
    logo: cnbLogo,
    logoClass: 'small-logo',
    dependants: true,
    employeeIdSignup: true,
    EAP: true,
  },
  {
    marketingURL: 'yorkregion',
    path: 'yorkregion',
    title: 'York Region',
    logo: yorkLogo,
    logoClass: 'wide-logo',
    dependants: true,
    employeeIdSignup: true,
  },
  {
    marketingURL: 'benevity',
    path: 'benevity',
    title: 'Benevity',
    logo: benevityLogo,
    logoClass: 'small-logo',
    employeeIdSignup: true,
    showFlags: true,
    EFAP: true,
    dependants: true,
  },
  {
    path: 'barco',
    marketingURL: 'barco',
    title: 'Barco Materials Handling Ltd.',
    logo: barcoHoldingsLogo,
    logoClass: 'wide-logo',
    employeeIdSignup: true,
    minutes: '180 minutes',
    dependants: true,
  },
  {
    path: 'arta',
    marketingURL: 'arta',
    title: "Alberta Retired Teachers' Association",
    logo: artaLogo,
    logoClass: 'small-logo',
    EAP: true,
    employeeIdSignup: true,
    minutes: '300 minutes',
    dependants: true,
    frenchPage: true,
    hideSignIn: true,
  },
  {
    path: 'cpp',
    marketingURL: 'cpp',
    title: 'Canadian Plate and Profiles Inc.',
    logo: cppLogo,
    employeeIdSignup: true,
    logoClass: 'wide-logo',
    dependants: true,
  },
  {
    path: 'mymentalhealth',
    marketingURL: 'mymentalhealth',
    marketingURLFr: 'masantementale',
    title: 'Lafarge Canada Inc',
    frenchPage: true,
    fullyGated: true,
    dependants: true,
    employeeIdSignup: true,
    yourMentalHealth: (
      <p>
        Your mental health matters and that's why your employer and Inkblot have
        partnered to provide you with confidential and convenient video
        counselling and mental health support.
      </p>
    ),
    yourMentalHealthFr: (
      <p>
        Votre santé mentale est importante, c’est pourquoi votre employeur et
        Inkblot se sont associées pour vous offrir des services de soutien en
        santé mentale et du counseling par vidéo confidentiels et pratiques.
      </p>
    ),
  },
  // demo
  {
    path: 'demoidcompany',
    marketingURL: 'demoidcompany',
    title: 'Demo ID Company',
    logo: demoIdCompanyLogo,
    employeeIdSignup: true,
    logoClass: 'small-logo',
    dependants: true,
    showFlags: true,
    frenchPage: true,
    EAP: true,
  },
  // Access Company template
  // {
  //   BASICS
  //     path: 'path', // This is the same as the customized_url in the database
  //     marketingURL: 'path', // Usually the same as the path but can be customized for companies with multiple portal pages
  //     redirectToURL: 'path', // Redirecting from marketingURL to another url (requires marketingURL and an existing url, see champlain as example )
  //     title: 'Title', // Name of the company as shown on Landing page
  //     logo: companyLogo, // Logo imported from above
  //     frLogo: companyLogoFr // French logo
  //     secondLogo: secondCompanyLogo, // Second Logo
  //     frSecondLogo: secondFrenchLogo // Second French
  //     logoClass: 'small-logo', // Use 'wide-logo', 'medium-logo', 'small-logo'
  //   PORTAL GATING
  //     trustBasedNoEF: true, // True if portal gating is Trust based with no eligibility file
  //     trustBasedWithEF: true, // True if portal gating is Trust based with eligibility file
  //     fullyGated: true, // True if portal gating is Fully gated and email addresses
  //     employeeIdSignup: true, // True if portal gating is Fully gated and e-IDs
  //     domainGated: true, // True if portal gating is Domain based
  //     email_domain: ['inkblottherapy.com', 'url.com'], // For Domain based gating use Inkblot url for testing and company url based on eligibility list
  //   PORTAL TEXT
  //     subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your private health insurance, benefits coverage or paid privately.', // To be customized if the last paragraph is different than the default
  //     removeSecondParagraph: true // Use if only two paragraphs are needed
  //     finalParagraph: 'See Canadian Tire, Insight or We for example' // For very special cases where both the second and third paragraph need to be customized (only been used three times)
  //     yourMentalHealth: 'string' // to replace "Your mental health matters and that’s why and Inkblot have partnered to provide you with confidential and convenient video counselling and mental health support."
  //     yourMentalHealthFr: 'string' // to replace "Your mental health matters and that’s why and Inkblot have partnered to provide you with confidential and convenient video counselling and mental health support."
  //   OTHER
  //     dependants: true, // True if company includes dependants
  //     showFlags: true, // true if Canada + US
  //     usOnly: true, // True if US only
  //   FRENCH PAGE
  //     frTitle: company's name in french
  //     frLogo: company's logo in french (if necessary)
  //     frenchPage: true, // True if English and French
  //     subsequentSessionsFr: 'Les séances individuelles subséquentes ne coûtent que 75 $ / heure (120 $ / heure pour les conseils aux couples) qui peuvent être remboursées par le biais de votre assurance maladie paramédicale ou payées en privé.', // Can be customized if frenchPage: true but defaults to regular translation,
  //     frenchOnly: company's page is only in French
  //     finalParagraphFr: 'See Canadian Tire, Insight or We for example' // For very special cases where both the second and third paragraph need to be customized (only been used three times)

  // },
  // -----------DEMO COMPANY------------
  {
    path: 'demoAccessCompany',
    marketingURL: 'demoAccessCompany',
    title: 'DemoABC',
    logo: demoaccesscompanyLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    showFlags: true,
    frenchPage: true,
    dependants: true,
  },
  // -----------------------------------
  {
    path: 'vanderlande',
    marketingURL: 'vanderlande',
    title: 'Vanderlande',
    logo: vanderlandeLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'novatel',
    marketingURL: 'novatel',
    title: 'NovAtel Inc.',
    logo: novAtelLogo,
    logoClass: 'wide-logo',
    frenchPage: true,
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: '407ETR',
    marketingURL: '407ETR',
    title: '407 ETR',
    logo: etr407Logo,
    logoClass: 'wide-logo',
    frenchPage: true,
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'ccc',
    marketingURL: 'ccc',
    title: 'Canada Colors and Chemicals Limited',
    logo: cccLogo,
    logoClass: 'wide-logo',
    frenchPage: true,
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'ClearConcepts',
    marketingURL: 'ClearConcepts',
    title: 'Clear Concepts',
    logo: clearConceptsLogo,
    logoClass: 'wide-logo',
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'GFS',
    marketingURL: 'GFS',
    title: 'Gordon Food Service',
    logo: gfsLogo,
    logoClass: 'wide-logo',
    dependants: true,
    fullyGated: true,
    frenchPage: true,
    employeeIdSignup: true,
  },
  {
    path: 'VTR',
    marketingURL: 'VTR',
    title: 'VTR Feeder Solutions Inc.',
    logo: vtrLogo,
    logoClass: 'wide-logo',
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'BIMM',
    marketingURL: 'BIMM',
    title: 'Bimm Management Inc.',
    logo: bimmLogo,
    logoClass: 'wide-logo',
    dependants: true,
    trustBasedNoEF: true,
    frenchPage: true,
  },
  {
    path: 'MartinBenefits',
    marketingURL: 'MartinBenefits',
    title: 'MMD Sales Ltd.',
    logo: martinMotorSportsLogo,
    secondLogo: martinDeerlineLogo,
    logoClass: 'small-logo',
    dependants: true,
    trustBasedNoEF: true,
  },
  {
    path: 'chitchats',
    marketingURL: 'chitchats',
    title: 'Chit Chats',
    logo: chitChatsLogo,
    logoClass: 'wide-logo',
    dependants: true,
    fullyGated: true,
    frenchPage: true,
  },
  {
    path: 'ROSEROCKET',
    marketingURL: 'ROSEROCKET',
    title: 'Rose Rocket',
    logo: roseRocketLogo,
    logoClass: 'small-logo',
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'zuva',
    marketingURL: 'zuva',
    title: 'Zuva',
    logo: zuvaLogo,
    logoClass: 'wide-logo',
    dependants: true,
    trustBasedWithEF: true,
  },
  {
    path: 'inkbox',
    marketingURL: 'inkbox',
    title: 'Inkbox',
    logo: inkboxLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    showFlags: true,
  },
  {
    path: 'grantbook',
    marketingURL: 'grantbook',
    title: 'Grantbook',
    logo: grantbookLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'borderglass',
    marketingURL: 'borderglass',
    title: 'Border Glass',
    logo: borderGlassLogo,
    logoClass: 'medium-logo',
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'mint',
    marketingURL: 'mint',
    title: 'Mint Agency',
    logo: mintLogo,
    logoClass: 'medium-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'sanofi',
    marketingURL: 'sanofi',
    title: 'Sanofi',
    logo: sanofiLogo,
    frLogo: sanofiLogoFr,
    logoClass: 'medium-logo',
    fullyGated: true,
    dependants: true,
    frenchPage: true,
    registrationFlow: 'redesign_2023',
  },
  {
    path: 'rldatix',
    marketingURL: 'rldatix',
    title: 'RLDatix North America Inc.',
    logo: rldatixLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    path: 'mountforestfht',
    marketingURL: 'mountforestfht',
    title: 'Mount Forest Family Health Team',
    logo: mountforestfhtLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    path: 'chemtrade',
    marketingURL: 'chemtrade',
    title: 'Chemtrade Logistics Inc.',
    logo: chemtradeLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    frenchPage: true,
    registrationFlow: 'redesign_2023',
  },
  {
    path: 'simplygroup',
    marketingURL: 'simplygroup',
    title: 'Simply Group',
    logo: snapFinancialLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    frenchPage: true,
    showFlags: true,
  },
  {
    path: 'zymewire',
    marketingURL: 'zymewire',
    title: 'Lumerate Inc',
    logo: LumerateLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'Mircom',
    marketingURL: 'Mircom',
    title: 'Mircom Technologies Ltd.',
    logo: mircomLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'heritagegreen',
    marketingURL: 'heritagegreen',
    title: 'Heritage Green Nursing Home',
    logo: heritageGreenLogo,
    logoClass: 'small-logo',
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'oneproperties',
    marketingURL: 'oneproperties',
    title: 'One Properties',
    logo: onePropertiesLogo,
    logoClass: 'small-logo',
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'carscallen',
    marketingURL: 'carscallen',
    title: 'Carscallen LLP',
    logo: carscallenLogo,
    logoClass: 'small-logo',
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'diginsights',
    marketingURL: 'diginsights',
    title: 'Dig Insights',
    logo: digLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    showFlags: true,
  },
  {
    path: 'cosa',
    marketingURL: 'cosa',
    title: 'COSA',
    logo: cosaLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'cameron',
    marketingURL: 'cameron',
    title: 'Cameron Development Management',
    logo: cameronLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'covenanthousetoronto',
    marketingURL: 'covenanthousetoronto',
    title: 'Covenant House',
    logo: covenantHouseLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'waxmanplanning',
    marketingURL: 'waxmanplanning',
    title: 'Waxman Planning',
    logo: waxmanLogo,
    logoClass: 'wide-logo',
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'bosleyrealestate',
    marketingURL: 'bosleyrealestate',
    title: 'Bosley Real Estate',
    logo: bosleyLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'krp',
    marketingURL: 'krp',
    title: 'KRP LLP',
    logo: krpLogo,
    logoClass: 'small-logo',
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'neo',
    marketingURL: 'neo',
    title: 'NEO Exchange',
    logo: neoLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'viafoura',
    marketingURL: 'viafoura',
    title: 'Viafoura',
    logo: viafouraLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    showFlags: true,
  },
  {
    path: 'interval',
    marketingURL: 'interval',
    title: 'interVal',
    logo: intervalLogo,
    logoClass: 'wide-logo',
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'hoopp',
    marketingURL: 'hoopp',
    title: 'HOOPP',
    logo: hooppLogo,
    logoClass: 'small-logo',
    dependants: true,
    trustBasedNoEF: true,
    frenchPage: true,
    subsequentSessions:
      'Subsequent sessions cost just $90 and can be reimbursed through your psychological benefits coverage with Sun Life ($2500 per calendar year for you and each of your dependents).',
    subsequentSessionsFr:
      'Les séances subséquentes ne coûtent que 90 $ et vous pouvez demander un remboursement au titre de votre garantie de services de psychologie de la Sun Life (2 500 $ par année civile pour vous et chacune des personnes à votre charge).',
  },
  {
    path: 'clearco',
    marketingURL: 'clearco',
    title: 'Clearco',
    logo: clearcoLogo,
    logoClass: 'wide-logo',
    dependants: true,
    fullyGated: true,
    showFlags: true,
  },
  {
    path: 'atp',
    marketingURL: 'atp',
    title: 'ATP',
    logo: atpLogo,
    logoClass: 'small-logo',
    dependants: true,
    trustBasedWithEF: true,
    showFlags: true,
  },
  {
    path: 'macgregors',
    marketingURL: 'macgregors',
    title: 'Macgregors Meat & Seafood',
    logo: macgregorsLogo,
    logoClass: 'small-logo',
    dependants: true,
    trustBasedNoEF: true,
  },
  {
    marketingURL: 'fugrocanada',
    path: 'fugrocanada',
    title: 'Fugro',
    logo: fugroLogo,
    logoClass: 'small-logo',
    dependants: true,
    fullyGated: true,
  },
  {
    marketingURL: 'acto',
    path: 'acto',
    title: 'ACTO',
    logo: actoLogo,
    logoClass: 'wide-logo',
    showFlags: true,
    fullyGated: true,
    dependants: true,
  },
  {
    marketingURL: 'uhf',
    path: 'uhf',
    title: 'University Hospital Foundation',
    logo: uhfLogo,
    logoClass: 'small-logo',
    fullyGated: true,
  },
  {
    marketingURL: 'gcrew',
    path: 'gcrew',
    title: 'Guestlogix',
    logo: grewLogo,
    logoClass: 'small-logo',
    dependants: true,
    trustBasedWithEF: true,
  },
  {
    marketingURL: '500px',
    path: '500px',
    title: '500px',
    logo: null,
    logoClass: 'small-logo',
    dependants: true,
  },
  {
    marketingURL: 'we',
    path: 'we',
    title: 'WE',
    logo: weLogo,
    logoClass: 'small-logo',
    dependants: true,
    trustBasedWithEF: true,
  },
  {
    marketingURL: 'thinkon',
    path: 'thinkon',
    title: 'ThinkOn',
    logo: thinkonLogo,
    logoClass: 'small-logo',
    dependants: true,
    fullyGated: true,
  },
  {
    marketingURL: 'partnerstack',
    path: 'partnerstack',
    title: 'PartnerStack',
    logo: partnerStackLogo,
    logoClass: 'wide-logo',
    domainGated: true,
    email_domain: ['inkblottherapy.com', 'partnerstack.com'],
  },
  {
    marketingURL: 'fullymanaged',
    path: 'fullymanaged',
    title: 'Fully Managed',
    logo: fullyManagedLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    marketingURL: 'ocp',
    path: 'ocp',
    title: 'Ontario College of Pharmacists',
    logo: ocpLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    marketingURL: 'firefly',
    path: 'firefly',
    title: 'Firefly Software',
    logo: fireflyLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    marketingURL: 'altus',
    path: 'altus',
    title: 'Altus Group',
    logo: altusLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    frenchPage: true,
    dependants: true,
  },
  {
    marketingURL: 'challengergeomatics',
    path: 'challengergeomatics',
    title: 'Challenger GeoMatics',
    logo: cglLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    marketingURL: 'davies',
    path: 'davies',
    title: 'Davies',
    logo: daviesLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    showFlags: true,
    frenchPage: true,
  },
  {
    marketingURL: 'kingcoleducks',
    path: 'kingcoleducks',
    title: 'King Cole Ducks',
    logo: kingcoleducksLogo,
    logoClass: 'small-logo',
    dependants: true,
    trustBasedWithEF: true,
  },
  {
    marketingURL: 'tebco',
    path: 'tebco',
    title: 'TEB/CO',
    logo: tebcoLogo,
    logoClass: 'wide-logo',
  },
  {
    marketingURL: 'sinkingship',
    path: 'sinkingship',
    title: 'Sinking Ship',
    logo: sinkingShipLogo,
    logoClass: 'small-logo',
    dependants: true,
    fullyGated: true,
  },
  {
    marketingURL: 'sci',
    path: 'sci',
    title: 'SCI Ltd',
    logo: sciLtdLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    marketingURL: 'newtopia',
    path: 'newtopia',
    title: 'Newtopia',
    logo: newtopiaLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    showFlags: true,
  },
  {
    marketingURL: 'fortress',
    path: 'fortress',
    title: 'Fortress Technology',
    logo: fortressLogo,
    logoClass: 'wide-logo',
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    marketingURL: 'ioof',
    path: 'ioof',
    title: 'IOOF Senior Homes',
    logo: ioofLogo,
    logoClass: 'small-logo',
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    marketingURL: 'hazelview',
    path: 'hazelview',
    title: 'Hazelview',
    logo: hvInvestmentsLogo,
    secondLogo: hvPropertiesLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    frenchPage: true,
    EAP: true,
    registrationFlow: 'redesign_2023',
  },
  {
    marketingURL: 'csa',
    path: 'csa',
    title: 'CSA Group',
    frTitle: 'Groupe CSA',
    logo: csaLogo,
    frLogo: csaLogoFr,
    logoClass: 'wide-logo',
    trustBasedNoEF: true,
    dependants: true,
    frenchPage: true,
  },
  {
    marketingURL: 'iqoffices',
    path: 'iqoffices',
    title: 'IQ Offices',
    logo: iqOfficesLogo,
    logoClass: 'wide-logo',
    dependants: true,
  },
  {
    marketingURL: 'bci',
    path: 'bci',
    title: 'BCI',
    logo: bciLogo,
    logoClass: 'wide-logo',
    dependants: true,
    fullyGated: true,
  },
  {
    marketingURL: 'step',
    path: 'step',
    title: 'STEP Energy Services',
    logo: stepLogo,
    logoClass: 'small-logo',
    dependants: true,
    trustBasedNoEF: true,
  },
  {
    marketingURL: 'jiffy',
    path: 'jiffy',
    title: 'Staffy Inc.',
    logo: staffyLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    marketingURL: 'inkblot',
    path: 'inkblot',
    title: 'Inkblot Technologies Inc.',
    EAP: true,
    logo: inkblotLogo,
    logoClass: 'small-logo',
    domainGated: true,
    email_domain: ['inkblottherapy.com', 'inkblotlabs.io'],
    singleParagraph:
      'Your internal Inkblot program provides you with easy to access support for your life, work and mental health challenges – any time – any place.',
    singleParagraphFr:
      "Votre programme interne Inkblot vous offre un soutien facile d'accès en matière de défis de la vie, de travail et de santé mentale - à tout moment - en tout lieu.",
    dependants: true,
    frenchPage: true,
  },
  {
    marketingURL: 'questica',
    path: 'questica',
    title: 'Questica',
    logo: questicaLogo,
    logoClass: 'small-logo',
    subsequentSessions:
      'Subsequent sessions cost just $90/hour which may be covered by your private health insurance, benefits coverage or paid privately.',
    subsequentSessionsUS:
      'Subsequent sessions can be paid out using your Aetna insurance. The rates will be in alignment with your benefits plan for in network mental health visits.',
    fullyGated: true,
    showFlags: true,
  },
  {
    marketingURL: 'morguard',
    path: 'morguard',
    title: 'Morguard',
    logo: morguardLogo,
    logoClass: 'wide-logo',
    frenchPage: true,
    dependants: true,
    fullyGated: true,
  },
  {
    marketingURL: 'diamond',
    path: 'diamond',
    title: 'Diamond',
    logo: diamondLogo,
    logoClass: 'wide-logo',
    frenchPage: true,
    dependants: true,
    singleParagraph: (
      <>
        <p>
          Diamond has partnered with Inkblot to provide you with easy to access
          support for your life, work and mental health challenges – any time –
          any place.
          <br />
          <br />
          <b>
            Inkblot’s services are voluntary and immediately available,
            24/7/365.
          </b>
        </p>
      </>
    ),
    singleParagraphFr: (
      <>
        <p>
          Diamond s’est associée à Inkblot pour vous offrir un soutien facile
          d’accès pour vos de vie, de travail et de santé mentale - à tout
          moment et en tout lieu.
        </p>
      </>
    ),
  },
  {
    marketingURL: 'sleepcountry',
    path: 'sleepcountry',
    title: 'Sleep Country',
    logo: sleepCountryLogo,
    logoClass: 'wide-logo',
    frenchPage: true,
    dependants: true,
    fullyGated: true,
  },
  {
    marketingURL: 'thedmz',
    path: 'thedmz',
    title: 'DMZ',
    logo: dmzLogo,
    logoClass: 'wide-logo',
    singleParagraph:
      'Your mental health matters and that’s why DMZ and Inkblot have partnered to provide you with confidential and convenient video counselling and mental health support at no cost.',
    isDMZ: true,
    trustBasedWithEF: true,
  },
  {
    marketingURL: 'eastcoaststrong',
    path: 'eastcoaststrong',
    title: 'Gallivan',
    logo: mywellnessLogo,
    logoClass: 'small-logo',
    singleParagraph:
      'As leaders in mental health support for students across Canada through our mywellness program, Gallivan has partnered with Inkblot to bring affordable Online Video Counselling to ALL students who may need the support.',
  },
  {
    marketingURL: 'league',
    path: 'league',
    title: 'League',
    logo: leagueLogo,
    logoClass: 'wide-logo',
    singleParagraph:
      'Your mental health matters and that’s why League and Inkblot have partnered to provide you with confidential and convenient video counselling and mental health support at no cost.',
    singleParagraphFr:
      'Votre santé mentale est importante c’est pourquoi League s’est associée à Inkblot, afin de vous offrir du vidéocounseling et du soutien en matière de santé mentale, en toute confidentialité et convivialité.',
    frenchPage: true,
    showFlags: true,
    dependants: true,
  },
  {
    marketingURL: 'indigo',
    path: 'indigo',
    title: 'Indigo',
    logo: indigoLogo,
    logoClass: 'wide-logo',
    singleParagraph:
      'With Inkblot, mental health support is completely confidential and just a click away. At half the cost of traditional therapy, your benefits will cover 25 sessions!',
  },
  {
    marketingURL: 'uber',
    path: 'uber',
    title: 'Uber',
    logo: uberLogo,
    logoClass: 'wide-logo',
    singleParagraph:
      'Your mental health matters and that’s why Uber and Inkblot have partnered to provide you with confidential and convenient video counselling and mental health support.',
    singleParagraphFr:
      'Votre santé mentale est importante, c’est pourquoi Uber s’est associée à Inkblot, afin de vous offrir du vidéocounseling et du soutien en matière de santé mentale, en toute confidentialité et convivialité.',
    frenchPage: true,
    dependants: true,
  },
  {
    marketingURL: 'bonfire',
    path: 'bonfire',
    title: 'Bonfire',
    logo: bonfireLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
  },
  {
    marketingURL: 'cdlsi',
    path: 'cdlsi',
    title: 'CDLSI',
    logo: cdlsiLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    marketingURL: 'baycrestgc',
    path: 'baycrestgc',
    title: 'Baycrest Construction',
    logo: baycrestLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    marketingURL: 'appcentrica',
    path: 'appcentrica',
    title: 'Appcentrica',
    logo: appcentricaLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    marketingURL: 'securitycompass',
    path: 'securitycompass',
    title: 'Security Compass',
    logo: securitycompassLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    showFlags: true,
  },
  {
    marketingURL: 'varicent',
    path: 'varicent',
    title: 'Varicent',
    logo: varicentLogo,
    logoClass: 'wide-logo',
    dependants: true,
    fullyGated: true,
    showFlags: true,
  },
  {
    marketingURL: 'intellijointsurgical',
    path: 'intellijointsurgical',
    title: 'Intellijoint Surgical',
    logo: intellijointLogo,
    logoClass: 'wide-logo',
    dependants: true,
    fullyGated: true,
    showFlags: true,
  },
  {
    marketingURL: 'sensibill',
    path: 'sensibill',
    title: 'Sensibill',
    logo: sensibillLogo,
    logoClass: 'small-logo',
    trustBasedWithEF: true,
    dependants: true,
    showFlags: true,
  },
  {
    marketingURL: 'alliancefranchise',
    path: 'alliancefranchise',
    title: 'Alliance Franchise Brands',
    logo: alliancefranchiseLogo,
    logoClass: 'wide-logo',
    trustBasedWithEF: true,
    dependants: true,
    showFlags: true,
  },
  {
    marketingURL: 'nca',
    path: 'nca',
    title: 'National Concrete Accessories',
    logo: ncaLogo,
    logoClass: 'wide-logo',
    trustBasedWithEF: true,
    dependants: true,
    frenchPage: true,
  },
  {
    marketingURL: 'rb',
    path: 'rb',
    title: 'Reckitt Benckiser',
    logo: rbLogo,
    logoClass: 'small-logo',
    trustBasedNoEF: true,
    dependants: true,
    frenchPage: true,
  },
  {
    marketingURL: 'einc',
    path: 'einc',
    title: 'E Inc.',
    logo: edealerLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    showFlags: true,
  },
  {
    marketingURL: 'cosmo',
    path: 'cosmo',
    title: 'Cosmo Music',
    logo: cosmoLogo,
    logoClass: 'small-logo',
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    marketingURL: 'valleyview',
    path: 'valleyview',
    title: 'Valleyview Residence',
    logo: valleyviewLogo,
    logoClass: 'small-logo',
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    marketingURL: 'forestview',
    path: 'forestview',
    title: 'Forestview Retirement Residence',
    logo: forestviewLogo,
    logoClass: 'small-logo',
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    marketingURL: 'citron',
    path: 'citron',
    title: 'Citron Hygiene',
    logo: citronLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    frenchPage: true,
  },
  {
    marketingURL: 'well',
    path: 'well',
    title: 'Well',
    logo: wellLogo,
    logoClass: 'wide-logo',
  },
  {
    marketingURL: 'partner',
    path: 'partner',
    title: 'Your Company',
    logo: partnerLogo,
    logoClass: 'partner-logo',
  },
  {
    path: 'hansens',
    marketingURL: 'hansens',
    title: 'Hansens Group of Companies',
    logo: hansensLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'turnerconstruction',
    marketingURL: 'turnerconstruction',
    title: 'Turner Construction',
    logo: turnerLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'tdsynnex',
    marketingURL: 'tdsynnex',
    title: 'TD SYNNEX Canada',
    logo: synnexLogo,
    logoClass: 'small-logo',
    trustBasedNoEF: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'moneris',
    marketingURL: 'moneris',
    title: 'Moneris',
    logo: monerisLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    EAP: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'futurpreneur',
    marketingURL: 'futurpreneur',
    title: 'Futurpreneur Canada',
    logo: futurpreneurLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'dealmaker',
    marketingURL: 'dealmaker',
    title: 'Dealmaker',
    logo: dealmakerLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'exchangerindustries',
    marketingURL: 'exchangerindustries',
    title: 'Exchanger Industries Limited',
    logo: exchangeIndustriesLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'helpwithaddictions',
    marketingURL: 'helpwithaddictions',
    title: 'Addiction Services Central Ontario',
    logo: helpWithAddictionsLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'larway',
    marketingURL: 'larway',
    title: 'Larway Transportation',
    logo: larwayLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'thebridge',
    marketingURL: 'thebridge',
    title: 'The Bridge, A Markham Community Church',
    logo: bridgeLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'tlg',
    marketingURL: 'tlg',
    title: 'The Leslie Group Limited',
    logo: leslieGroupLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'motionlp',
    marketingURL: 'motionlp',
    title: 'Motion Cares',
    logo: motionCaresLogo,
    logoClass: 'small-logo',
    trustBasedNoEF: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'yorkville',
    marketingURL: 'yorkville',
    title: 'Yorkville Education LP',
    logo: yorkvilleEducationLp,
    logoClass: 'small-logo',
    dependants: true,
    frenchPage: true,
    trustBasedNoEF: true,
    showFlags: true,
  },
  {
    path: 'teamstransport',
    marketingURL: 'teamstransport',
    title: 'Teams Transport ',
    logo: teamsTransportLogo,
    logoClass: 'small-logo',
    dependants: true,
    trustBasedNoEF: true,
  },
  {
    path: 'cubert',
    marketingURL: 'cubert',
    title: 'Cubert Inc.',
    logo: cubertLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    showFlags: true,
  },
  {
    path: 'associatedambulance',
    marketingURL: 'associatedambulance',
    title: 'Associated Ambulance',
    logo: associatedambulanceLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'mainframe',
    marketingURL: 'mainframe',
    title: 'Mainframe',
    logo: mainframeLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'austinpowder',
    marketingURL: 'austinpowder',
    title: 'Austin Powder',
    logo: austinpowderLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'gsc-access',
    marketingURL: 'gsc-access',
    title: 'Green Shield Canada',
    logo: newGscLogoEN,
    frLogo: newGscLogoFR,
    logoClass: 'wide-logo',
    frenchPage: true,
    removeSecondParagraph: true,
    subsequentSessions:
      'Your initial individual consultation is complimentary.',
    subsequentSessionsFr: 'Votre première séance est gratuite.',
    finalParagraph:
      'Subsequent sessions are reimbursable under your benefits plan, through a health care spending account (if applicable), or paid privately.',
    finalParagraphFr:
      'Les séances suivantes sont remboursables dans le cadre de votre régime d’avantages sociaux, via un compte de dépenses de santé (le cas échéant) ou payées en privé.',
  },
  {
    path: 'clarkbuilderscorporate',
    marketingURL: 'clarkbuilderscorporate',
    title: 'Clark Builders Corporate',
    logo: clarkBuildersLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'clarkbuildersfield',
    marketingURL: 'clarkbuildersfield',
    title: 'Clark Builders Field',
    logo: clarkBuildersLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'hansensbrokers',
    marketingURL: 'hansensbrokers',
    title: "Hansen's Brokers",
    logo: hansenLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'lenbrook',
    marketingURL: 'lenbrook',
    title: 'The Lenbrook Group of Companies',
    logo: lenbrookLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    showFlags: true,
  },
  {
    path: 'twigfertility',
    marketingURL: 'twigfertility',
    title: 'Twig Fertility',
    logo: twigLogo,
    logoClass: 'wide-logo',
    trustBasedNoEF: true,
  },
  {
    path: 'sidefx',
    marketingURL: 'sidefx',
    title: 'SideFX',
    logo: sidefxLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    frenchPage: true,
    dependants: true,
  },
  {
    path: 'HFSinclair',
    marketingURL: 'HFSinclair',
    title: 'HF Sinclair',
    logo: hollyfrontierLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    frenchPage: true,
    dependants: true,
  },
  {
    path: 'orbis',
    marketingURL: 'orbis',
    title: 'ORBIS Canada Limited',
    logo: orbisLogo,
    logoClass: 'wide-logo',
    EAP: true,
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'gallagher',
    marketingURL: 'gallagher',
    title: 'Gallagher',
    logo: gallagherLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    frenchPage: true,
    dependants: true,
  },
  {
    path: 'menasha',
    marketingURL: 'menasha',
    title: 'Menasha Packaging Canada LP',
    logo: menashaLogo,
    logoClass: 'wide-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'creationtech',
    marketingURL: 'creationtech',
    title: 'Creation Technologies',
    logo: creationLogo,
    logoClass: 'wide-logo',
    dependants: true,
    trustBasedNoEF: true,
  },
  {
    path: 'AdiDevelopmentGroup',
    marketingURL: 'AdiDevelopmentGroup',
    title: 'Adi Development Group Inc.',
    logo: adiLogo,
    logoClass: 'wide-logo',
    dependants: true,
    trustBasedNoEF: true,
  },
  {
    path: 'osler',
    marketingURL: 'osler',
    title: 'Osler',
    logo: wohsLogo,
    logoClass: 'small-logo',
    removeSecondParagraph: true,
    fullyGated: true,
    employeeIdSignup: true,
    dependants: true,
    hideSignUp: true,
    subsequentSessions:
      'This benefit is for staff, physicians and volunteers only.',
    finalParagraph:
      'Thank you for accessing Inkblot services. At this time there are no additional sponsored hours available. Please connect with Ivian Tchakarova, Manager of Healthy Workplace at William Osler Health System to connect to more resources and supports available at <a style="text-decoration: underline" href="mailto:Ivian.Tchakarova@williamoslerhs.ca">Ivian.Tchakarova@williamoslerhs.ca</a> or 437-992-1145.',
  },
  {
    path: 'luxy',
    marketingURL: 'luxy',
    showFlags: true,
    fullyGated: true,
    title: 'Luxy Hair Co.',
    dependants: true,
    logo: luxyLogo,
    logoClass: 'small-logo',
  },
  {
    path: 'litcolaw',
    marketingURL: 'litcolaw',
    title: 'Litco Law',
    logo: litcolawLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'newscorp',
    marketingURL: 'newscorp',
    title: 'News Corp',
    logo: newscorpLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'bluej',
    marketingURL: 'bluej',
    title: 'Blue J',
    logo: blueJLogo,
    logoClass: 'wide-logo',
    domainGated: true,
    email_domain: ['inkblottherapy.com', 'bluejlegal.com'],
    dependants: true,
  },
  {
    path: 'nwgeomatics',
    marketingURL: 'nwgeomatics',
    title: 'North West Geomatics Ltd',
    logo: hexagonLogo,
    logoClass: 'small-logo',
    dependants: true,
    trustBasedNoEF: true,
    showFlags: true,
  },
  {
    path: 'hmic',
    marketingURL: 'hmic',
    title: 'Hexagon Manufacturing Intelligence',
    logo: hexagonLogo,
    logoClass: 'small-logo',
    dependants: true,
    trustBasedNoEF: true,
    frenchPage: true,
  },
  {
    path: 'multivista',
    marketingURL: 'multivista',
    title: 'Multivista Canada',
    logo: hexagonLogo,
    logoClass: 'small-logo',
    dependants: true,
    trustBasedNoEF: true,
    showFlags: true,
  },
  {
    path: 'microsurvey',
    marketingURL: 'microsurvey',
    title: 'MicroSurvey Software Inc',
    logo: hexagonLogo,
    logoClass: 'small-logo',
    dependants: true,
    trustBasedNoEF: true,
  },
  {
    path: 'slalom',
    marketingURL: 'slalom',
    title: 'Slalom Inc',
    logo: slalomLogo,
    logoClass: 'small-logo',
    dependants: true,
    frenchPage: true,
    fullyGated: true,
  },
  {
    path: 'realstar',
    marketingURL: 'realstar',
    title: 'Realstar Management',
    logo: realstarLogo,
    logoClass: 'small-logo',
    dependants: true,
    frenchPage: true,
    trustBasedNoEF: true,
  },
  {
    path: 'redthread',
    marketingURL: 'redthread',
    title: 'Red Thread Innovations',
    logo: redthreadLogo,
    logoClass: 'small-logo',
    domainGated: true,
    email_domain: ['inkblottherapy.com', 'redthreadinnovations.com'],
    dependants: true,
  },
  {
    path: 'sourceenergy',
    marketingURL: 'sourceenergy',
    title: 'Source Energy Services',
    logo: sourceenergyLogo,
    logoClass: 'small-logo',
    dependants: true,
    trustBasedNoEF: true,
  },
  {
    path: 'WCSWR',
    marketingURL: 'WCSWR',
    title: "Women's Crisis Services Waterloo Region",
    logo: wcswrLogo,
    logoClass: 'small-logo',
    frenchPage: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'bryanco',
    marketingURL: 'bryanco',
    title: 'Bryan & Company LLP',
    logo: bryancoLogo,
    logoClass: 'small-logo',
    frenchPage: true,
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'CNRL',
    marketingURL: 'CNRL',
    title: 'Canadian Natural Resources Limited',
    logo: canadiannaturalresourceslimitedLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    removeSecondParagraph: true,
    yourMentalHealth:
      'Your mental health matters! Canadian Natural and Inkblot have partnered to provide you and your eligible dependents access to online personal and confidential counselling and mental health support.',
    subsequentSessions:
      'Sessions cost just $90/hour and are paid privately. In turn, these can be submitted under your Canadian Natural benefit plan, Strive HSA or other health insurance.',
    dependants: true,
  },
  {
    path: 'smls',
    marketingURL: 'smls',
    title: "St. Mildred's-Lightbourn School",
    logo: smlsLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'pianopiano',
    marketingURL: 'pianopiano',
    title: 'Piano Piano Inc.',
    logo: pianopianoLogo,
    logoClass: 'small-logo',
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'otf',
    marketingURL: 'otf',
    title: 'Ontario Trillium Foundation',
    logo: otfLogo,
    logoClass: 'small-logo',
    dependants: true,
    frenchPage: true,
    fullyGated: true,
  },
  {
    path: 'arcticglacier',
    marketingURL: 'arcticglacier',
    title: 'Arctic Glacier',
    logo: ArcticGlacierLogo,
    logoClass: 'small-logo',
    dependants: true,
    trustBasedWithEF: true,
  },
  {
    path: 'ucc',
    marketingURL: 'ucc',
    title: 'Upper Canada College',
    logo: UccLogo,
    logoClass: 'small-logo',
    frenchPage: true,
    dependants: true,
    trustBasedWithEF: true,
  },
  {
    path: 'llt',
    marketingURL: 'llt',
    title: 'Lower Lakes Towing Ltd',
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    logo: LLTLogo,
  },
  {
    path: 'fppm',
    marketingURL: 'fppm',
    title: 'Fraternité des policiers et policières de Montréal (FPPM)',
    logo: FPPMLogo,
    logoClass: 'wide-logo',
    frenchPage: true,
    frenchOnly: true,
    fullyGated: true,
    dependants: true,
    customLabelForSignUpInputField: 'eap.customFPPMSignUp',
    subsequentSessionsFr: `Les séances peuvent être remboursées par votre régime d’assurance maladie, à raison de 50 % par session, jusqu’à un maximum de <br/>1 000 $ par personne assurée. Après chaque séance, vous recevrez par courrier électronique un reçu pour le remboursement. Au Canada, les séances sont 90 $ de l’heure pour une séance individuelle ou de couple.
      <br/><br/>Notez bien que ce service ne peut d’aucune façon être considéré comme un substitut au PAPP pour le policier actif.<br/><br/>Il s’agit bien d’un service complémentaire, le PAPP représentant la ressource pertinente en cas de risque suicidaire, de blessure grave, d’événement majeur ou d’hospitalisation d’un policier ou d’une policière en devoir.`,
  },
  {
    path: 'esteelauder',
    marketingURL: 'esteelauder',
    title: 'Estee Lauder',
    logoClass: 'wide-logo',
    trustBasedNoEF: true,
    dependants: true,
    logo: EsteeLauderLogo,
  },
  // Access Plus Company template
  // {
  //   BASICS
  //   path: 'path', // This is the same as the customized_url in the database
  //   marketingURL: 'path', // Usually the same as the path but can be customized for companies with multiple portal pages
  //   title: 'Title', // Name of the company as shown on Landing page
  //   logo: companyLogo, // Logo imported from above
  //   logoClass: 'small-logo', // Use 'wide-logo' for wider, rectangular logos and 'small-logo' for smaller, square or circular logos
  //   minutes: '3 hours', // This is where you put the time alotted in the contract, use hours for 2 hours plus and minutes for 90 minutes or less
  //   PORTAL GATING
  //   trustBasedNoEF: true, // True if portal gating is Trust based with no eligibility file
  //   trustBasedWithEF: true, // True if portal gating is Trust based with eligibility file
  //   fullyGated: true, // True if portal gating is Fully gated
  //   employeeIdSignup: true, // True if portal gating is Fully gated and e-IDs
  //   domainGated: true, // True if portal gating is Domain based
  //   email_domain: ['inkblottherapy.com', 'url.com'], // For Domain based gating use Inkblot url for testing and company url based on eligibility list
  //   PORTAL TEXT
  //   subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your private health insurance, benefits coverage or paid privately.', // To be customized if the last paragraph is different than the default
  //   OTHER
  //   dependants: true, // True if company includes dependants
  //   showFlags: true, // true if Canada + US
  //   usOnly: true, // True if US only
  //   FRENCH PAGE
  //   frTitle: company's name in french
  //   frLogo: company's logo in french (if necessary)
  //   frenchPage: true, // True if English and French
  //   heures: '3 prochaines heures', // This is where you put the time alotted in the contract in french - ex. '2 prochaines heures' or '90 prochaines minutes' - make sure to include prochaines
  //   subsequentSessionsFr: 'Les séances individuelles subséquentes ne coûtent que 75 $ / heure (120 $ / heure pour les conseils aux couples) qui peuvent être remboursées par le biais de votre assurance maladie paramédicale ou payées en privé.', // Can be customized if frenchPage: true but defaults to regular translation,
  // },
  // -----------DEMO COMPANY------------
  {
    path: 'demoAccessPlusCompany',
    marketingURL: 'demoAccessPlusCompany',
    title: 'DemoXYZ',
    logo: demoaccesspluscompanyLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    showFlags: true,
    frenchPage: true,
    dependants: true,
    minutes: '5 hours',
    heures: '5 prochaines heures',
  },
  {
    path: 'demodomaincompany',
    marketingURL: 'demodomaincompany',
    title: 'Demo Domain Company',
    logo: demoDomainCompanyLogo,
    logoClass: 'small-logo',
    domainGated: true,
    email_domain: ['inkblottherapy.com'],
    showFlags: true,
    frenchPage: true,
    dependants: true,
    minutes: '5 hours',
    heures: '5 prochaines heures',
  },
  // -----------------------------------
  {
    path: 'phenomic',
    marketingURL: 'phenomic',
    title: 'Phenomic Ai',
    logo: phenomicLogo,
    logoClass: 'wide-logo',
    minutes: '12 hours',
    dependants: true,
    trustBasedWithEF: true,
  },
  {
    path: 'colligan',
    marketingURL: 'colligan',
    title: 'Colligan Law',
    logo: colliganLogo,
    logoClass: 'small-logo',
    minutes: '5 hours',
    dependants: true,
    fullyGated: true,
    usOnly: true,
  },
  {
    path: 'olympique',
    marketingURL: 'olympique',
    title: 'Parc olympique',
    logo: parcolympiqueLogo,
    logoClass: 'small-logo',
    minutes: '2 hours',
    heures: '2 prochaines heures',
    frenchPage: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'myodetox',
    marketingURL: 'myodetox',
    title: 'Myodetox',
    logo: myodetoxLogo,
    logoClass: 'wide-logo',
    minutes: '120 minutes',
    fullyGated: true,
  },
  {
    path: 'thejunemotel',
    marketingURL: 'thejunemotel',
    title: 'The June Motel',
    logo: theJuneMotelLogo,
    logoClass: 'small-logo',
    minutes: '7 hours',
    dependants: true,
    trustBasedNoEF: true,
  },
  {
    path: 'medullaco',
    marketingURL: 'medullaco',
    title: 'Medulla & Co',
    logo: medullaCoLogo,
    logoClass: 'small-logo',
    minutes: '5.5 hours',
    fullyGated: true,
  },
  {
    path: 'elevate',
    marketingURL: 'elevate',
    title: 'Elevate',
    logo: elevateLogo,
    logoClass: 'small-logo',
    minutes: '120 minutes',
    fullyGated: true,
    dependants: true,
    removeSecondParagraph: true,
    subsequentSessions:
      'Elevate will cover all of your counselling sessions during the cohort and up to 6 months after the cohort.',
  },
  {
    path: 'realfood',
    marketingURL: 'realfood',
    title: 'Real Food For Real Kids',
    logo: rfrkLogo,
    logoClass: 'small-logo',
    minutes: '3 hours',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'osc',
    marketingURL: 'osc',
    title: 'Ontario Securities Commission',
    logo: oscLogo,
    logoClass: 'small-logo',
    minutes: '7 hours',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'haven',
    marketingURL: 'haven',
    title: 'Clad Innovations Ltd. (dba Haven IAQ)',
    logo: HavenLogo,
    logoClass: 'small-logo',
    minutes: '5 hours',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'earnest',
    marketingURL: 'earnest',
    title: 'Earnest Ice Cream',
    logo: earnestLogo,
    logoClass: 'small-logo',
    minutes: '60 minutes',
    fullyGated: true,
    secondLogo: earnestSecondLogo,
    dependants: true,
  },
  {
    path: 'wirthhats',
    marketingURL: 'wirthhats',
    title: 'WIRTH Hats',
    logo: wirthLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    frenchPage: true,
    showFlags: true,
  },
  {
    path: 'omvic',
    marketingURL: 'omvic',
    title: 'OMVIC',
    logo: omvicLogo,
    logoClass: 'small-logo',
    dependants: true,
    minutes: '2 hours',
    fullyGated: true,
  },
  {
    path: 'vela',
    marketingURL: 'vela',
    title: 'VELA Wealth',
    logo: velaLogo,
    logoClass: 'wide-logo',
    dependants: true,
    minutes: '4 hours',
    fullyGated: true,
  },
  {
    path: 'kasian',
    marketingURL: 'kasian',
    title: 'Kasian',
    logo: kasianLogo,
    logoClass: 'small-logo',
    dependants: true,
    minutes: '2 hours',
    EAP: true,
    fullyGated: true,
  },
  {
    marketingURL: 'esg',
    path: 'esg',
    title: 'East Side Games',
    logo: esgLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    marketingURL: 'loopio',
    path: 'loopio',
    title: 'Loopio',
    logo: loopioLogo,
    logoClass: 'wide-logo',
    minutes: '5 hours',
    fullyGated: true,
    dependants: true,
    singleParagraph: (
      <>
        <p>
          Loopio has partnered with Inkblot to provide you with easy to access
          support for your life, work and mental health challenges – any time –
          any place.
          <br />
          <br />
          <b>
            Inkblot’s services are voluntary and immediately available,
            24/7/365.
          </b>
        </p>
      </>
    ),
  },
  {
    marketingURL: 'eastsidegames',
    path: 'esg',
    title: 'East Side Games',
    logo: esgLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
  },
  {
    marketingURL: 'thesweetpotato',
    path: 'thesweetpotato',
    title: 'The Sweet Potato',
    logo: tspLogo,
    logoClass: 'wide-logo',
  },
  {
    marketingURL: 'ratiocity',
    path: 'ratiocity',
    title: 'Ratio City',
    logo: ratioLogo,
    logoClass: 'small-logo',
  },
  {
    marketingURL: 'cbn',
    redirectToURL: 'cbncoeap',
  },
  {
    marketingURL: 'athinkingape',
    path: 'athinkingape',
    title: 'A Thinking Ape',
    logo: thinkingApeLogo,
    logoClass: 'wide-logo',
    minutes: '4 hours',
    fullyGated: true,
    dependants: true,
  },
  {
    marketingURL: 'reunion',
    path: 'reunion',
    title: 'Reunion Inc',
    logo: reunionLogo,
    logoClass: 'wide-logo',
    minutes: '2 hours',
    dependants: true,
  },
  {
    marketingURL: 'flipgive',
    path: 'flipgive',
    title: 'FlipGive',
    logo: flipgiveLogo,
    logoClass: 'wide-logo',
    minutes: '3 hours',
  },
  {
    marketingURL: 'tribalscale',
    path: 'tribalscale',
    title: 'TribalScale',
    logo: tribalscaleLogo,
    logoClass: 'wide-logo',
    minutes: '12 hours',
    dependants: true,
    fullyGated: true,
    showFlags: true,
  },
  {
    marketingURL: 'campbrain',
    path: 'campbrain',
    title: 'CampBrain',
    logo: campbrainLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    marketingURL: 'kirasystems',
    path: 'kirasystems',
    title: 'Kira Systems',
    logo: kiraSystemsLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    showFlags: true,
  },
  {
    marketingURL: 'velocity',
    path: 'velocity',
    title: 'Velocity',
    logo: velocityLogo,
    logoClass: 'wide-logo',
    minutes: '90 minutes',
  },
  {
    marketingURL: 'streetcontext',
    path: 'streetcontext',
    title: 'Street Context',
    logo: streetContxtLogo,
    logoClass: 'wide-logo',
    minutes: '2 hours',
    dependants: true,
  },
  {
    marketingURL: 'carbon60',
    path: 'carbon60',
    title: 'Carbon60',
    logo: carbon60Logo,
    logoClass: 'wide-logo',
    minutes: '2 hours',
    fullyGated: true,
  },
  {
    marketingURL: 'blanclabs',
    path: 'blanclabs',
    title: 'Blanc Labs',
    logo: blanclabsLogo,
    logoClass: 'wide-logo',
    dependants: true,
    fullyGated: true,
  },
  {
    marketingURL: 'hoem',
    path: 'hoem',
    title: 'HOEM on Jarvis',
    logo: hoemLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    removeSecondParagraph: true,
    subsequentSessions:
      'Your initial individual consultation is complimentary, plus any additional counseling hours sponsored by Hoem on Jarvis.',
    finalParagraph:
      'Subsequent individual sessions cost just $90/hour which may be covered by your student health insurance or paid privately.',
  },
  {
    marketingURL: 'mapleleaffoods',
    path: 'mapleleaffoods',
    title: 'Maple Leaf Foods',
    logo: mapleleaffoodsLogo,
    logoClass: 'small-logo',
    showFlags: true,
    frenchPage: true,
    singleParagraph: (
      <>
        <p>
          <span>Your mental health matters</span> and that’s why Maple Leaf
          Foods and Inkblot have partnered to provide you with confidential and
          convenient video counselling and mental health support.
        </p>
        <p>
          Your initial individual consultation is complimentary, and through
          Maple Leaf Foods, your next 10 hours of counselling per year are
          covered.
        </p>
        <p>
          Subsequent individual or couples counselling sessions cost just{' '}
          {COUNTRY === 'US' ? '$110' : '$90'}/hour, which may be covered by your
          benefits plan or paid privately.
        </p>
      </>
    ),
    singleParagraphFr: (
      <>
        <p>
          <span>Votre santé mentale est importante,</span> c’est pourquoi Maple
          Leaf Foods s’est associée à Inkblot, afin de vous offrir des
          vidéoconsultations et du soutien en matière de santé mentale, en toute
          confidentialité et convivialité.
        </p>
        <p>
          Votre première séance individuelle est gratuite et par le biais de
          Maple Leaf Foods, vos 10 prochaines heures de counseling par anneé
          sont couvertes.
        </p>
        <p>
          Les séances individuelles et conseils aux couples subséquentes ne
          coûtent que {COUNTRY === 'US' ? '110' : '90'} $ / heure qui peuvent
          être remboursées par le biais de votre assurance maladie paramédicale,
          compte de dépenses de santé ou payées en privé.
        </p>
      </>
    ),
  },
  {
    marketingURL: 'canadalearningcode',
    path: 'canadalearningcode',
    title: 'Canada Learning Code',
    logo: canadalearningcodeLogo,
    logoClass: 'small-logo',
    frenchPage: true,
    fullyGated: true,
    dependants: true,
  },
  {
    marketingURL: 'endy',
    path: 'endy',
    title: 'Endy',
    logo: endyLogo,
    logoClass: 'small-logo',
    frenchPage: true,
    EFAP: true,
    dependants: true,
    fullyGated: true,
  },
  {
    marketingURL: 'canadianstage',
    path: 'canadianstage',
    title: 'Canadian Stage',
    logo: csLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    marketingURL: 'unifiedalloys',
    path: 'unifiedalloys',
    title: 'Unified Alloys',
    logo: unifiedAlloysLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    minutes: '3 hours',
  },
  {
    marketingURL: 'insight',
    path: 'insight',
    title: 'Insight TV',
    logo: insightLogo,
    logoClass: 'small-logo',
    trustBasedWithEF: true,
    showFlags: true,
  },
  {
    marketingURL: 'bench',
    path: 'bench',
    title: 'Bench',
    logo: benchLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    showFlags: true,
    EAP: true,
  },
  {
    marketingURL: 'brandheroes',
    path: 'brandheroes',
    title: 'Brand Heroes',
    logo: brandHeroesLogo,
    logoClass: 'too-wide-logo',
    dependants: true,
    fullyGated: true,
    minutes: '3 hours',
  },
  {
    marketingURL: 'flipp',
    path: 'flipp',
    title: 'Flipp',
    logo: flippLogo,
    logoClass: 'small-logo',
    dependants: true,
    fullyGated: true,
    removeSecondParagraph: true,
    singleParagraph: (
      <>
        <p>
          Flipp has partnered with Inkblot to provide you with easy to access
          support for your life, work and mental health challenges – any time –
          any place.
          <br />
          <br />
          <b>
            Inkblot’s services are voluntary and immediately available,
            24/7/365.
          </b>
        </p>
      </>
    ),
  },
  {
    path: 'atbclassic',
    marketingURL: 'atbclassic',
    title: 'ATB-PGA Sponsorship',
    logo: atbPgaLogo,
    logoClass: 'small-logo',
    minutes: '180 minutes',
    trustBasedNoEF: true,
  },
  {
    path: 'sang',
    marketingURL: 'sang',
    title: 'SANG - Ste. Anne Natural Gas Co-op Ltd.',
    logo: sangLogo,
    logoClass: 'wide-logo',
    minutes: '120 minutes',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'createaction',
    marketingURL: 'createaction',
    title: 'The Canadian Community Economic Development Network',
    logo: createActionLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    frenchPage: true,
    dependants: true,
    removeSecondParagraph: true,
    subsequentSessions:
      'Your initial individual consultation is complimentary and through The Canadian Community Economic Development Network, counselling is free of charge.',
    finalParagraph: '    ', // blank for subsequentSessions paragraph styling
    subsequentSessionsFr:
      'Votre première consultation individuelle est gratuite et les séances de consultation sont aussi gratuites par l’intermédiaire du Réseau canadien de développement économique communautaire.',
  },
  {
    path: 'cfrreddeer',
    marketingURL: 'cfrreddeer',
    title: 'Canadian Finals Rodeo presented by ATB',
    logo: cfrLogo,
    secondLogo: cfrSecondLogo,
    logoClass: 'small-logo',
    minutes: '180 minutes',
    trustBasedNoEF: true,
  },
  {
    path: 'roomforher',
    marketingURL: 'roomforher',
    title: 'Room For Her',
    frenchPage: true,
    trustBasedNoEF: true,
    minutes: '60 minutes',
    logo: rfhLogo,
    frLogo: rfhLogoFr,
    logoClass: 'small-logo',
    removeSecondParagraph: true,
    subsequentSessions:
      'As a part of the Room for Her program, Inkblot Therapy offers a one-hour complimentary counselling session, which you can book as one single hour or two 30-minute sessions.',
    finalParagraph:
      'Subsequent individual sessions cost just $90/hour, which may be covered by your private health insurance, employer benefits coverage or paid privately. <br><br> If you are an existing Inkblot user or have access to Inkblot provided through your workplace, <a style="text-decoration: underline" href="mailto:support@inkblottherapy.com"> please get in touch with support </a> to claim this complimentary one-hour of counselling.',
    subsequentSessionsFr:
      'Dans le cadre du programme Espac<i>elle</i>, Thérapie Inkblot offre une séance de consultation gratuite d’une heure, qui peut se diviser en deux séances de 30 minutes.',
    finalParagraphFr:
      'Les séances individuelles subséquentes ne coûtent que 90 $ l’heure. Elles peuvent être couvertes par votre assurance maladie privée, un régime de garanties d’employeur ou un régime privé. <br><br> Si vous êtes une cliente actuelle d’Inkblot ou si vous avez accès à Inkblot depuis votre lieu de travail, <a style="text-decoration: underline" href="mailto:support@inkblottherapy.com"> veuillez communiquer avec le soutien à la clientèle </a> pour bénéficier de cette heure de consultation gratuite.',
    yourMentalHealth: (
      <p>
        The Room for Her initiative is a program brought to you by Green Shield
        Benefits Association and Inkblot Therapy. Room for Her is built to help
        remove the barriers women face in accessing therapy, including not
        knowing how to find a therapist that's a good fit, long wait times,
        coupled with the costs, inconvenience and time spent travelling to and
        from traditional in-person therapy.
      </p>
    ),
    yourMentalHealthFr: (
      <p>
        L’initiative Espac<i>elle</i> est un programme qui vous est présenté par
        la Green Shield Benefits Association et Thérapie Inkblot. L’initiative
        Espac<i>elle</i> vise à éliminer les obstacles que rencontrent les
        femmes pour accéder à une thérapie, notamment le fait de ne pas savoir
        comment trouver un thérapeute qui leur convienne, les longs délais
        d’attente, ainsi que les coûts, les inconvénients et le temps passé à se
        déplacer pour suivre une thérapie traditionnelle en personne.
      </p>
    ),
  },
  {
    path: 'lanefour',
    marketingURL: 'lanefour',
    title: 'Lane Four',
    trustBasedNoEF: true,
    minutes: '60 minutes',
    logo: laneFourLogo,
    logoClass: 'small-logo',
    dependants: true,
  },
  {
    path: 'thenaturalstep',
    marketingURL: 'thenaturalstep',
    title: 'The Natural Step',
    fullyGated: true,
    logo: thenaturalstepLogo,
    logoClass: 'small-logo',
    dependants: true,
  },
  {
    path: 'scopesecurity',
    marketingURL: 'scopesecurity',
    title: 'Scope Security',
    logo: scopesecurityLogo,
    logoClass: 'wide-logo',
    showFlags: true,
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'kellyservicesca',
    marketingURL: 'kellyservicesca',
    title: 'Kelly Services Canada Ltd',
    logo: kellyservicesLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'shelters',
    marketingURL: 'shelters',
    title: 'Homelessness and Housing Help Hub (H4)',
    trustBasedNoEF: true,
    subsequentSessions: ' ',
  },
  {
    path: 'CHC',
    marketingURL: 'CHC',
    title: 'Windsor Essex Community Housing Corporation',
    trustBasedNoEF: true,
    subsequentSessions: ' ',
  },
  {
    path: 'HWS',
    marketingURL: 'HWS',
    title: 'Housing with Supports Home',
    trustBasedNoEF: true,
    subsequentSessions: ' ',
  },
  {
    path: 'connections',
    marketingURL: 'connections',
    title: 'Connections CAS',
    frenchPage: true,
    fullyGated: true,
    logo: connectionsLogo,
    logoClass: 'small-logo',
    subsequentSessions: '  ',
    subsequentSessionsFr: '  ',
    customLabelForSignUpInputField: 'eap.email',
    bestWay:
      'Counselling is the best way to achieve mental well-being, learn to cope with life stressors and improve your relationships.',
    bestWayFr:
      "La thérapie est le meilleur moyen d'atteindre le bien-être mental, d'apprendre à faire face aux facteurs de stress personnels et d'améliorer vos relations.",
  },
  {
    path: 'BRCF',
    marketingURL: 'BRCF',
    title: 'Blue Rose Cancer Foundation',
    fullyGated: true,
    showFlags: true,
    logo: brcfLogo,
    frenchPage: true,
    dependants: true,
    customLabelForSignUpInputField: 'eap.email',
  },
  {
    path: 'worley',
    marketingURL: 'worley',
    title: 'Worley Canada',
    trustBasedNoEF: true,
    logo: WorleyLogo,
    logoClass: 'wide-logo',
    dependants: true,
  },
  {
    path: 'genrus',
    marketingURL: 'genrus',
    title: 'Genrus United',
    trustBasedNoEF: true,
    logo: GenrusLogo,
    logoClass: 'small-logo',
  },
  // EAP / EFAP Company template
  // {
  //   BASICS
  //   path: 'path', // This is the same as the customized_url in the database
  //   marketingURL: 'path', // Usually the same as the path but can be customized for companies with multiple portal pages
  //   title: 'Title', // Name of the company as shown on Landing page
  //   logo: companyLogo, // Logo imported from above
  //   logoClass: 'small-logo', // Use 'wide-logo' for wider, rectangular logos and 'small-logo' for smaller, square or circular logos
  //   EAP: true, // True if you want the text to read EAP
  //   EFAP: true, // True if you want the text to read EFAP
  //   PORTAL GATING
  //   trustBasedNoEF: true, // True if portal gating is Trust based with no eligibility file
  //   trustBasedWithEF: true, // True if portal gating is Trust based with eligibility file
  //   fullyGated: true, // True if portal gating is Fully gated
  //   employeeIdSignup: true, // True if portal gating is Fully gated and e-IDs
  //   domainGated: true, // True if portal gating is Domain based
  //   email_domain: ['inkblottherapy.com', 'url.com'], // For Domain based gating use Inkblot url for testing and company url based on eligibility list
  //   PORTAL TEXT
  //   efapSecond: 'Your initial counselling session is complimentary, and through the EAP, your next 5 hours of counselling per year are covered. After that, you can continue counselling with sessions reimbursable through your health benefits plan, if applicable. Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling).', // To be customized if the portal needs an extra middle paragraph
  //   efapThird: 'Lorem ipsum', // To be customized if the portal needs an extra final paragraph
  //   efapSecondFr: 'Lorem Ipsum francais', // To be customized in french if the portal needs an extra middle paragraph, default is null so CANT BE LEFT BLANK if a french page is needed
  //   efapThirdFr: 'Lorem ipsum', // To be customized if the portal needs an extra French final paragraph
  //   customLabelForSignUpInputField: 'eap.email' // to custom label string of an email input field in sign up page. id value will be neded.
  //   bestWay: 'Lorem ipsum', // to custom "Counselling is the best way to achieve mental well-being, learn to cope with work and life stressors and improve your relationships."
  //   bestWayFr: 'Lorem ipsum', // to custom "Counselling is the best way to achieve mental well-being, learn to cope with work and life stressors and improve your relationships."
  //   OTHER
  //   dependants: true, // True if company includes dependants
  //   showFlags: true, // true if Canada + US
  //   usOnly: true, // True if US only
  //   FRENCH PAGE
  //   frTitle: company's name in french
  //   frLogo: company's logo in french (if necessary)
  //   frenchPage: true, // True if English and French
  // },
  // -----------DEMO COMPANY------------
  {
    path: 'demoEAPcompany',
    marketingURL: 'demoEAPcompany',
    title: 'Demo123',
    logo: demoeapcompanyLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    showFlags: true,
    frenchPage: true,
    dependants: true,
    EAP: true,
  },
  // -----------------------------------
  {
    path: 'hansen',
    marketingURL: 'hansen',
    title: 'Hansen',
    logo: hansenLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'semaphore',
    path: 'semaphore',
    title: 'Semaphore Solutions',
    logo: semaphoreLogo,
    logoClass: 'wide-logo',
    frenchPage: true,
    fullyGated: true,
    dependants: true,
    showFlags: true,
    EAP: true,
  },
  {
    marketingURL: 'saftcab',
    path: 'saftcab',
    title: 'Saf-T-Cab Inc',
    logo: saftcabLogo,
    logoClass: 'small-logo',
    employeeIdSignup: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'hrb',
    path: 'hrb',
    title: 'H&R Block Canada, Inc',
    logo: hrbLogo,
    logoClass: 'small-logo',
    frenchPage: true,
    trustBasedNoEF: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'springboardclinic',
    path: 'springboardclinic',
    title: 'Springboard Clinic',
    logo: springboardclinicLogo,
    logoClass: 'wide-logo',
    trustBasedWithEF: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'milkmoovement',
    path: 'milkmoovement',
    title: 'Milk Moovement',
    logo: milkmoovementLogo,
    logoClass: 'wide-logo',
    trustBasedWithEF: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'sunspace',
    path: 'sunspace',
    title: 'Sunspace Modular Enclosures Inc.',
    logo: sunspaceLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'mindlance',
    path: 'mindlance',
    title: 'Mindlance Inc.',
    logo: mindlanceLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'kidsability',
    path: 'kidsability',
    title: 'KidsAbility',
    logo: kidsabilityLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'michels',
    path: 'michels',
    title: 'Michels Canada Co',
    logo: michelsLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'covers',
    path: 'covers',
    title: 'Covers',
    logo: coversLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    frenchPage: true,
    EAP: true,
  },
  {
    marketingURL: 'coastalreign',
    path: 'coastalreign',
    title: 'Coastal Reign Printing Ltd.',
    logo: coastalreignLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'cornerstonecourier',
    path: 'cornerstonecourier',
    title: 'Cornerstone Courier',
    logo: cornerstonecourierLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'fielding',
    path: 'fielding',
    title: 'Fielding Environmental',
    logo: fieldingenvironmentalLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'mcccanada',
    path: 'mcccanada',
    title: 'Mennonite Central Committee Canada',
    logo: mccLogo,
    logoClass: 'small-logo',
    frenchPage: true,
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'mccsk',
    path: 'mccsk',
    title: 'Mennonite Central Committee Saskatchewan',
    logo: mccLogo,
    logoClass: 'small-logo',
    frenchPage: true,
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'mccmb',
    path: 'mccmb',
    title: 'Mennonite Central Committee Manitoba',
    logo: mccLogo,
    logoClass: 'small-logo',
    frenchPage: true,
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'mccab',
    path: 'mccab',
    title: 'Mennonite Central Committee Alberta',
    logo: mccLogo,
    logoClass: 'small-logo',
    frenchPage: true,
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'mcaconsultants',
    path: 'mcaconsultants',
    title: 'MCA Consultants',
    logo: mcaconsultantsLogo,
    logoClass: 'wide-logo',
    trustBasedNoEF: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'selectpath',
    path: 'selectpath',
    title: 'SelectPath',
    logo: selectpathLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'genesis',
    path: 'genesis',
    title: 'Genesis Land Development',
    logo: genesisLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'calgaryzoo',
    path: 'calgaryzoo',
    title: 'Wilder Institute/Calgary Zoo',
    logo: calgaryzooLogo,
    logoClass: 'wide-logo',
    frenchPage: true,
    trustBasedNoEF: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'widerfunnel',
    path: 'widerfunnel',
    title: 'WiderFunnel Marketing Inc.',
    logo: widerfunnelLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    marketingURL: 'sentinelpolymers',
    path: 'sentinelpolymers',
    title: 'Sentinel Polymers Canada Inc.',
    logo: sentinelLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    EAP: true,
    dependants: true,
  },
  {
    marketingURL: 'workforcewindsoressex',
    path: 'workforcewindsoressex',
    title: 'Workforce Windsor Essex',
    logo: workforcewindsoressexLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    EAP: true,
    dependants: true,
  },
  {
    marketingURL: 'cube',
    path: 'cube',
    title: 'Tygus Inc.',
    logo: tygusIncLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    EAP: true,
    dependants: true,
    showFlags: true,
  },
  {
    marketingURL: 'rexall',
    path: 'rexall',
    title: 'Rexall',
    logo: rexallLogo,
    logoClass: 'wide-logo',
    trustBasedNoEF: true,
    EAP: true,
    dependants: true,
  },
  {
    marketingURL: 'thenorthpinefoundation',
    path: 'thenorthpinefoundation',
    title: 'The Northpine Foundation',
    logo: northpineLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    EAP: true,
    dependants: true,
  },
  {
    marketingURL: 'cyclicarx',
    path: 'cyclicarx',
    title: 'Cyclica Inc',
    logo: cyclicaLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    EAP: true,
    dependants: true,
    showFlags: true,
  },
  {
    marketingURL: 'mcdonalds',
    path: 'mcdonalds',
    title: "McDonald's",
    logo: mcdonaldsLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    EFAP: true,
    frenchPage: true,
    dependants: true,
  },
  {
    marketingURL: 'afilias',
    path: 'afilias',
    title: 'Afilias',
    logo: afiliasLogo,
    logoClass: 'wide-logo',
    dependants: true,
    EAP: true,
    fullyGated: true,
  },
  {
    path: 'vidyard',
    marketingURL: 'vidyard',
    title: 'Vidyard',
    logo: vidyardLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    EAP: true,
    dependants: true,
  },
  {
    path: 'westjet',
    marketingURL: 'westjet',
    title: 'WestJet',
    logo: westJetLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    EAP: true,
    frenchPage: true,
    dependants: true,
  },
  {
    marketingURL: 'canadapooch',
    path: 'canadapooch',
    title: 'Canada Pooch',
    logo: canadapoochLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    showFlags: true,
    EAP: true,
    dependants: true,
  },
  {
    path: 'venngage',
    marketingURL: 'venngage',
    title: 'Venngage',
    logo: venngageLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    fullyGated: true,
    showFlags: true,
  },
  {
    path: 'public',
    marketingURL: 'public',
    title: 'Public Inc',
    logo: publicLogo,
    logoClass: 'wide-logo',
    EAP: true,
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'bosleystaff',
    marketingURL: 'bosleystaff',
    title: 'Bosley Real Estate',
    logo: bosleyLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'astrazeneca',
    marketingURL: 'astrazeneca',
    title: 'AstraZeneca',
    logo: astraLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    fullyGated: true,
    usOnly: true,
  },
  {
    path: 'browze',
    marketingURL: 'browze',
    title: 'Browze',
    logo: browzeLogo,
    logoClass: 'small-logo',
    EFAP: true,
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'nanoleaf',
    marketingURL: 'nanoleaf',
    title: 'Nanoleaf',
    logo: nanoleafLogo,
    logoClass: 'small-logo',
    EFAP: true,
    dependants: true,
    fullyGated: true,
    frenchPage: true,
  },
  {
    path: 'mcco',
    marketingURL: 'mcco',
    title: 'MCCO',
    logo: mccoLogo,
    logoClass: 'wide-logo',
    EFAP: true,
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'nbpl',
    marketingURL: 'nbpl',
    title: 'North Bay Public Library',
    logo: nbplLogo,
    logoClass: 'small-logo',
    EFAP: true,
    dependants: true,
    trustBasedWithEF: true,
  },
  {
    marketingURL: 'atb',
    path: 'atb',
    title: 'ATB Financial',
    logo: atbLogo,
    logoClass: 'small-logo',
    EFAP: true,
    dependants: true,
  },
  {
    marketingURL: 'aecon',
    path: 'aecon',
    title: 'Aecon',
    logo: aeconLogo,
    logoClass: 'wide-logo',
    EFAP: true,
    dependants: true,
    fullyGated: true,
  },
  {
    marketingURL: 'kearney',
    path: 'kearney',
    title: 'Kearney',
    logo: kearneyLogo,
    logoClass: 'wide-logo',
    dependants: true,
    fullyGated: true,
    EFAP: true,
    efapSecond:
      'Through the EFAP, you have access to unlimited counselling support for yourself and your immediate family.',
  },
  {
    marketingURL: 'thg',
    path: 'thg',
    title: 'The Humphrey Group',
    logo: thgLogo,
    logoClass: 'small-logo',
    dependants: true,
    fullyGated: true,
    EAP: true,
  },
  {
    marketingURL: 'ni',
    path: 'thg',
    title: 'Niagara Institute',
    logo: niLogo,
    logoClass: 'small-logo',
    dependants: true,
    fullyGated: true,
    EAP: true,
  },
  {
    marketingURL: 'humi',
    path: 'humi',
    title: 'Humi',
    logo: humiLogo,
    logoClass: 'small-logo',
    dependants: true,
    fullyGated: true,
    EFAP: true,
    EAP: true,
  },
  {
    marketingURL: 'koho',
    path: 'koho',
    title: 'KOHO',
    logo: kohoLogo,
    logoClass: 'small-logo',
    dependants: true,
    fullyGated: true,
    EAP: true,
    showFlags: true,
  },
  {
    marketingURL: 'dsb1',
    path: 'dsb1',
    title: 'District School Board Ontario North East',
    logo: dsb1Logo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    EFAP: true,
  },
  {
    marketingURL: 'brightandearly',
    path: 'brightandearly',
    title: 'Bright + Early',
    logo: brightandearlyLogo,
    logoClass: 'small-logo',
    dependants: true,
    trustBasedWithEF: true,
    EFAP: true,
  },
  {
    marketingURL: 'pelmorex',
    path: 'pelmorex',
    title: 'Pelmorex',
    logo: pelmorexLogo,
    logoClass: 'wide-logo',
    EFAP: true,
    frenchPage: true,
    dependants: true,
  },
  {
    marketingURL: 'prosper',
    path: 'prosper',
    title: 'Prosper',
    logo: prosperLogo,
    logoClass: 'prosper-logo',
    EFAP: true,
  },
  {
    marketingURL: 'techstars',
    path: 'techstars',
    title: 'Techstars',
    logo: techstarsLogo,
    logoClass: 'small-logo',
    EFAP: true,
  },
  {
    marketingURL: 'sunwing',
    path: 'sunwing',
    title: 'Sunwing',
    logo: sunwingLogo,
    logoClass: 'wide-logo',
    dependants: true,
    frenchPage: true,
    EAP: true,
    trustBasedNoEF: true,
  },
  {
    marketingURL: 'moseyandmosey',
    path: 'moseyandmosey',
    title: 'Mosey & Mosey',
    logo: moseyLogo,
    logoClass: 'small-logo',
    dependants: true,
    fullyGated: true,
    EAP: true,
  },
  {
    marketingURL: 'intelex',
    path: 'intelex',
    title: 'Intelex',
    logo: intelexLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    showFlags: true,
    EAP: true,
    dependants: true,
  },
  {
    marketingURL: 'minesense',
    path: 'minesense',
    title: 'MineSense',
    logo: minesenseLogo,
    logoClass: 'small-logo',
    dependants: true,
    EFAP: true,
    fullyGated: true,
    showFlags: true,
  },
  {
    marketingURL: 'willful',
    path: 'willful',
    title: 'Willful',
    logo: willfulLogo,
    logoClass: 'wide-logo',
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'reasonone',
    marketingURL: 'reasonone',
    title: 'Reason One',
    logo: reasononeLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'gscers',
    marketingURL: 'gscers',
    title: 'Green Shield Canada',
    frTitle: 'les avantages green shield',
    logo: gscLogoEn,
    frLogo: gscLogoFr,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'gsh',
    marketingURL: 'gsh',
    title: 'Green Shield Holdings',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'nextdimension',
    marketingURL: 'nextdimension',
    title: 'Next Dimension Inc',
    logo: nextDimensionLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
    showFlags: true,
  },
  {
    path: 'cheme',
    marketingURL: 'cheme',
    title: 'Cheme Engineering',
    logo: chemeLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    marketingURL: 'gsc-eap',
    path: 'gsc-eap',
    title: 'Green Shield Canada',
    logo: newGscLogoEN,
    frLogo: newGscLogoFR,
    logoClass: 'wide-logo',
    EAP: true,
    frenchPage: true,
    efapSecond:
      'Your initial individual consultation is complimentary, followed by 5 hours of individual counselling and 5 hours of couples counselling. Once you’ve used all of your available EAP hours, you and your eligible dependents have the ability to continue with the same counsellor for longer-term counselling needs, with costs reimbursable under your benefits plan, through a health care spending account (if applicable), or paid privately – at rates that are significantly lower than the national average.',
    efapSecondFr:
      'Votre première consultation individuelle est gratuite, ainsi que cinq heures de consultation individuelle subséquentes et de cinq heures de consultation de couple. Une fois que vous avez utilisé toutes les heures de PAE dont vous disposez, vous et les personnes à votre charge admissibles avez la possibilité de continuer à consulter le même conseiller pour des besoins à plus long terme, les coûts étant remboursables au titre de votre régime d’avantages sociaux, d’un compte de frais pour soins de santé (le cas échéant) ou d’un régime privé, et ce, à des tarifs considérablement inférieurs à la moyenne nationale.',
  },
  {
    path: 'acct_support',
    marketingURL: 'acct_support',
    title: 'Academy of Canadian Cinema & Television',
    logo: acctLogo,
    logoClass: 'wide-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'tealbook',
    marketingURL: 'tealbook',
    title: 'Tealbook Inc',
    logo: tealbookLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
    frenchPage: true,
    showFlags: true,
  },
  {
    path: 'snapcommerce',
    marketingURL: 'snapcommerce',
    title: 'Snapcommerce Technologies Inc.',
    logo: snapCommerceLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'flexed',
    marketingURL: 'flexed',
    title: 'Flex ED',
    logo: flexEDLogo,
    logoClass: 'wide-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'benecaid',
    marketingURL: 'benecaid',
    title: 'Benecaid',
    logo: benecaidLogo,
    frLogo: benecaidFrLogo,
    logoClass: 'tiny-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'environics',
    marketingURL: 'environics',
    title: 'Environics Analytics',
    logo: environicsAnalyticsLogo,
    logoClass: 'wide-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'takeiteasy',
    marketingURL: 'takeiteasy',
    title: 'Take It Easy Personal Concierge Inc.',
    logo: takeItEasyLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'aem',
    marketingURL: 'aem',
    title: 'Agnico Eagle',
    logo: agnicoEagleLogo,
    logoClass: 'wide-logo',
    EAP: true,
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'craftpublicrelations',
    marketingURL: 'craftpublicrelations',
    title: 'Craft Public Relations',
    logo: craftPublicRelationsLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'talentminded',
    marketingURL: 'talentminded',
    title: 'TalentMinded',
    logo: talentmindedLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'fika',
    marketingURL: 'fika',
    title: 'FIKA Herbal Goods',
    logo: fikaLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'notch',
    marketingURL: 'notch',
    title: 'Vendorhero Inc.',
    logo: vendorheroLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
    showFlags: true,
  },
  {
    marketingURL: 'och',
    path: 'och',
    title: 'Ottawa Community Housing',
    logo: ochLogo,
    logoClass: 'wide-logo',
    EFAP: true,
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'bcspca',
    marketingURL: 'bcspca',
    title: 'BC SPCA',
    logo: bcspcaLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    trustBasedNoEF: true,
  },
  {
    path: 'netxinc',
    marketingURL: 'netxinc',
    title: 'NetX Inc.',
    logo: netXLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'acces',
    marketingURL: 'acces',
    title: 'Acces Employment',
    logo: accesEmploymentLogo,
    logoClass: 'wide-logo',
    EAP: true,
    dependants: true,
    fullyGated: true,
    frenchPage: true,
  },
  {
    path: 'jalf',
    marketingURL: 'jalf',
    title: 'JALF',
    logo: jalfLogo,
    logoClass: 'small-logo',
    dependants: true,
    fullyGated: true,
    frenchPage: true,
  },
  {
    path: 'amplifon',
    marketingURL: 'amplifon',
    title: 'Amplifon',
    logo: amplifonLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'IMV',
    marketingURL: 'IMV',
    title: 'IMV Inc.',
    logo: imvLogo,
    logoClass: 'wide-logo',
    EAP: true,
    frenchPage: true,
    dependants: true,
    fullyGated: true,
    showFlags: true,
  },
  {
    path: 'junopharm',
    marketingURL: 'junopharm',
    title: 'Juno Pharmaceuticals',
    logo: junoLogo,
    logoClass: 'medium-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'jssupplybb',
    marketingURL: 'jssupplybb',
    title: 'Johnstone Supply',
    logo: johnstoneLogo,
    logoClass: 'medium-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'element',
    marketingURL: 'element',
    title: 'Element Fleet',
    logo: elementLogo,
    logoClass: 'small-logo',
    EAP: true,
    frenchPage: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'pursuit',
    marketingURL: 'pursuit',
    title: 'Pursuit Collection',
    logo: pursuitLogo,
    logoClass: 'wide-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
    customLabelForSignUpInputField: 'eap.email',
  },
  {
    path: 'brane',
    marketingURL: 'brane',
    title: 'Brane',
    logo: braneLogo,
    logoClass: 'small-logo',
    EAP: true,
    frenchPage: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'longueuil',
    marketingURL: 'longueuil',
    title: 'Ville de Longueuil',
    logo: longueuilLogo,
    logoClass: 'small-logo',
    EAP: true,
    frenchPage: true,
    fullyGated: true,
    dependants: true,
    employeeIdSignup: true,
    finalParagraph:
      '<span class="bold">It is the responsibility of the employee to verify their insurance coverage.</span>',
    finalParagraphFr:
      '<span class="bold">Il est de la responsabilité de l’employé de vérifier ses couvertures d’assurances.</span>',
  },
  {
    path: 'i-sight',
    marketingURL: 'i-sight',
    title: 'I-Sight',
    logo: iSightLogo,
    logoClass: 'small-logo',
    EAP: true,
    frenchPage: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'stclairmechanical',
    marketingURL: 'stclairmechanical',
    title: 'St. Clair Mechanical ',
    logo: stClairLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    marketingURL: 'benecaid-eap',
    path: 'benecaid-eap',
    title: 'Benecaid',
    logo: benecaidLogo,
    frLogo: benecaidFrLogo,
    logoClass: 'tiny-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
    frenchPage: true,
    efapSecond:
      'Included in your EAP is individual and couples counselling, health coaching, life coaching, career coaching, financial and legal consultations. Once you’ve used all of your available EAP hours, you and your eligible dependents have the ability to continue with the same counsellor for longer-term counselling needs, with costs reimbursable under your benefits plan, through a health care spending account (if applicable), or paid privately.',
    efapSecondFr:
      "Votre PAE comprend des services de counseling individuel et de couple, de coaching de santé, de coaching de vie, de coaching de carrière, de consultations financières et juridiques.  Lorsque vous aurez utilisé toutes les heures offertes par votre PAE, vous et vos personnes à charge admissibles pourrez entreprendre un counseling à long terme avec le même conseiller ou la même conseillère, et les coûts du counseling vous seront remboursables en vertu de votre régime d'avantages sociaux, d'un compte de gestion des dépenses santé (le cas échéant), ou payés à titre privé.",
  },
  {
    marketingURL: 'honeybee-eap',
    path: 'honeybee-eap',
    title: 'Honeybee',
    logo: honeybeeLogo,
    frLogo: honeybeeFrLogo,
    logoClass: 'tiny-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
    frenchPage: true,
    efapSecond:
      'Included in your EAP is individual and couples counselling, health coaching, life coaching, career coaching, financial and legal consultations. Once you’ve used all of your available EAP hours, you and your eligible dependents have the ability to continue with the same counsellor for longer-term counselling needs, with costs reimbursable under your benefits plan, through a health care spending account (if applicable), or paid privately.',
    efapSecondFr:
      "Votre PAE comprend des services de counseling individuel et de couple, de coaching de santé, de coaching de vie, de coaching de carrière, de consultations financières et juridiques.  Lorsque vous aurez utilisé toutes les heures offertes par votre PAE, vous et vos personnes à charge admissibles pourrez entreprendre un counseling à long terme avec le même conseiller ou la même conseillère, et les coûts du counseling vous seront remboursables en vertu de votre régime d'avantages sociaux, d'un compte de gestion des dépenses santé (le cas échéant), ou payés à titre privé.",
  },
  {
    path: 'quidividibrewery',
    marketingURL: 'quidividibrewery',
    title: 'Quidi Vidi Brewing Company',
    logo: qvbcLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'townofnewmarket',
    marketingURL: 'townofnewmarket',
    title: 'Town Of Newmarket',
    logo: newmarketLogo,
    logoClass: 'small-logo',
    EFAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'avanti',
    marketingURL: 'avanti',
    title: 'Avanti Software',
    logo: avantiLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'HRPA',
    marketingURL: 'HRPA',
    title: 'Human Resources Professionals Association',
    logo: hrpaLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'BallisticArts',
    marketingURL: 'BallisticArts',
    title: 'Ballistic Arts Media Studios',
    logo: ballisticArtsLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'oldoak',
    marketingURL: 'oldoak',
    title: 'Old Oak',
    logo: oldOakLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'imp',
    marketingURL: 'imp',
    title: 'IMP Digital',
    logo: impLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'p4g',
    marketingURL: 'p4g',
    title: 'P4G',
    logo: p4gLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: '561jobs',
    marketingURL: '561jobs',
    title: 'Common Good Solutions',
    logo: commonGoodSolutionsLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'orbit',
    marketingURL: 'orbit',
    title: 'Orbit',
    logo: orbitLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
    showFlags: true,
  },
  {
    path: 'blazing',
    marketingURL: 'blazing',
    title: 'Baicorp Financial Inc.',
    logo: blazingLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'quasar',
    marketingURL: 'quasar',
    title: 'Quasar Consulting Group',
    logo: quasarPratusLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'lamsar',
    marketingURL: 'lamsar',
    title: 'LamSar Inc',
    logo: lamsarLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'rocklandflooring',
    marketingURL: 'rocklandflooring',
    title: 'Rockland Flooring',
    logo: rocklandLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'mlse',
    marketingURL: 'mlse',
    title: 'MLSE',
    logo: mlseLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'dyspatch',
    marketingURL: 'dyspatch',
    title: 'Dyspatch',
    logo: dyspatchLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'pixelunion',
    marketingURL: 'pixelunion',
    title: 'Pixel Union Design Ltd',
    logo: pixelunionLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
    showFlags: true,
  },
  {
    path: 'dtssab',
    marketingURL: 'dtssab',
    title: 'District of Timiskaming Social Services Administration Board',
    logo: dtssabLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
    frenchPage: true,
    frTitle:
      "Conseil d'administration des services sociaux du district de Timiskaming",
  },
  {
    path: 'knit',
    marketingURL: 'knit',
    title: 'Knit Agency',
    logo: knitLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'HalifaxPartnership',
    marketingURL: 'HalifaxPartnership',
    title: 'Halifax Partnership',
    logo: halifaxPartnershipLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'ablemployment',
    marketingURL: 'ablemployment',
    title: 'ABL Employment',
    logo: ablemploymentLogo,
    logoClass: 'small-logo',
    EAP: true,
    employeeIdSignup: true,
    dependants: true,
  },
  {
    path: 'ESI',
    marketingURL: 'ESI',
    title: 'Epic Story Interactive',
    logo: esiLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    EAP: true,
    dependants: true,
  },
  {
    path: 'enginuityinc',
    marketingURL: 'enginuityinc',
    title: 'Enginuity Inc.',
    logo: enginuityLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    marketingURL: 'arcadis-ibigroup',
    path: 'arcadis-ibigroup',
    title: 'Arcadis IBI Group',
    logo: ibigroupLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    trustBasedNoEF: true,
  },
  {
    marketingURL: 'ibigroup',
    redirectToURL: 'arcadis-ibigroup',
  },
  {
    marketingURL: 'dream',
    path: 'dream',
    title: 'Dream',
    logo: dreamLogo,
    logoClass: 'wide-logo',
    EAP: true,
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'partnership',
    marketingURL: 'partnership',
    title: 'The&Partnership',
    logo: partnershipLogo,
    logoClass: 'wide-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'LFT',
    marketingURL: 'LFT',
    title: 'Lycée Français de Toronto',
    logo: lyceeLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    frenchPage: true,
    domainGated: true,
    email_domain: ['lft.ca'],
  },
  {
    path: 'rdbrck',
    marketingURL: 'rdbrck',
    title: 'Redbrick Technologies Inc.',
    logo: redbrickLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'FortisInc',
    marketingURL: 'FortisInc',
    title: 'Fortis Inc.',
    logo: fortisLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'CKFHT',
    marketingURL: 'CKFHT',
    title: 'Chatham Kent Family Health Team',
    logo: chathamKentHealthTeamLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'Zavitz',
    marketingURL: 'Zavitz',
    title: 'Zavitz Insurance & Wealth Inc.',
    logo: zavitzLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    trustBasedWithEF: true,
  },
  {
    path: 'ApexPR',
    marketingURL: 'ApexPR',
    title: 'Apex Public Relations',
    logo: apexprLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    domainGated: true,
    email_domain: ['apexpr.com'],
  },
  {
    path: 'MFC',
    marketingURL: 'MFC',
    title: 'Markham Fertility Centre',
    logo: markhamFertilityCentreLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'guusto',
    marketingURL: 'guusto',
    title: 'Guusto',
    logo: guustoLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    domainGated: true,
    email_domain: ['guusto.com'],
  },
  {
    path: 'gillfor',
    marketingURL: 'gillfor',
    title: 'Gillfor Distribution Inc.',
    logo: gillforLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'y2y',
    marketingURL: 'y2y',
    title: 'Yellowstone to Yukon Conservation Initiative',
    logo: yellowstoneToYukonConservationInitiativeLogo,
    logoClass: 'small-logo',
    EAP: true,
    showFlags: true,
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'eastpointEngineering',
    marketingURL: 'eastpointEngineering',
    title: 'Eastpoint Engineering',
    logo: eastpointLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    trustBasedWithEF: true,
  },
  {
    path: 'VHA',
    marketingURL: 'VHA',
    title: 'VHA Home Healthcare',
    logo: champlainLogo,
    secondLogo: vhaLogo,
    logoClass: 'small-logo',
    frenchPage: true,
    EAP: true,
    dependants: true,
    trustBasedNoEF: true,
  },
  {
    marketingURL: 'champlain',
    redirectToURL: 'VHA',
  },
  {
    path: 'concord',
    marketingURL: 'concord',
    title: 'Concord Premium Meats',
    logo: concordLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedWithEF: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'feminuity',
    marketingURL: 'feminuity',
    title: 'Feminuity',
    logo: feminuityLogo,
    logoClass: 'small-logo',
    EAP: true,
    showFlags: true,
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'WWS',
    marketingURL: 'WWS',
    title: 'Whistler Welding Services LTD',
    logo: wwsLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'TSESteel',
    marketingURL: 'TSESteel',
    title: 'TSE Steel Ltd.',
    logo: tsesteelLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    trustBasedWithEF: true,
  },
  {
    path: 'feminuity',
    marketingURL: 'feminuity',
    title: 'Feminuity',
    logo: feminuityLogo,
    logoClass: 'small-logo',
    EAP: true,
    showFlags: true,
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'feminuity',
    marketingURL: 'feminuity',
    title: 'Feminuity',
    logo: feminuityLogo,
    logoClass: 'small-logo',
    EAP: true,
    showFlags: true,
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'bluearthrenewables',
    marketingURL: 'bluearthrenewables',
    title: 'BluEarth Renewables Inc',
    logo: bluearthLogo,
    logoClass: 'small-logo',
    EAP: true,
    showFlags: true,
    dependants: true,
    trustBasedNoEF: true,
  },
  {
    path: 'bluearthassetmanagement',
    marketingURL: 'bluearthassetmanagement',
    title: 'BluEarth Asset Management',
    logo: bluearthLogo,
    logoClass: 'small-logo',
    EAP: true,
    showFlags: true,
    dependants: true,
    trustBasedNoEF: true,
  },
  {
    path: 'cmaw',
    marketingURL: 'cmaw',
    title: 'Construction Maintenance and Allied Workers Canada',
    logo: cmawLogo,
    logoClass: 'small-logo',
    EAP: true,
    frenchPage: true,
    dependants: true,
    trustBasedNoEF: true,
  },
  {
    path: 'mariner',
    marketingURL: 'mariner',
    title: 'Mariner Partners',
    logo: marinerLogo,
    logoClass: 'wide-logo',
    EAP: true,
    frenchPage: true,
    dependants: true,
    trustBasedWithEF: true,
  },
  {
    path: 'swiftmedical',
    marketingURL: 'swiftmedical',
    title: 'Swift Medical',
    logo: swiftLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
    showFlags: true,
  },
  {
    path: 'foremancompany',
    marketingURL: 'foremancompany',
    title: 'Foreman & Company Professional Corporation',
    logo: foremanLogo,
    logoClass: 'wide-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'onenet',
    marketingURL: 'onenet',
    title: 'One Net Agency',
    logo: onenetLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'AlphaCT',
    marketingURL: 'AlphaCT',
    title: 'Alpha Corporate Technologies',
    logo: alphactLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'silverorange',
    marketingURL: 'silverorange',
    title: 'silverorange',
    logo: silverorangeLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'accedo',
    marketingURL: 'accedo',
    title: 'Accedo',
    logo: accedoLogo,
    logoClass: 'wide-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'eiqc',
    marketingURL: 'eiqc',
    frTitle:
      'Régime des étudiants internationaux – Centre d’acquisitions Gouvernementales (CAG)',
    title:
      "International students' plan - Centre d’acquisitions Gouvernementales (CAG)",
    logo: newGscLogoEN,
    frLogo: newGscLogoFR,
    logoClass: 'wide-logo',
    EAP: true,
    employeeIdSignup: true,
    dependants: false,
    frenchPage: true,
  },
  {
    path: 'JCCGV',
    marketingURL: 'JCCGV',
    title: 'Jewish Community Centre of Greater Vancouver',
    EAP: true,
    fullyGated: true,
    dependants: true,
    logo: jccgvLogo,
    logoClass: 'small-logo',
  },
  {
    path: 'ControlsEquipment',
    marketingURL: 'ControlsEquipment',
    title: 'Controls & Equipment Ltd.',
    EAP: true,
    fullyGated: true,
    dependants: true,
    logo: controlsequipmentLogo,
    logoClass: 'small-logo',
  },
  {
    path: 'cintas',
    marketingURL: 'cintas',
    title: 'Cintas Corporation',
    EAP: true,
    trustBasedNoEF: true,
    frenchPage: true,
    dependants: true,
    logo: cintasLogo,
    frLogo: cintasFRLogo,
    logoClass: 'small-logo',
  },
  {
    path: 'dll',
    marketingURL: 'dll',
    title: 'De Lage Landen Financial Services Canada Inc.',
    EAP: true,
    fullyGated: true,
    frenchPage: true,
    dependants: true,
    logo: dllLogo,
    logoClass: 'small-logo',
  },
  {
    path: 'BlueprintADE',
    marketingURL: 'BlueprintADE',
    title: 'Blueprint ADE',
    EAP: true,
    fullyGated: true,
    dependants: true,
    logo: blueprintLogo,
    logoClass: 'small-logo',
  },
  {
    path: 'penmorebenefits',
    marketingURL: 'penmorebenefits',
    title: 'Penmore Benefits Inc.',
    EAP: true,
    domainGated: true,
    email_domain: ['smartchoicebenefits.com', 'penmorebenefits.com'],
    dependants: true,
    logo: penmorebenefitsLogo,
    logoClass: 'small-logo',
  },
  {
    path: 'Timbercreek',
    marketingURL: 'Timbercreek',
    title: 'Timbercreek Capital Inc.',
    EAP: true,
    fullyGated: true,
    frenchPage: true,
    dependants: true,
    logo: timbercreekLogo,
    logoClass: 'small-logo',
  },
  {
    path: 'canadagamescentre',
    marketingURL: 'canadagamescentre',
    title: 'Canada Games Centre',
    EAP: true,
    trustBasedWithEF: true,
    logo: canadagamescenterLogo,
    logoClass: 'small-logo',
  },
  {
    path: 'cineplex',
    marketingURL: 'cineplex',
    title: 'Cineplex',
    frenchPage: true,
    dependants: true,
    fullyGated: true,
    employeeIdSignup: true,
    EAP: true,
    logo: cineplexenglishLogo,
    frLogo: cineplexfrenchLogo,
    logoClass: 'small-logo',
  },
  {
    path: 'eapcineplex',
    marketingURL: 'eapcineplex',
    title: 'Cineplex',
    frenchPage: true,
    dependants: true,
    fullyGated: true,
    employeeIdSignup: true,
    EAP: true,
    logo: cineplexenglishLogo,
    frLogo: cineplexfrenchLogo,
    logoClass: 'small-logo',
  },
  {
    path: 'tecvalco',
    marketingURL: 'tecvalco',
    title: 'Tecvalco LTD',
    logo: tecvalcoLogo,
    logoClass: 'wide-logo',
    EAP: true,
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    marketingURL: 'maple-eap-uv',
    marketingURLFr: 'maple-pae-uv',
    path: 'maple-eap-uv',
    title: 'Maple-UV',
    secondLogo: uvLogo,
    frSecondLogo: uvFrLogo,
    logo: mapleLogo,
    logoClass: 'wide-logo',
    EAP: true,
    customLabelForSignUpInputField: 'eap.customMapleUvSignUp',
    trustBasedNoEF: true,
    frenchPage: true,
  },
  {
    marketingURL: 'maple-eap',
    path: 'maple-eap',
    title: 'Maple',
    logo: mapleLogo,
    logoClass: 'small-logo',
    EAP: true,
    frenchPage: true,
    efapSecond:
      'Included in your EAP is individual and couples counselling, health coaching, life coaching, career coaching, financial and legal consultations.',
    efapSecondFr:
      'Votre PAE comprend des services de counseling individuel et de couple, de coaching de santé, de coaching de vie, de coaching de carrière, de consultations financières et juridiques.',
    dependants: true,
  },
  {
    path: 'alpinehelicopter',
    marketingURL: 'alpinehelicopter',
    title: 'Alpine Helicopters',
    logo: alpineHelicopterLogo,
    logoClass: 'wide-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'medaviebsb',
    marketingURL: 'medaviebsb',
    title: 'Medavie Benefits for Small Business',
    logo: medaviebsbEnLogo,
    frLogo: medaviebsbFrLogo,
    registrationFlow: 'redesign_2023',
    logoClass: 'small-logo',
    EFAP: true,
    trustBasedNoEF: true,
    dependants: true,
    frenchPage: true,
  },
  {
    marketingURL: 'medaviegpe',
    redirectToURL: 'medaviebsb',
  },
  {
    path: 'FD',
    marketingURL: 'FD',
    title: 'Fundraising Direct Limited',
    logo: fdLogo,
    frLogo: fdLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'NetworkInnovations',
    marketingURL: 'NetworkInnovations',
    title: 'Network Innovations, Inc.',
    logo: niMasterLogo,
    logoClass: 'wide-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'bluivy',
    marketingURL: 'bluivy',
    title: 'Blu Ivy Group',
    logo: bluIvyLogo,
    logoClass: 'wide-logo',
    EAP: true,
    domainGated: true,
    email_domain: ['inkblottherapy.com', 'bluivygroup.com'],
    dependants: true,
    showFlags: true,
  },
  {
    path: 'RLG',
    marketingURL: 'RLG',
    title: 'RLG Systems Canada Inc.',
    logo: rglLogo,
    logoClass: 'wide-logo',
    EAP: true,
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'CEL',
    marketingURL: 'CEL',
    title: 'CEL Electric',
    logo: celLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'BML',
    marketingURL: 'BML',
    title: 'BML Multi Trades Group Ltd',
    logo: bmlLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'cbncoeap',
    marketingURL: 'cbncoeap',
    title: 'Canadian Bank Note Company, Limited',
    logo: canadianBankNoteLogo,
    showFlags: true,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedNoEF: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'SLCS',
    marketingURL: 'SLCS',
    title: "St. Leonard's Community Services",
    logo: SLCSLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'Timiskaming',
    marketingURL: 'Timiskaming',
    title: 'The Timiskaming Health Unit',
    logo: TimiskamingHealthUnitLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'pathwaysEHC',
    marketingURL: 'pathwaysEHC',
    title: 'Pathways Employment Health Centre',
    logo: PEHCLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'EORN',
    marketingURL: 'EORN',
    title: 'Eastern Ontario Regional Network',
    logo: EORNLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'interlake',
    marketingURL: 'interlake',
    title: 'Interlake Acquisition Corporation Limited',
    frenchPage: true,
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'massivedamage',
    marketingURL: 'massivedamage',
    title: 'Massive Damage, Inc',
    logo: massdmgLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'asebp',
    marketingURL: 'asebp',
    title: 'ASEBP',
    logo: ASEBPLogo,
    logoClass: 'small-logo',
    EFAP: true,
    employeeIdSignup: true,
    fullyGated: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'valence',
    marketingURL: 'valence',
    title: 'Valence',
    showFlags: true,
    logo: valenceLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'koboldcorporation',
    marketingURL: 'koboldcorporation',
    title: 'Kobold Corporation',
    logo: KoboldLogo,
    logoClass: 'wide-logo',
    EAP: true,
    domainGated: true,
    email_domain: ['inkblottherapy.com', 'koboldinc.com'],
    dependants: true,
  },
  {
    path: 'tegus',
    marketingURL: 'tegus',
    title: 'Tegus Holdings Inc',
    logo: TegusLogo,
    logoClass: 'wide-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'tchc',
    marketingURL: 'tchc',
    title: 'Toronto Community Housing Corporation (TCHC)',
    logo: TchcLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'nada',
    marketingURL: 'nada',
    title: 'Nada',
    logo: NadaLogo,
    logoClass: 'wide-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'logixx',
    marketingURL: 'logixx',
    title: 'Logixx Security Inc.',
    logo: LogixxLogo,
    logoClass: 'wide-logo',
    EAP: true,
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'ecadlabs',
    marketingURL: 'ecadlabs',
    title: 'ECAD Labs',
    logo: EcadLogo,
    logoClass: 'wide-logo',
    EAP: true,
    domainGated: true,
    email_domain: ['ecadlabs.com', 'inkblottherapy.com'],
    dependants: true,
  },
  {
    path: '4refuel',
    marketingURL: '4refuel',
    title: '4Refuel',
    logo: RefuelLogo,
    logoClass: 'wide-logo',
    EAP: true,
    fullyGated: true,
    frenchPage: true,
    dependants: true,
  },
  {
    path: 'finning-canada',
    marketingURL: 'finning-canada',
    title: 'Finning',
    logo: FinningLogo,
    logoClass: 'wide-logo',
    EAP: true,
    fullyGated: true,
    frenchPage: true,
    dependants: true,
  },
  {
    path: 'TMCAN',
    marketingURL: 'TMCAN',
    title: 'Tokio Marine Canada Ltd.',
    logo: TokioLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedWithEF: true,
    frenchPage: true,
    dependants: true,
  },
  {
    path: 'enmax',
    marketingURL: 'enmax',
    title: 'Enmax',
    logo: EnmaxLogo,
    logoClass: 'wide-logo',
    EFAP: true,
    fullyGated: true,
    frenchPage: true,
    dependants: true,
  },
  {
    path: 'freshcity',
    marketingURL: 'freshcity',
    title: 'Fresh City Farms Inc.',
    logo: FreshCityLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'melchersconstruction',
    marketingURL: 'melchersconstruction',
    title: 'Melchers Construction Limited',
    logo: MelchersLogo,
    logoClass: 'wide-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'steamwhistle',
    marketingURL: 'steamwhistle',
    title: 'Steam Whistle Brewing',
    logo: SteamWhistleLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    EAP: true,
    dependants: true,
  },
  {
    path: 'gcg',
    marketingURL: 'gcg',
    title: 'Guardian Capital Group',
    logo: GuardianCapitalLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    EAP: true,
    frenchPage: true,
    dependants: true,
  },
  {
    path: 'countyofrenfrew',
    marketingURL: 'countyofrenfrew',
    title: 'The Corporation of the County of Renfrew',
    logo: RenfrewLogo,
    logoClass: 'wide-logo',
    trustBasedWithEF: true,
    EAP: true,
    dependants: true,
  },
  {
    path: 'wajax',
    marketingURL: 'wajax',
    title: 'Wajax Limited',
    logo: WajaxLogo,
    trustBasedNoEF: true,
    frenchPage: true,
    logoClass: 'small-logo',
    dependants: true,
  },
  {
    path: 'monarchhouse',
    marketingURL: 'monarchhouse',
    title: 'Monarch House',
    logo: MonarchLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'foglers',
    marketingURL: 'foglers',
    title: 'Foglers, Rubinoff LLP',
    logo: FoglerLogo,
    logoClass: 'small-logo',
    EAP: true,
    domainGated: true,
    email_domain: ['inkblottherapy.com', 'foglers.com'],
    dependants: true,
  },
  {
    path: 'CCS',
    marketingURL: 'CCS',
    title: 'Credit Counselling Society',
    logo: CreditCounsellingLogo,
    logoClass: 'tiny-logo',
    EAP: true,
    frenchPage: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'ratesdotca',
    marketingURL: 'ratesdotca',
    title: 'RATESDOTCA',
    logo: RatesLogo,
    logoClass: 'wide-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'trilliummutual',
    marketingURL: 'trilliummutual',
    title: 'Trillium Mutual Insurance Company',
    logo: TrilliumLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'noibu',
    marketingURL: 'noibu',
    title: 'Noibu Technologies',
    logo: NoibuLogo,
    logoClass: 'wide-logo',
    EAP: true,
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'fsd',
    marketingURL: 'fsd',
    title: 'Foothills School District',
    logo: FSDLogo,
    logoClass: 'small-logo',
    trustBasedNoEF: true,
    frenchPage: true,
    dependants: true,
  },
  {
    path: 'firstcanadian',
    marketingURL: 'firstcanadian',
    title: 'First Canadian Insurance Corporation',
    logo: FCICLogo,
    frLogo: FCICFrLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    frenchPage: true,
    dependants: true,
    customLabelForSignUpInputField: 'eap.benefitCertificateSearch',
    customLabelForEmployeeId: 'eap.benefitCertificateNumber',
  },
  {
    path: 'navblue',
    marketingURL: 'navblue',
    title: 'NavBlue',
    logo: NavBlueLogo,
    logoClass: 'small-logo',
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'CESD',
    marketingURL: 'CESD',
    title: "Chinook's Edge School Division",
    logo: ChinookLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'burnside',
    marketingURL: 'burnside',
    title:
      'R.J. Burnside & Associates Ltd, Neegan Burnside Ltd., Well Initiatives Ltd.',
    logo: BurnsideLogo,
    logoClass: 'small-logo',
    EAP: true,
    domainGated: true,
    email_domain: ['rjburnside.com', 'inkblottherapy.com'],
    dependants: true,
  },
  {
    path: 'ethosautomation',
    marketingURL: 'ethosautomation',
    title: 'Ethos Automation',
    logo: EthosLogo,
    logoClass: 'tiny-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'norr',
    marketingURL: 'norr',
    title: 'NORR Group Inc.',
    logo: NorrLogo,
    logoClass: 'tiny-logo',
    frenchPage: true,
    dependants: true,
  },
  {
    path: 'travelers',
    marketingURL: 'travelers',
    title: 'Travelers Insurance',
    logo: TravellersInsuranceLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'prairiemeats',
    marketingURL: 'prairiemeats',
    title: 'Prairie Meats LP',
    logo: PrairieMeatsLogo,
    logoClass: 'small-logo',
    trustBasedWithEF: true,
    EAP: true,
    dependants: true,
  },
  {
    path: 'abparamedics',
    marketingURL: 'abparamedics',
    title: 'Alberta College of Paramedics',
    logo: AlbertaCollegeLogo,
    logoClass: 'wide-logo',
    domainGated: true,
    email_domain: ['inkblottherapy', 'abparamedics.com'],
    EAP: true,
    dependants: true,
  },
  {
    path: 'alentic',
    marketingURL: 'alentic',
    title: 'Alentic Microscience Inc.',
    logo: AlenticLogo,
    logoClass: 'small-logo',
    domainGated: true,
    email_domain: ['inkblottherapy.com', 'alentic.com'],
    EAP: true,
    dependants: true,
  },
  {
    path: 'audienceview',
    marketingURL: 'audienceview',
    title: 'AudienceView Ticketing Co.',
    logo: AudienceViewLogo,
    logoClass: 'small-logo',
    frenchPage: true,
    EAP: true,
    domainGated: true,
    dependants: true,
  },
  {
    path: 'storck',
    marketingURL: 'storck',
    title: 'Storck Canada Inc.',
    logo: StorckLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'cairnsoneil',
    marketingURL: 'cairnsoneil',
    title: 'Cairns Oneil Strategic Media Inc.',
    logo: CairnsLogo,
    logoClass: 'wide-logo',
    EAP: true,
    domainGated: true,
    email_domain: ['inkblottherapy.com', 'cairnsoneil.com'],
    dependants: true,
  },
  {
    path: 'collingwood',
    marketingURL: 'collingwood',
    title: 'Town of Collingwood',
    logo: TownOfCollingwoodLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'wildforkcanada',
    marketingURL: 'wildforkcanada',
    title: 'Wild Fork Canada',
    logo: WildForkLogo,
    logoClass: 'wide-logo',
    EAP: true,
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'theleatherfactory',
    marketingURL: 'theleatherfactory',
    title: 'Leather Factory of Canada',
    logo: LeatherFactoryLogo,
    logoClass: 'tiny-logo',
    EAP: true,
    trustBasedWithEF: true,
  },
  {
    path: 'olivefertility',
    marketingURL: 'olivefertility',
    title: 'Olive Fertility Centre',
    logo: OliveLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    path: 'airdrieedge',
    marketingURL: 'airdrieedge',
    title: 'Airdrie Edge Gymnastics',
    logo: AirdrieLogo,
    logoClass: 'small-logo',
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'acumenex',
    marketingURL: 'acumenex',
    title: 'Acumenex',
    logo: AcumenexLogo,
    logoClass: 'wide-logo',
    trustBasedNoEF: true,
    EAP: true,
    dependants: true,
  },
  {
    path: 'oakenholdings',
    marketingURL: 'oakenholdings',
    title: 'Oaken Holdings',
    logo: OakenLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'westrock',
    marketingURL: 'westrock',
    title: 'WestRock',
    logo: WestrockLogo,
    logoClass: 'small-logo',
    frenchPage: true,
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'fnzcanada',
    marketingURL: 'fnzcanada',
    title: 'FNZ Canada Limited',
    logo: FNZLogo,
    logoClass: 'tiny-logo',
    trustBasedNoEF: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'CIS',
    marketingURL: 'CIS',
    title: 'Corporate Investigation Services LTD.',
    logo: CISLogo,
    logoClass: 'small-logo',
    EAP: true,
    domainGated: true,
    email_domain: ['corporateinvestigations.com', 'inkblottherapy.com'],
    dependants: true,
  },
  {
    path: 'clearview',
    marketingURL: 'clearview',
    title: 'Clearview Public Schools',
    logo: ClearviewLogo,
    logoClass: 'tiny-logo',
    EAP: true,
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'shiftsupplygroup',
    marketingURL: 'shiftsupplygroup',
    title: 'Shift Supply Group',
    logo: ShiftLogo,
    logoClass: 'tiny-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'badal',
    marketingURL: 'badal',
    title: 'Badal.io Inc.',
    logo: BadalLogo,
    logoClass: 'tiny-logo',
    domainGated: true,
    email_domain: ['badal.io', 'inkblottherapy.com'],
    EAP: true,
    frenchPage: true,
    dependants: true,
  },
  {
    path: 'villageicecream',
    marketingURL: 'villageicecream',
    title: 'Village Ice Cream',
    logo: VillageLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'fresenius',
    marketingURL: 'fresenius',
    title: 'Fresenius Medical Care',
    logo: FreseniusLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    frenchPage: true,
    EAP: true,
  },
  {
    path: 'geocomply',
    marketingURL: 'geocomply',
    title: 'GeoComply',
    logo: GeocomplyLogo,
    logoClass: 'wide-logo',
    EAP: true,
    domainGated: true,
    email_domain: ['geocomply.com', 'inkblottherapy.com'],
    dependants: true,
  },
  {
    path: 'duca',
    marketingURL: 'duca',
    title: 'DUCA Financial Services Credit Union Ltd.',
    logo: DucaLogo,
    logoClass: 'tiny-logo',
    fullyGated: true,
    frenchPage: true,
    dependants: true,
  },
  {
    path: 'stickeryou',
    marketingURL: 'stickeryou',
    title: 'Sticker You',
    logo: StickerYouLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
  },
  {
    path: 'saltwire',
    marketingURL: 'saltwire',
    title: 'SaltWire Network',
    logo: SaltWireLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'westrock',
    marketingURL: 'westrock',
    title: 'WestRock',
    logo: WestrockLogo,
    logoClass: 'small-logo',
    frenchPage: true,
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'fnzcanada',
    marketingURL: 'fnzcanada',
    title: 'FNZ Canada Limited',
    logo: FNZLogo,
    logoClass: 'tiny-logo',
    trustBasedNoEF: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'CIS',
    marketingURL: 'CIS',
    title: 'Corporate Investigation Services LTD.',
    logo: CISLogo,
    logoClass: 'small-logo',
    EAP: true,
    domainGated: true,
    email_domain: ['corporateinvestigations.com', 'inkblottherapy.com'],
    dependants: true,
  },
  {
    path: 'AgaKhanInstitutionsCanada',
    marketingURL: 'AgaKhanInstitutionsCanada',
    title: 'Aga Khan Institutions – Canada',
    fullyGated: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'apartmentlove',
    marketingURL: 'apartmentlove',
    title: 'ApartmentLove',
    logo: ApartmentLoveLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    EAP: true,
    dependants: true,
  },
  {
    path: 'LVMH',
    marketingURL: 'LVMH',
    title: 'LVMH Moët Hennessy Louis Vuitton Inc.',
    logo: LVMHLogo,
    logoClass: 'small-logo',
    trustBasedWithEF: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'stpaulbus',
    marketingURL: 'stpaulbus',
    title: 'St. Paul Education Division',
    logo: StPaulLogo,
    logoClass: 'small-logo',
    trustBasedNoEF: true,
    EAP: true,
    dependants: true,
  },
  {
    path: 'phsd',
    marketingURL: 'phsd',
    title: 'Pembina School division',
    logo: PHSDLogo,
    logoClass: 'small-logo',
    trustBasedNoEF: true,
    EAP: true,
    dependants: true,
  },
  {
    path: 'mavencollectivemarketing',
    marketingURL: 'mavencollectivemarketing',
    title: 'Maven Collective Marketing',
    logo: MavenLogo,
    logoClass: 'tiny-logo',
    fullyGated: true,
    EAP: true,
    dependants: true,
  },
  {
    path: 'lululemon',
    marketingURL: 'lululemon',
    title: 'lululemon',
    logo: LululemonLogo,
    logoClass: 'wide-logo',
    employeeIdSignup: true,
    fullyGated: true,
    EAP: true,
    frenchPage: true,
    dependants: true,
  },
  {
    path: 'hallmarkcanada',
    marketingURL: 'hallmarkcanada',
    title: 'Hallmark Canada',
    logo: HallmarkLogo,
    logoClass: 'tiny-logo',
    EAP: true,
    domainGated: true,
    frenchPage: true,
    dependants: true,
    email_domain: ['hallmark.com', 'inkblottherapy.com'],
  },
  {
    path: 'lwolf',
    marketingURL: 'lwolf',
    title: 'Lone Wolf Technologies',
    logo: LoneWolfLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'yourcreditunion',
    marketingURL: 'yourcreditunion',
    title: 'Your Credit Union',
    logo: YCULogo,
    logoClass: 'small-logo',
    dependants: true,
    EAP: true,
    frenchPage: true,
  },
  {
    path: 'mmgltd',
    marketingURL: 'mmgltd',
    title: 'Mayfield Management Group Ltd.',
    logo: MMGLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    path: 'pas',
    marketingURL: 'pas',
    title: 'Pharmacy Association of Saskatchewan',
    logo: PASLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    path: 'crayolacanada',
    marketingURL: 'crayolacanada',
    title: 'Crayola Canada',
    logo: CrayolaLogo,
    logoClass: 'small-logo',
    trustBasedWithEF: true,
    dependants: true,
    frenchPage: true,
    EAP: true,
  },
  {
    path: 'ericsson',
    marketingURL: 'ericsson',
    title: 'Ericsson Canada Inc.',
    logo: EricssonLogo,
    logoClass: 'small-logo',
    trustBasedNoEF: true,
    dependants: true,
    frenchPage: true,
    EFAP: true,
  },
  {
    path: 'worldsource',
    marketingURL: 'worldsource',
    title: 'Worldsource Wealth Management',
    logo: WorldsourceLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    frenchPage: true,
    fullyGated: true,
  },
  {
    path: 'thinkwell',
    marketingURL: 'thinkwell',
    title: 'Thinkwell Shift Inc.',
    logo: ThinkwellLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'arbutusproperties',
    marketingURL: 'arbutusproperties',
    title: 'Arbutus Properties',
    logo: ArbutusLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'exchangesolutions',
    marketingURL: 'exchangesolutions',
    title: 'Exchange Solutions',
    logo: ExchangeSolutionsLogo,
    logoClass: 'wide-logo',
    EAP: true,
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'purelogicit',
    marketingURL: 'purelogicit',
    title: 'PureLogic IT Solutions',
    logo: PureLogicLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    frenchPage: true,
    fullyGated: true,
  },
  {
    path: 'palliser',
    marketingURL: 'palliser',
    title: 'Palliser School',
    logo: PalliserLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'axistherapy',
    marketingURL: 'axistherapy',
    title: 'Axis Therapy & Performance',
    logo: AxisLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'countyofstpaul',
    marketingURL: 'countyofstpaul',
    title: 'County of St. Paul No. 19',
    logo: CountyLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'eureka',
    marketingURL: 'eureka',
    title: 'La Maison Euréka',
    logo: EurekaLogo,
    logoClass: 'small-logo',
    EAP: true,
    frenchPage: true,
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'sparkscience',
    marketingURL: 'sparkscience',
    title: 'TELUS Spark Science Centre',
    logo: TelusSparkLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'pcb',
    marketingURL: 'pcb',
    title: 'PCB Customs Brokers',
    logo: PCBLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'nureva',
    marketingURL: 'nureva',
    title: 'Nureva Inc.',
    logo: NurevaLogo,
    logoClass: 'tiny-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'nks',
    marketingURL: 'nks',
    title: 'NKS',
    logo: NKSLogo,
    logoClass: 'small-logo',
    fullyGated: true,
  },
  {
    path: 'cima',
    marketingURL: 'cima',
    title: 'Canadian Independent Music Association (CIMA)',
    logo: CIMALogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    frenchPage: true,
    dependants: true,
    customLabelForSignUpInputField: 'eap.cimaSignUp',
  },
  {
    path: 'abr',
    marketingURL: 'abr',
    title:
      'l’Association de bienfaisance et de retraite des policiers et policières de la Ville de Montréal',
    logo: ABRLogo,
    logoClass: 'small-logo',
    frenchOnly: true,
    fullyGated: true,
    dependants: true,
    customLabelForSignUpInputField: 'eap.abrCustomSignUp',
  },
  {
    path: 'fmpsd',
    marketingURL: 'fmpsd',
    title: 'Fort McMurray School',
    logo: FMPSDLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedNoEF: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'terrapex',
    marketingURL: 'terrapex',
    title: 'Terrapex Environmental',
    logo: TerrapexLogo,
    logoClass: 'small-logo',
    EAP: true,
    domainGated: true,
    email_domain: ['inkblottherapy.com', 'terrapex.com'],
    frenchPage: true,
    dependants: true,
  },
  {
    path: 'foodwaterwellnessfoundation',
    marketingURL: 'foodwaterwellnessfoundation',
    title: 'Food Water Wellness Foundation',
    logo: FoodWaterLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'brfn',
    marketingURL: 'brfn',
    title: 'Blueberry River',
    logo: BlueberryLogo,
    logoClass: 'small-logo',
    trustBasedNoEF: true,
  },
  {
    path: 'aptos',
    marketingURL: 'aptos',
    title: 'Aptos',
    logo: AptosLogo,
    logoClass: 'tiny-logo',
    trustBasedNoEF: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'admare',
    marketingURL: 'admare',
    title: 'adMare BioInnovations',
    logo: AdmareLogo,
    logoClass: 'tiny-logo',
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'titan',
    marketingURL: 'titan',
    title: 'Titan Security & Investigation Inc.',
    logo: TitanLogo,
    logoClass: 'small-logo',
    trustBasedWithEF: true,
    dependants: true,
    EAP: true,
  },
  {
    path: 'sperlingind',
    marketingURL: 'sperlingind',
    title: 'Sperling Industries Ltd',
    logo: SperlingLogo,
    logoClass: 'small-logo',
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'maraconsulting',
    marketingURL: 'maraconsulting',
    title: 'Mara Consulting',
    logo: MaraLogo,
    logoClass: 'tiny-logo',
    domainGated: true,
    email_domain: ['www.maraconsulting.ca'],
    EAP: true,
    dependants: true,
  },
  {
    path: 'haldimandfht',
    marketingURL: 'haldimandfht',
    title: 'Haldimand Family Health Team',
    logo: HaldimandLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    path: 'activisionblizzard',
    marketingURL: 'activisionblizzard',
    title: ' Activision Blizzard',
    logo: ActivisionBlizzardLogo,
    logoClass: 'tiny-logo',
    trustBasedNoEF: true,
    dependants: true,
    frenchPage: true,
  },
  {
    path: 'inconfidence',
    marketingURL: 'inconfidence',
    marketingURLFr: 'enequilibre',
    title: 'inConfidence',
    logo: InconfidenceEnLogo,
    frLogo: InconfidenceFrLogo,
    logoClass: 'small-logo',
    trustBasedNoEF: true,
    singleParagraph: (
      <div>
        <p>
          Medavie Blue Cross has partnered with Inkblot to provide you with easy
          to access support for your life, work and mental health challenges –
          any time – any place.
        </p>
        <p>
          Included in your EFAP is individual and couples counselling, health
          coaching, life coaching, career coaching, financial and legal
          consultations. Once you’ve used all of your available EFAP hours, you
          and your eligible dependents have the ability to continue with the
          same counsellor for longer-term counselling needs, with costs
          reimbursable under your benefits plan, through a health care spending
          account (if applicable), or paid privately.
        </p>
      </div>
    ),
    singleParagraphFr: (
      <div>
        <p>
          Croix Bleue Medavie s’est associée à Inkblot pour vous offrir un
          soutien facile d’accès pour vos de vie, de travail et de santé mentale
          - à tout moment et en tout lieu.{' '}
        </p>
        <p>
          Votre PAEF comprend des services de counseling individuel et de
          couple, de coaching de santé, de coaching de vie, de coaching de
          carrière, de consultations financières et juridiques. Lorsque vous
          aurez utilisé toutes les heures offertes par votre PAEF, vous et vos
          personnes à charge admissibles pourrez entreprendre un counseling à
          long terme avec le même conseiller ou la même conseillère, et les
          coûts du counseling vous seront remboursables en vertu de votre régime
          d'avantages sociaux, d'un compte de gestion des dépenses santé (le cas
          échéant), ou payés à titre privé.
        </p>
      </div>
    ),
    EAP: true,
    dependants: true,
    frenchPage: true,
    customLabelForSignUpInputField: 'eap.inconfidenceSignUpField',
    registrationFlow: 'redesign_2023',
  },
  {
    path: 'inconfidencepersonal',
    marketingURL: 'inconfidencepersonal',
    marketingURLFr: 'enequilibrepersonnel',
    title: 'inConfidence',
    logo: InconfidenceEnLogo,
    frLogo: InconfidenceFrLogo,
    logoClass: 'small-logo',
    singleParagraph: (
      <div>
        <p>
          Medavie Blue Cross has partnered with Inkblot to provide you with easy
          to access support for your life, work and mental health challenges –
          any time – any place.
        </p>
        <p>
          Included in your EFAP is individual and couples counselling, health
          coaching, life coaching, career coaching, financial and legal
          consultations. Once you’ve used all of your available EFAP hours, you
          and your eligible dependents have the ability to continue with the
          same counsellor for longer-term counselling needs, with costs
          reimbursable under your benefits plan, through a health care spending
          account (if applicable), or paid privately.
        </p>
      </div>
    ),
    singleParagraphFr: (
      <div>
        <p>
          Croix Bleue Medavie s’est associée à Inkblot pour vous offrir un
          soutien facile d’accès pour vos de vie, de travail et de santé mentale
          - à tout moment et en tout lieu.{' '}
        </p>
        <p>
          Votre PAEF comprend des services de counseling individuel et de
          couple, de coaching de santé, de coaching de vie, de coaching de
          carrière, de consultations financières et juridiques. Lorsque vous
          aurez utilisé toutes les heures offertes par votre PAEF, vous et vos
          personnes à charge admissibles pourrez entreprendre un counseling à
          long terme avec le même conseiller ou la même conseillère, et les
          coûts du counseling vous seront remboursables en vertu de votre régime
          d'avantages sociaux, d'un compte de gestion des dépenses santé (le cas
          échéant), ou payés à titre privé.
        </p>
      </div>
    ),
    trustBasedNoEF: true,
    EAP: true,
    dependants: true,
    frenchPage: true,
    customLabelForSignUpInputField: 'eap.inconfidenceSignUpField',
    registrationFlow: 'redesign_2023',
  },
  {
    path: 'advicahealth',
    marketingURL: 'advicahealth',
    title: 'Advica Health',
    logo: AdvicaLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    frenchPage: true,
    dependants: true,
    removeSecondParagraph: true,
    subsequentSessions:
      'Your initial individual consultation is complimentary, plus any additional counseling hours sponsored by Advica Health. <br/><br/>Subsequent sessions cost just $90/hour which may be covered by your private health insurance, benefits coverage or paid privately.',
    registrationFlow: 'redesign_2023',
  },
  {
    path: 'goelks',
    marketingURL: 'goelks',
    title: 'Edmonton Elks',
    logo: EdmontonElksLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    path: 'wallcentre',
    marketingURL: 'wallcentre',
    title: 'Wall Centre Hotels',
    logo: WallLogo,
    logoClass: 'wide-logo',
    trustBasedWithEF: true,
    dependants: true,
    EAP: true,
  },
  {
    path: 'reachedmonton',
    marketingURL: 'reachedmonton',
    title: 'REACH Edmonton Council',
    logo: ReachLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    EAP: true,
    dependants: true,
  },
  {
    path: 'royalbeef',
    marketingURL: 'royalbeef',
    title: 'Royal Beef',
    logo: RoyalBeefLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    path: 'mindsea',
    marketingURL: 'mindsea',
    title: 'Mindsea Development Inc.',
    logo: MindseaLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'fwhcpa',
    marketingURL: 'fwhcpa',
    title: 'FWH Chartered Professional Accountants',
    logo: FWHLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    EAP: true,
    dependants: true,
  },
  {
    path: 'bluenoserv',
    marketingURL: 'bluenoserv',
    title: 'Bluenose RV',
    logo: BluenoseLogo,
    logoClass: 'tiny-logo',
    EAP: true,
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'victaulic',
    marketingURL: 'victaulic',
    title: 'Victaulic',
    logo: VictaulicLogo,
    logoClass: 'wide-logo',
    EAP: true,
    trustBasedWithEF: true,
    dependants: true,
  },
  {
    path: 'naes',
    marketingURL: 'naes',
    title: 'NAES Canada Ltd',
    logo: NaesLogo,
    logoClass: 'small-logo',
    domainGated: 'true',
    email_domain: ['naes.com', 'inkblottherapy.com'],
    EAP: true,
    dependants: true,
  },
  {
    path: 'delta-q',
    marketingURL: 'delta-q',
    title: 'Delta-Q',
    logo: DeltaQLogo,
    logoClass: 'small-logo',
    EAP: true,
    domainGated: true,
    dependants: true,
    email_domain: ['delta-q.com', 'inkblottherapy.com'],
  },
  {
    path: 'acciona',
    marketingURL: 'acciona',
    title: 'Acciona Infrastructure Canada Inc',
    logo: AccionaLogo,
    logoClass: 'small-logo',
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'longshore',
    marketingURL: 'longshore',
    title: 'Longshore Resources',
    logo: LongshoreLogo,
    logoClass: 'tiny-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
    frenchPage: true,
  },
  {
    path: 'deveng',
    marketingURL: 'deveng',
    title: 'Development Engineering Ltd.',
    logo: DevengLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    path: 'northerndocksystems',
    marketingURL: 'northerndocksystems',
    title: 'Northern Dock Systems',
    logo: NDSLogo,
    logoClass: 'small-logo',
    EAP: true,
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'blumetric',
    marketingURL: 'blumetric',
    title: 'BluMetric Environmental Inc.',
    logo: BluMetricLogo,
    logoClass: 'small-logo',
    EAP: true,
    frenchPage: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'bouchier',
    marketingURL: 'bouchier',
    title: 'The Bouchier Group',
    logo: BouchierGroupLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'thrivedigital',
    marketingURL: 'thrivedigital',
    title: 'Thrive Digital',
    logo: ThriveLogo,
    logoClass: 'wide-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'essentialenergy',
    marketingURL: 'essentialenergy',
    title: 'Essential Energy Services',
    logo: EssentialLogo,
    logoClass: 'small-logo',
    frenchPage: true,
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'cpcs',
    marketingURL: 'cpcs',
    title: 'CPCS Transcom Ltd',
    logo: CPCSLogo,
    logoClass: 'wide-logo',
    frenchPage: true,
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    path: 'yyc',
    marketingURL: 'yyc',
    title: 'The Calgary Airport Authority',
    logo: YYCLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    path: 'engcomp',
    marketingURL: 'engcomp',
    title: 'Engcomp',
    logo: EngCompLogo,
    logoClass: 'small-logo',
    fullyGated: true,
    dependants: true,
    EAP: true,
  },
  {
    path: 'tractionrec',
    marketingURL: 'tractionrec',
    title: 'Traction Rec',
    logo: TractionRecLogo,
    logoClass: 'wide-logo',
    fullyGated: true,
    dependants: true,
    frenchPage: true,
    EAP: true,
  },
  {
    path: 'modernniagara',
    marketingURL: 'modernniagara',
    title: 'Modern Niagara Group Inc',
    logo: ModernNiagaraLogo,
    logoClass: 'tiny-logo',
    frenchPage: true,
    trustBasedNoEF: true,
    dependants: true,
    EAP: true,
  },
  {
    path: 'leicageosystems',
    marketingURL: 'leicageosystems',
    title: 'Leica Geosystems Ltd.',
    logo: LeicaLogo,
    logoClass: 'tiny-logo',
    frenchPage: true,
    trustBasedNoEF: true,
    dependants: true,
  },
  {
    path: 'shaw',
    marketingURL: 'shaw',
    title: 'Shaw',
    logo: ShawLogo,
    logoClass: 'small-logo',
    frenchPage: true,
    dependants: true,
    trustBasedNoEF: true,
  },
  {
    path: 'fppmemployes',
    marketingURL: 'fppmemployes',
    title: 'Fraternité des policiers et policières de Montréal (pour employés)',
    logo: FppmEmployeeLogo,
    logoClass: 'small-logo',
    frenchPage: true,
    trustBasedNoEF: true,
    subsequentSessions:
      "Each eligible employee will have access to Inkblot's counseling services via video or telephone, including a free initial 15 minute consultation to ensure compatibility with the selected clinician and an unlimited number of one-on-one sessions.",
    subsequentSessionsFr:
      'Chaque employé admissibles auront accès aux services de counseling d’Inkblot par vidéo ou par téléphone, incluant une première consultation individuelle gratuite (15 minutes) pour assurer la compatibilité avec le clinicien sélectionné et par la suite un nombres illimités de sessions individuelle.',
  },
  {
    path: 'suttonplanning',
    marketingURL: 'suttonplanning',
    title: 'Sutton Financial Group',
    logo: SuttonLogo,
    logoClass: 'small-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'purposemed',
    marketingURL: 'purposemed',
    title: 'PurposeMed Inc.',
    logo: PurposeMedLogo,
    logoClass: 'wide-logo',
    EAP: true,
    fullyGated: true,
    dependants: true,
  },
  {
    path: '3dstoragesystems',
    marketingURL: '3dstoragesystems',
    title: '3D Storage Systems Ltd.',
    logo: StorageSystemLogo,
    logoClass: 'small-logo',
    EAP: true,
    trustBasedWithEF: true,
    dependants: true,
  },
];

export default companies;
