import React, { Component } from 'react';
import axios from 'axios';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import PropTypes from 'prop-types';
import _ from 'lodash';
import queryString from 'query-string';

import { validEmail } from 'src/utils/form';
import Consent from './consent';
import { signUpData } from '../../pagePartials/auth';
import SpinnerButton from '../SpinnerButton';
import X from '../../images/dark-x.svg';
import UnitedStates from '../../images/united-states.png';
import Canada from '../../images/canada.png';
import {
  SERVER_URL,
  COUNTRY,
  OTHER_MARKETING_URL,
} from '../../utils/environment';
import { auditProgress } from '../../utils/pageUtils';
import { logEvent, COMPLETED_SIGNUP } from '../../utils/amplitude';
import { redirectToTherapy } from '../../utils/redirect';
import { LANGUAGE_KEY } from '../Navigation';
import trapFocus from '../../utils/trapFocus';
import EyeIcon from '../EyeIcon';
class SignUp extends Component {
  constructor(props) {
    super(props);

    const params = queryString.parse(window.location.search);

    this.state = {
      signUpDisabled: true,
      signUpError: null,
      error: {},
      recentlyUpdated: null,
      loading: false,
      errCountry: null,
      partnerInviteToken: params.partner_token || null,
      visiblePassword: {},
      privacyConsent: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.recentlyUpdated !== prevState.recentlyUpdated) {
      this.validateForm(this.state.recentlyUpdated);
    }
  }

  emailExists = email => {
    const locale = localStorage.getItem(LANGUAGE_KEY);

    axios
      .get(
        `${SERVER_URL}/api/signup/check_email?email=${encodeURIComponent(
          email,
        )}&locale=${encodeURIComponent(locale)}`,
      )
      .then(() => { })
      .catch(e => {
        const isFr = localStorage.getItem('selectedLanguage') === 'fr';
        this.setState({
          error: {
            ...this.state.error,
            email: isFr ? 'Ce courriel existe déjà' : e.response.data.error ,
          },
        });
      });
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: name === 'dob' ? this.normalizeDate(value) : value,
      recentlyUpdated: name,
    });
  };

  handleChangeRadio = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeCheckbox = event => {
    this.setState({
      privacyConsent: event.target.checked,
      recentlyUpdated: event.target.name,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.signUp();
  };

  signUp = () => {
    const { referralCode } = this.props;
    const { partnerInviteToken, privacyConsent } = this.state;

    const values = _.omit(this.state, [
      'signUpDisabled',
      'error',
      'signUpError',
      'recentlyUpdated',
      'other_referral_source',
      'errCountry',
      'partnerInviteToken',
      'privacyConsent'
    ]);

    values.locale = localStorage.getItem('selectedLanguage') || 'en';

    if (partnerInviteToken) {
      values.partner_invitation_token = partnerInviteToken;
      values.referral_source = 'Partner Invitation';
    }

    if (referralCode) {
      values.referral_source = referralCode;
    }
    if (values.referral_source === 'Other') {
      values.referral_source = this.state.other_referral_source;
    }

    let mergedValues = { ...values, source: 'therapy', consent_yn: privacyConsent };
    if (window.location.pathname.split('/')[1] === 'avail') {
      mergedValues = { ...values, source: 'therapy', school: 'avail' };
    }

    this.setState({ loading: true });
    axios
      .post(`${SERVER_URL}/api/signup`, mergedValues)
      .then(response => {
        const token = response.data.access_token;
        logEvent(COMPLETED_SIGNUP, {
          page: referralCode || '',
          source: partnerInviteToken ? 'partner invitation' : '',
        });
        auditProgress(referralCode || '', 'completed_signup', token);
        redirectToTherapy(token, {partner_registration: true});
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        this.setState({ signUpError: e.response.data.error });
      });
  };

  switchDrawers = () => {
    this.props.toggleSignUpDrawer();
    this.props.toggleSignInDrawer();
  };

  validateForm = recentlyUpdated => {
    const isFr = localStorage.getItem('selectedLanguage') === 'fr';
    const requiredField = isFr
      ? 'Ceci est un champ obligatoire'
      : 'This is a required field';
    const emailValid = isFr
      ? 'Veuillez saisir une adresse courriel - Exemple, utilisateur@exemple.com'
      : 'Please enter an email address - Example, user@example.com';
    const passwordLength = isFr
      ? 'Le mot de passe doit être au moins de 8 caractères '
      : 'Password must be at least 8 characters';
    const passwordMatch = isFr
      ? 'Les mots de passe doivent correspondre'
      : 'Passwords must match';
    const useUSA = isFr
      ? 'Veuillez utiliser le site Web des États-Unis.'
      : 'Click here to use the US website.';
    const useCanada = isFr
      ? 'Veuillez utiliser le site Web canadien.'
      : 'Click here to use the Canadian website.';

    const { referralCode } = this.props;
    const {
      first_name,
      last_name,
      email,
      password,
      password_confirm,
      referral_source,
      other_referral_source,
      country,
      partnerInviteToken,
      privacyConsent,
    } = this.state;
    const error = {};

    if (first_name === '') {
      error.first_name = requiredField;
    }
    if (last_name === '') {
      error.last_name = requiredField;
    }
    if (email === '') {
      error.email = requiredField;
    }
    if (password === '') {
      error.password = requiredField;
    }
    if (!referralCode && !partnerInviteToken && referral_source === '') {
      error.referral_source = requiredField;
    }
    if (!privacyConsent) {
      error.privacyConsent = requiredField;
    }

    if (!partnerInviteToken) {
      // validate Country select
      if (country && country !== COUNTRY) {
        error.country = COUNTRY === 'CA' ? useUSA : useCanada;
        this.setState({ errCountry: COUNTRY });
      } else {
        this.setState({ errCountry: null });
      }
    }

    if (email) {
      // to reduce memory usage, we will call check_email api only when email field updated
      if (validEmail.test(email)) {
        if (recentlyUpdated === 'email') {
          this.emailExists(email);
        }
      } else {
        error.email = emailValid;
      }
    }
    if (password) {
      if (password.length < 8) {
        error.password = passwordLength;
      } else if (password !== password_confirm) {
        error.password_confirm = passwordMatch;
      }
    }

    this.setState({
      recentlyUpdated: null,
      signUpDisabled:
        !_.isEmpty(error) ||
        !first_name ||
        !last_name ||
        !email ||
        !privacyConsent ||
        !password ||
        !password_confirm ||
        (!partnerInviteToken && !country) ||
        (!referralCode &&
          !partnerInviteToken &&
          (!referral_source ||
            (referral_source === 'Other' && !other_referral_source))),
      error,
    });
  };

  renderInputs = inputs => {
    const { error, visiblePassword } = this.state;
    return (
      <div className="input-row">
        {_.map(inputs, ({ id, name, defaultText, type, text, fullWidth }) => (
          <div
            key={id}
            className={
              fullWidth ? 'full-width-input input-container' : 'input-container'
            }
          >
            <label htmlFor={name}>
              <FormattedMessage id={text} />
            </label>
            {type === 'password' ? (
              <div className="password-input">
                <div className="text-input">
                  <input
                    id={name}
                    onChange={event => this.handleChange(event)}
                    type={visiblePassword[name] ? 'text' : 'password'}
                    name={name}
                    placeholder={defaultText}
                    value={this.state[name]}
                  />
                </div>
                <EyeIcon isVisible={visiblePassword[name]} onClick={() => {
                    const visible = { ...visiblePassword };
                    visible[name] = !visiblePassword[name];
                    this.setState({ visiblePassword: { ...visible } });
                  }}
                />
              </div>
            ) : (
              <input
                id={name}
                onChange={event => this.handleChange(event)}
                type={type}
                name={name}
                placeholder={defaultText}
                value={this.state[name]}
              />
            )}
            <p
              role="alert"
              className={`error-text ${error[name] ? '' : 'hidden'}`}
            >
              {error[name]}
            </p>
          </div>
        ))}
      </div>
    );
  };

  renderCountrySelect = () => {
    const { error, errCountry } = this.state;

    const countrySelection = error.country;

    return (
      <div className="input-container">
        <label htmlFor="country">
          <FormattedMessage id="auth.countrySelection" />
        </label>
        <select
          className="referral-dropdown"
          onChange={value => this.handleChange(value)}
          type="text"
          name="country"
          defaultValue=""
        >
          <option value="">
            {this.props.intl.formatMessage({
              id: 'auth.pleaseSelect',
              defaultMessage: 'Please select an option in this list.',
            })}
          </option>
          <option value="CA">
            {this.props.intl.formatMessage({
              id: 'auth.selectCanada',
              defaultMessage: 'Canada',
            })}
          </option>
          <option value="US">
            {this.props.intl.formatMessage({
              id: 'auth.selectUS',
              defaultMessage: 'United States',
            })}
          </option>
        </select>
        <p
          className={`error-text ${countrySelection ? '' : 'hidden'}`}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {errCountry && (
            <a
              href={`${OTHER_MARKETING_URL}/?country_selected=true&signup=true`}
            >
              {countrySelection}
              <img
                src={COUNTRY === 'CA' ? UnitedStates : Canada}
                alt="flag"
                style={{ width: 20, marginLeft: 7 }}
              />
            </a>
          )}
        </p>
      </div>
    );
  };

  renderReferralSelect = () => {
    const { referral_source, other_referral_source, error } = this.state;

    const isFr = localStorage.getItem('selectedLanguage') === 'fr';

    const requiredField = isFr
      ? 'Ceci est un champ obligatoire'
      : 'This is a required field';

    const referralSourceUndefined =
      error.referral_source ||
      (referral_source === 'Other' && !other_referral_source);

    return (
      <div className="input-container">
        <label htmlFor="referral_source">
          <FormattedMessage id="auth.howDidYouHear" />
        </label>
        <select
          className="referral-dropdown"
          required
          onChange={value => this.handleChange(value)}
          type="text"
          name="referral_source"
          defaultValue=""
        >
          <option value="">
            {this.props.intl.formatMessage({
              id: 'auth.pleaseSelect',
              defaultMessage: 'Please select an option in this list.',
            })}
          </option>
          <option value="Healthcare provider">
            {this.props.intl.formatMessage({
              id: 'auth.refHealthcare',
              defaultMessage: 'Healthcare provider',
            })}
          </option>
          <option value="Family/Friend">
            {this.props.intl.formatMessage({
              id: 'auth.refFamily',
              defaultMessage: 'Family/Friend',
            })}
          </option>
          <option value="Social Media">
            {this.props.intl.formatMessage({
              id: 'auth.refSocial',
              defaultMessage: 'Social Media',
            })}
          </option>
          <option value="Internet Search">
            {this.props.intl.formatMessage({
              id: 'auth.refInternet',
              defaultMessage: 'Internet Search',
            })}
          </option>
          <option value="Advertisement">
            {this.props.intl.formatMessage({
              id: 'auth.refAds',
              defaultMessage: 'Advertisement',
            })}
          </option>
          <option value="Television/Radio">
            {this.props.intl.formatMessage({
              id: 'auth.refTV',
              defaultMessage: 'Television/Radio',
            })}
          </option>
          <option value="YouTube/Podcast">
            {this.props.intl.formatMessage({
              id: 'auth.refYoutube',
              defaultMessage: 'YouTube/Podcast',
            })}
          </option>
          <option value="Article">
            {this.props.intl.formatMessage({
              id: 'auth.refArticle',
              defaultMessage: 'Article',
            })}
          </option>
          <option value="Other">
            {this.props.intl.formatMessage({
              id: 'auth.refOther',
              defaultMessage: 'Other',
            })}
          </option>
        </select>
        {referral_source === 'Other' && (
          <input
            style={{
              marginTop: '10px',
              color: '#212121',
              fontSize: '16px',
            }}
            onChange={value => this.handleChange(value)}
            type="text"
            name="other_referral_source"
            value={this.state[other_referral_source]}
          />
        )}
        <p className={`error-text ${referralSourceUndefined ? '' : 'hidden'}`}>
          {requiredField}
        </p>
      </div>
    );
  };

  renderConsent = () => {
    const { error } = this.state;
    return (
      <Consent error={error} handleChangeCheckbox={this.handleChangeCheckbox} />
    );
  };

  render() {
    const { signUpOpen, referralCode } = this.props;
    const {
      signUpError,
      signUpDisabled,
      loading,
      partnerInviteToken,
    } = this.state;

    const hasScrollbar =
      window.innerWidth > document.documentElement.clientWidth;

    // eslint-disable-next-line
    const authStyle = signUpOpen
      ? hasScrollbar
        ? { right: '-15px' }
        : { right: '0px' }
      : hasScrollbar
        ? { right: '-615px', boxShadow: 'none', display: 'none' }
        : { right: '-600px', boxShadow: 'none', display: 'none' };

    return (
      <div onKeyDown={trapFocus} role="presentation">
        <div className="auth" style={authStyle}>
          <div className="sign-up">
            <h1 className="title">
              <FormattedMessage id="auth.signUp" defaultMessage="Sign Up" />
            </h1>
            <h2 className="subtitle">
              <FormattedMessage
                id="auth.alreadyHaveAnAccount"
                defaultMessage="Already have an account?&nbsp;"
              />
              <button
                className="subtitle-link"
                onClick={() => this.switchDrawers()}
                role="submit"
              >
                <FormattedMessage id="auth.signIn" defaultMessage="Sign In" />
              </button>
            </h2>
            <p className="subtitle" tabindex="0">
              <FormattedMessage
                id="auth.employeeNotice"
                defaultMessage="If you are an employee, member or dependant of a company using Inkblot benefits, please register using your company sign-up page."
              />
            </p>
            <form
              className="form-container sign-up-form"
              onSubmit={this.handleSubmit}
            >
              {this.renderInputs(signUpData.slice(0, 2))}
              {this.renderInputs(signUpData.slice(3, 4))}
              {this.renderInputs(signUpData.slice(4, 6))}
              {!partnerInviteToken && this.renderCountrySelect()}
              {!referralCode &&
                !partnerInviteToken &&
                this.renderReferralSelect()}
              {this.renderConsent()}
              <div>
                <p className={`error-text ${signUpError ? '' : 'hidden'}`}>
                  {signUpError}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <SpinnerButton
                  label={
                    <FormattedMessage
                      id="auth.createMyAccount"
                      defaultMessage="CREATE MY ACCOUNT"
                    />
                  }
                  type="submit"
                  loading={loading}
                  disabled={signUpDisabled}
                  style={{ width: '240px' }}
                />
              </div>
            </form>
            <button
              className="close"
              onClick={this.props.toggleSignUpDrawer}
              role="button"
              tabIndex={0}
            >
              <img src={X} alt="close" />
            </button>
          </div>
        </div>
        <div
          className="overlay"
          onClick={this.props.toggleSignUpDrawer}
          role="presentation"
          style={
            signUpOpen
              ? { visibility: 'visible', opacity: '1', right: '600px' }
              : { visibility: 'hidden', opacity: '0', right: 0 }
          }
        />
      </div>
    );
  }
}

SignUp.propTypes = {
  intl: intlShape.object,
  referralCode: PropTypes.string,
  signUpOpen: PropTypes.bool.isRequired,
  toggleSignUpDrawer: PropTypes.func.isRequired,
  toggleSignInDrawer: PropTypes.func.isRequired,
};

SignUp.defaultProps = {
  referralCode: '',
  intl: {},
};

export default injectIntl(SignUp);
