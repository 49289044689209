import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import _ from 'lodash';
import X from '../../../images/gallivan/x.svg';
import { SERVER_URL, MARKETING_URL } from '../../../utils/environment';
import { redirectToTherapy } from '../../../utils/redirect';
import EyeIcon from '../../EyeIcon';


class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      visible: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.clearError, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.clearError, false);
  }

  clearError = () => {
    this.setState({ error: null });
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.signIn();
  };

  pushOver = () => {
    const { signInOpen } = this.props;
    const amount = window.innerWidth < 400 ? window.innerWidth : 400;

    if (signInOpen) {
      document.getElementById('___gatsby').style.right = `${amount}px`;
    } else {
      document.getElementById('___gatsby').style.right = '0px';
    }
  };

  signIn = () => {
    const values = _.omit(this.state, ['error']);
    axios
      .post(`${SERVER_URL}/api/login`, { ...values, source: 'therapy' })
      .then(response => {
        const token = response.data.access_token;
        redirectToTherapy(token);
      })
      .catch(e => {
        this.setState({ error: e.response.data.error });
      });
  };

  switchDrawers = () => {
    this.props.toggleSignInDrawer();
    this.props.toggleSignUpDrawer();
  };

  render() {
    const { signInOpen } = this.props;
    const { error, visible } = this.state;
    const hasScrollbar =
      window.innerWidth > document.documentElement.clientWidth;
    // eslint-disable-next-line
    const authStyle = signInOpen
      ? hasScrollbar
        ? { right: '-15px' }
        : { right: '0px' }
      : hasScrollbar
        ? { right: '-415px', boxShadow: 'none' }
        : { right: '-400px', boxShadow: 'none' };

    return (
      <div>
        <div className="gallivan-auth" style={authStyle}>
          <div className="sign-in">
            <p className="title">Sign in</p>
            <p className="subtitle">
              Don&#39;t have an account?&nbsp;
              <span
                className="subtitle-link"
                onClick={() => this.switchDrawers()}
                role="presentation"
              >
                Sign up
              </span>
            </p>
            <form
              className="form-container sign-in-form"
              onSubmit={this.handleSubmit}
            >
              <div className="input-container">
                <p className="label">Email</p>
                <input
                  onChange={value => this.handleChange(value)}
                  type="text"
                  name="email"
                  placeholder="Your Email"
                />
              </div>
              <div className="input-container">
                <p className="label">Password</p>
                <div className="password-input">
                  <div className="text-input">
                    <input
                      onChange={value => this.handleChange(value)}
                      type={visible ? 'text' : 'password'}
                      name="password"
                      placeholder="Your Password"
                    />
                  </div>
                  <EyeIcon isVisible={visible} onClick={() => this.setState({ visible: !visible })} />
                </div>
                {error && (
                  <p
                    className="error-text"
                    aria-live='polite'
                    role='alert'
                  >
                    {error}
                  </p>
                )}
              </div>
              <button
                className="center sign-in-button gallivan-button-green"
                type="submit"
              >
                <p className="">SIGN IN</p>
              </button>
            </form>
            <div className="gasi-options">
              <p className="gasio-forget-pw">
                <a href={`${MARKETING_URL}/forgot_password`}>
                  <span className="gasi-link">Forgot my password</span>
                </a>
              </p>
            </div>
            <div
              className="close"
              onClick={this.props.toggleSignInDrawer}
              role="presentation"
            >
              <img src={X} alt="close" />
            </div>
          </div>
        </div>
        <div
          className="overlay"
          onClick={this.props.toggleSignInDrawer}
          role="presentation"
          style={
            signInOpen
              ? { visibility: 'visible', opacity: '1', right: '400px' }
              : { visibility: 'hidden', opacity: '0', right: 0 }
          }
        />
      </div>
    );
  }
}

SignIn.propTypes = {
  signInOpen: PropTypes.bool.isRequired,
  toggleSignInDrawer: PropTypes.func.isRequired,
  toggleSignUpDrawer: PropTypes.func.isRequired,
};

export default SignIn;
