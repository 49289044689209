import React, { Component } from 'react';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { validEmail } from 'src/utils/form';
import Consent from '../auth/consent';
import { signUpData } from '../../pagePartials/auth';
import DataLoadingSpinner from '../DataLoadingSpinner';
import X from '../../images/dark-x.svg';
import { SERVER_URL } from '../../utils/environment';
import { redirectToTherapy } from '../../utils/redirect';
import { auditProgress } from '../../utils/pageUtils';
import { logEvent, COMPLETED_SIGNUP } from '../../utils/amplitude';
import EyeIcon from '../EyeIcon';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpDisabled: true,
      signUpError: null,
      error: {},
      recentlyUpdated: null,
      loading: false,
      errCountry: null,
      companies: [],
      visiblePassword: {},
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.recentlyUpdated !== prevState.recentlyUpdated) {
      this.validateForm(this.state.recentlyUpdated);
    }
  }

  emailExists = email => {
    axios
      .get(`${SERVER_URL}/api/signup/check_email?email=${email}`)
      .then(() => { })
      .catch(e => {
        const isFr = localStorage.getItem('selectedLanguage') === 'fr';
        this.setState({
          error: {
            ...this.state.error,
            email: isFr ? 'Ce courriel existe déjà' : e.response.data.error ,
          },
        });
      });
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      recentlyUpdated: name,
    });
  };

  handleChangeRadio = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeSelect = selectedOption => {
    this.setState({
      company_name: selectedOption.label,
      recentlyUpdated: 'company_name',
    });
  };

  handleChangeCheckbox = event => {
    this.setState({
      privacyConsent: event.target.checked,
      recentlyUpdated: event.target.name,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.signUp();
  };

  signUp = () => {
    const values = _.omit(this.state, [
      'signUpDisabled',
      'error',
      'signUpError',
      'recentlyUpdated',
      'other_referral_source',
      'errCountry',
      'loading',
      'companies',
      'privacyConsent',
    ]);

    const mergedValues = {
      ...values,
      source: 'therapy',
      corporate_account: 'omers',
      consent_yn: this.state.privacyConsent
    };

    axios
      .post(`${SERVER_URL}/api/signup`, mergedValues)
      .then(response => {
        const token = response.data.access_token;
        this.setState({ loading: true });
        logEvent(COMPLETED_SIGNUP, { page: '' });
        auditProgress('', 'completed_signup', SERVER_URL, token, () =>
          redirectToTherapy(token),
        );
      })
      .catch(e => {
        this.setState({ signUpError: e.response.data.error });
      });
  };

  switchDrawers = () => {
    this.props.toggleSignUpDrawer();
    this.props.toggleSignInDrawer();
  };

  validateForm = recentlyUpdated => {
    const isFr = localStorage.getItem('selectedLanguage') === 'fr';
    const requiredField = isFr
      ? 'Ceci est un champ obligatoire'
      : 'This is a required field';
    const emailValid = isFr
      ? "Veuillez saisir une adresse courriel - Exemple, utilisateur@exemple.com"
      : 'Please enter an email address - Example, user@example.com';
    const passwordSix = isFr
      ? 'Le mot de passe doit être au moins de 8 caractères '
      : 'Password must be at least 8 characters';
    const passwordMatch = isFr
      ? 'Les mots de passe doivent correspondre'
      : 'Passwords must match';
    const {
      first_name,
      last_name,
      email,
      privacyConsent,
      password,
      password_confirm,
      status,
    } = this.state;
    const error = {};

    if (first_name === '') {
      error.first_name = requiredField;
    }
    if (last_name === '') {
      error.last_name = requiredField;
    }
    if (email === '') {
      error.email = requiredField;
    }
    if (password === '') {
      error.password = requiredField;
    }
    if (status === '') {
      error.status = requiredField;
    }
    if (!privacyConsent) {
      error.privacyConsent = requiredField;
    }

    if (email) {
      if (validEmail.test(email)) {
        // to reduce memory usage, we will call check_email api only when email field updated
        if (recentlyUpdated === 'email') {
          this.emailExists(email);
        }
      } else {
        error.email = emailValid;
      }
    }
    if (password) {
      if (password.length < 8) {
        error.password = passwordSix;
      } else if (password !== password_confirm) {
        error.password_confirm = passwordMatch;
      }
    }
    this.setState({
      recentlyUpdated: null,
      signUpDisabled:
        !_.isEmpty(error) ||
        !first_name ||
        !last_name ||
        !privacyConsent ||
        !email ||
        !password ||
        !password_confirm ||
        !status,
      error,
    });
  };

  renderInputs = inputs => {
    const { error, visiblePassword } = this.state;
    return (
      <div className="input-row">
        {_.map(inputs, ({
          id, name, defaultText, type, text, fullWidth,
        }) => (
          <div key={id} className={fullWidth ? 'full-width-input input-container' : 'input-container'}>
            <p className="label">
              <FormattedMessage id={text} />
            </p>
            {type === 'password' ? (
              <div className="password-input">
                <div className="text-input">
                  <input
                    id={name}
                    onChange={event => this.handleChange(event)}
                    type={visiblePassword[name] ? 'text' : 'password'}
                    name={name}
                    placeholder={defaultText}
                    value={this.state[name]}
                  />
                </div>
                <EyeIcon isVisible={visiblePassword[name]} onClick={() => {
                  const visible = { ...visiblePassword };
                  visible[name] = !visiblePassword[name];
                  this.setState({ visiblePassword: { ...visible } });
                }} />
              </div>
            ) : (
              <input
                id={name}
                onChange={event => this.handleChange(event)}
                type={type}
                name={name}
                placeholder={defaultText}
                value={this.state[name]}
              />
            )}
            <p className={`error-text ${error[name] ? '' : 'hidden'}`}>
              {error[name]}
            </p>
          </div>
        ))}
      </div>
    );
  };

  renderCompanySignup = () => {
    const {
      error: { status },
    } = this.state;
    return (
      <div className="league-signup">
        <div className="input-row">
          <div className="input-container">
            <p className="label">
              <FormattedMessage
                id="league.organization"
                defaultMessage="Organization (Optional)"
              />
            </p>
            <input
              onChange={event => this.handleChange(event)}
              type="text"
              name="referral_source"
              placeholder="Organization"
              value={this.state.referral_source}
            />
          </div>
          <div className="input-container">
            <p className="label">
              <FormattedMessage id="league.status" defaultMessage="Status" />
            </p>
            <select
              className="league-dropdown"
              onChange={value => this.handleChange(value)}
              type="text"
              name="status"
              defaultValue=""
            >
              <option value="">Select an option</option>
              <option value="1">Member</option>
              <option value="3">Spouse/Partner</option>
              <option value="4">Dependent</option>
            </select>
            <p className={`error-text ${status ? '' : 'hidden'}`}>
              This is a required field
            </p>
          </div>
        </div>
      </div>
    );
  };

  renderConsent = () => {
    const { error } = this.state;
    return (
      <Consent error={error} handleChangeCheckbox={this.handleChangeCheckbox} />
    );
  };

  render() {
    const { signUpOpen } = this.props;
    const { signUpError, signUpDisabled, loading } = this.state;

    const hasScrollbar =
      window.innerWidth > document.documentElement.clientWidth;

    // eslint-disable-next-line
    const authStyle = signUpOpen
      ? hasScrollbar
        ? { right: '-15px' }
        : { right: '0px' }
      : hasScrollbar
      ? { right: '-615px', boxShadow: 'none' }
      : { right: '-600px', boxShadow: 'none' };

    const signupButtonStyles = signUpDisabled
      ? 'center sign-up-button pink-gradient-button disabled-button'
      : 'center sign-up-button pink-gradient-button';

    const loaderStyling = loading
      ? { visibility: 'visible', marginBottom: '35px' }
      : { visibility: 'hidden' };
    return (
      <div>
        <div className="auth" style={authStyle}>
          <div className="sign-up">
            <h1 className="title">
              <FormattedMessage id="auth.signUp" defaultMessage="Sign Up" />
            </h1>
            <h2 className="subtitle">
              <FormattedMessage
                id="auth.alreadyHaveAnAccount"
                defaultMessage="Already have an account?&nbsp;"
              />
              <span
                className="subtitle-link"
                onClick={() => this.switchDrawers()}
                role="presentation"
              >
                <FormattedMessage id="auth.signIn" defaultMessage="Sign In" />
              </span>
            </h2>
            <form
              className="form-container sign-up-form"
              onSubmit={this.handleSubmit}
            >
              {this.renderInputs(signUpData.slice(0, 2))}
              {this.renderInputs(signUpData.slice(3, 4))}
              {this.renderInputs(signUpData.slice(4, 6))}
              {this.renderCompanySignup()}
              {this.renderConsent()}
              <div>
                <p className={`error-text ${signUpError ? '' : 'hidden'}`}>
                  {signUpError}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <button
                  className={signupButtonStyles}
                  type="submit"
                  disabled={signUpDisabled}
                >
                  <FormattedMessage
                    id="auth.createMyAccount"
                    defaultMessage="CREATE MY ACCOUNT"
                  />
                </button>
                <div style={loaderStyling}>
                  <DataLoadingSpinner />
                </div>
              </div>
            </form>
            <div
              className="close"
              onClick={this.props.toggleSignUpDrawer}
              role="presentation"
            >
              <img src={X} alt="close" />
            </div>
          </div>
        </div>
        <div
          className="overlay"
          onClick={this.props.toggleSignUpDrawer}
          role="presentation"
          style={
            signUpOpen
              ? { visibility: 'visible', opacity: '1', right: '600px' }
              : { visibility: 'hidden', opacity: '0', right: 0 }
          }
        />
      </div>
    );
  }
}

SignUp.propTypes = {
  signUpOpen: PropTypes.bool.isRequired,
  toggleSignUpDrawer: PropTypes.func.isRequired,
  toggleSignInDrawer: PropTypes.func.isRequired,
};

SignUp.defaultProps = {};

export default SignUp;
