import React, { Component } from 'react';
import { Link } from 'gatsby';
import myWellnessLogo from '../../images/gallivan/_myWellnessLogo.png';
import SignUp from './auth/SignUp';
import SignIn from './auth/SignIn';
import { whichSchool, unauthAuditProgress } from '../../utils/pageUtils';
import { logEvent, OPEN_SIGNIN, OPEN_SIGNUP } from '../../utils/amplitude';
import Hamburger from '../Hamburger';

class GallivanNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigationShow: false,
      signInOpen: false,
      signUpOpen: false,
      isScrollDown: this.getScrollTop() > 60,
    };

    this.school = whichSchool();
  }

  componentWillMount() {
    window.addEventListener('scroll', () => {
      this.setState({ isScrollDown: this.getScrollTop() > 60 });
    });

    this.closeAuths();

    window.addEventListener('scroll', () => {
      this.setState({ isScrollDown: this.getScrollTop() > 60 });
    });

    const signUp = window.location.search === '?signup=true';
    const signIn = window.location.search === '?signin=true';

    if (signUp) {
      localStorage.setItem('signUp', 'open');
    }
    if (signIn) {
      localStorage.setItem('signIn', 'open');
    }

    localStorage.getItem('signIn');
    localStorage.getItem('signUp');
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.getScrollTop, false);
  }

  getScrollTop = () =>
    window.pageYOffset !== undefined
      ? window.pageYOffset
      : (document.documentElement || document.body.parentNode || document.body)
        .scrollTop;

  pushOver = element => {
    const { signInOpen, signUpOpen } = this.state;
    if (element === 'signInOpen') {
      const amount = window.innerWidth < 400 ? window.innerWidth : 400;
      if (!signInOpen) {
        document.getElementById('___gatsby').style.right = `${amount}px`;
      } else {
        document.getElementById('___gatsby').style.right = '0px';
      }
    } else if (element === 'signUpOpen') {
      const amount = window.innerWidth < 600 ? window.innerWidth : 600;
      if (!signUpOpen) {
        document.getElementById('___gatsby').style.right = `${amount}px`;
      } else {
        document.getElementById('___gatsby').style.right = '0px';
      }
    }
  };

  closeAuths = () => {
    localStorage.setItem('signIn', 'close');
    localStorage.setItem('signUp', 'close');
    document.getElementById('___gatsby').style.right = '0px';
    this.forceUpdate();
  };

  toggleAuths = (authOpen, authClose) => {
    localStorage.setItem(authOpen, 'open');
    localStorage.setItem(authClose, 'close');
    logEvent(authOpen === 'signIn' ? OPEN_SIGNIN : OPEN_SIGNUP, {
      page: this.school.key,
    });
    unauthAuditProgress(
      this.school.key,
      authOpen === 'signIn' ? 'open_signin' : 'open_signup',
    );
    this.pushOver(authOpen);
    this.forceUpdate();
  };

  toggleElements = element => {
    this.setState(prevState => ({ [element]: !prevState[element] }));
    this.pushOver(element);
  };

  closeAuths = () => {
    localStorage.setItem('signIn', 'close');
    localStorage.setItem('signUp', 'close');
    document.getElementById('___gatsby').style.right = '0px';
    this.forceUpdate();
  };

  renderLinks = school => (
    <div>
      {school.key !== 'greatplains' && (
        <li>
          <a
            title="Insurance Information"
            onClick={() => this.setState({ navigationShow: false })}
            href={school.insuranceInformationUrl}
          >
            Insurance Information
          </a>
        </li>
      )}
      <li>
        <a
          title="Mental Health Assessment"
          onClick={() => this.setState({ navigationShow: false })}
          href={school.mentalHealthAssessmentUrl}
        >
          Mental Health Assessment
        </a>
      </li>
      <li>
        <Link
          title="FAQ"
          onClick={() => this.setState({ navigationShow: false })}
          to={`/${school.key}/faq`}
        >
          FAQ
        </Link>
      </li>
      <li>
        <Link
          title="About"
          onClick={() => this.setState({ navigationShow: false })}
          to={`/${school.key}/about`}
        >
          About
        </Link>
      </li>
      <li>
        <Link
          title="Meditation"
          onClick={() => this.setState({ navigationShow: false })}
          to="/meditation"
        >
          Meditation
        </Link>
      </li>
      <li>
        <button
          title="Find a Counsellor"
          className="center gallivan-button-green"
          onClick={() => {
            this.toggleAuths('signUp', 'signIn');
            this.setState({ navigationShow: false });
          }}
        >
          <span>Find a Counsellor</span>
        </button>
      </li>
    </div>
  );

  render() {
    const { navigationShow } = this.state;
    const signIn = localStorage.getItem('signIn') === 'open';
    const signUp = localStorage.getItem('signUp') === 'open';

    return (
      <div>
        <div className="gallivan-navigation-bar-container" role="navigation">
          <div style={{ marginRight: '20px' }}>
            <Link
              className="gallivan-logo-container"
              to={`/${this.school.key}`}
            >
              <img
                className="navigation-my-wellness-logo"
                src={myWellnessLogo}
                alt="Logo"
              />
              <img
                style={this.school.logoClass === 'small-logo' ? {maxWidth: '150px'} : { maxWidth: '100px' }}
                src={this.school.logo}
                alt="Logo"
              />
            </Link>
          </div>
          <div>
            <ul className="navigation-bar-right">
              {this.renderLinks(this.school)}
            </ul>
          </div>
          <Hamburger onClick={() => this.toggleElements('navigationShow')} />
          <div
            style={navigationShow ? { display: 'block' } : { display: 'none' }}
            className="navigation-overlay-mobile"
          >
            <div
              role="presentation"
              onClick={() => this.toggleElements('navigationShow')}
            >
              <span className="navigation-mobile-close">X</span>
            </div>
            <ul className="navigation-overlay-mobile-content">
              {this.renderLinks(this.school)}
            </ul>
          </div>
          <SignIn
            signInOpen={signIn}
            toggleSignInDrawer={() => this.closeAuths()}
            toggleSignUpDrawer={() => this.toggleAuths('signUp', 'signIn')}
          />
          <SignUp
            signUpOpen={signUp === true}
            toggleSignInDrawer={() => this.toggleAuths('signIn', 'signUp')}
            toggleSignUpDrawer={() => this.closeAuths()}
          />
        </div>
      </div>
    );
  }
}

export default GallivanNavigation;
