import React from 'react';
import PropTypes from 'prop-types';
import Spinner from './spinner.svg';

function SpinnerButton(props) {
  const {
    label, loading, disabled, ...rest
  } = props;

  return (
    <button
      className={`spinner-button ${(disabled || loading) && 'spinner-button-disabled'} not-custom-auth`}
      disabled={disabled || loading}
      {...rest}
    >
      <span className="wrapper">
        {loading && (
          <span className="spinner">
            <img src={Spinner} alt="loading" />
          </span>
        )}
        <span className="label">{label}</span>
      </span>
    </button>
  );
}

SpinnerButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

SpinnerButton.defaultProps = {
  disabled: false,
};

export default SpinnerButton;
