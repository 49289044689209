export const cfsSchools = [
  { label: "Cape Breton University Students' Union" },
  { label: 'Dalhousie Association of Graduate Students' },
  { label: "Mount Saint Vincent University Students' Union" },
  { label: 'Holland College Student Union' },
  { label: 'University of Prince Edward Island Graduate Student Association' },
  { label: 'University of Prince Edward Island Student Union' },
  { label: "University of New Brunswick Graduate Students' Association" },
  { label: "Algoma University Students' Union" },
  { label: "Brock University Graduate Students' Association" },
  { label: "Carleton University Students' Association" },
  { label: 'ollège Boréal Association générale des étudiants et des étudiantes' },
  { label: 'Student Association of George Brown College' },
  { label: "lendon College Students' Union / L'association des étudiantes et étudiants du collège Glendon" },
  { label: 'University of Guelph Central Student Association' },
  { label: "University of Guelph Graduate Students' Association" },
  { label: 'Lakehead University Student Union' },
  { label: "Laurentian University Students' General Association" },
  { label: 'Nipissing University Student Union' },
  { label: 'Ontario College of Art and Design Student Union' },
  { label: "niversity of Ottawa Graduate Students' Association des étudiant(e)s diplômé(e)s de l'Université d'Ottawa" },
  { label: "Queen's University Society of Graduate and Professional Students" },
  { label: "Ryerson Students' Union" },
  { label: "Saint Paul University Students' Association" },
  { label: "University of Toronto Graduate Students' Union" },
  { label: "University of Toronto Students' Union" },
  { label: 'Trent Central Student Association' },
  { label: 'University of Western Ontario Society of Graduate Students' },
  { label: "Wilfrid Laurier University Graduate Students' Association" },
  { label: 'University of Windsor Graduate Student Society' },
  { label: 'University of Windsor Organization of Part-time University Students' },
  { label: "University of Windsor Students' Alliance" },
  { label: "York University Graduate Students' Association" },
];

export const nshnSchools = [
  { label: `Association Edutiant & Etudiante Fracophone de l"universite de Laurentianne` },
  { label: `St Anne's University` },
  { label: `University of Toronto Part time students` },
  { label: `Ryerson Part time students CESAR` },
  { label: `College of North Atlantic` },
  { label: `Grenfell Campus Students' Union` },
  { label: `Carelton University Graduate Student Association` },
  { label: `Laurentian University Graduate Student Association` },
  { label: `Memorial Univeristy Graduate Students' Union` },
  { label: `Herst University Students' Union` },
  { label: `Kings College Students' Union` },
  { label: `Marine Institute Students Union` },
  { label: `Memorial Univeristy of Newfoundland Students' Union` },
  { label: `Univeristy of Windsor Part time students` },
  { label: `Scarbourgh Campus Students' Unon` },
  { label: `Saint Boniface Univeristy` },
  { label: `Student Union of NSCAD` },
  { label: `University of Toronto Mississauga Student Union` },
  { label: `University of Winnipeg Student Association` },
  { label: `York Federation of students` },
  { label: `Ryerson Graduate Students Union` },
  { label: `Memorial university graduate students union ` },
];
