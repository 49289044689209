import React from 'react'
import PropTypes from 'prop-types';
import { getLocale, setLanguage } from '../../utils/locale'

const ButtonStyle = { fontWeight: 300, background: 'transparent', cursor: 'pointer' }
const OptionStyle = { background: 'transparent', fontWeight: 800, }

const LanguageButton = ({ language, style }) => (
  <button
    tabIndex="0"
    onClick={() => setLanguage(language)}
    style={{ ...ButtonStyle, ...style }}
  >
    {language.toUpperCase()}
  </button>
)

const LanguageSelector = ({ style }) => {
  return (
    <div className="en-fr" style={style}>
      {getLocale() === 'en' ? (
        <div class='lang-selector'>
          <span style={OptionStyle}>EN</span>
          <LanguageButton language='fr' style={{ marginLeft: '20px' }} />
        </div>
      ) : (
        <div class='lang-selector'>
          <LanguageButton language='en' />
          <span style={{ ...OptionStyle, marginLeft: '20px' }}>FR</span>
        </div>
      )}
    </div>
  )
}
LanguageSelector.prototype = {
  style: PropTypes.object
};

LanguageSelector.defaultProps = {
  style: {},
};
export default LanguageSelector