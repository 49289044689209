/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import CanadaFlag from '../../images/country-select-modal/canada-big-flag.svg';
import UsFlag from '../../images/country-select-modal/us-big-flag.svg';
import './style.scss';
import trapFocus from '../../utils/trapFocus';

function CountrySelectModal(props) {
  const { onSelect, onClose } = props;

  function handleClick(code) {
    onSelect(code);
  }

  return (
    <div className="cs-modal-overlay">
      <div className="cs-modal-wrapper" onClick={() => onClose()}>
        <div onKeyDown={trapFocus} role="presentation" className="cs-modal">
          <div className="cs-content">
            <div className="cs-sub-title">
              Please select the country you are in:
            </div>
            <div className="cs-button-group">
              <FlagButton
                flagImage={CanadaFlag}
                name="Canada"
                onClick={() => handleClick('CA')}
              />
              <FlagButton
                flagImage={UsFlag}
                name="United States of America"
                onClick={() => handleClick('US')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CountrySelectModal.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function FlagButton(props) {
  const { flagImage, name, onClick } = props;

  return (
    <button
      tabIndex={0}
      className="cs-flag-button"
      onClick={onClick}
      type="button"
    >
      <div className="cs-flag-image">
        <img src={flagImage} alt="country flag" />
      </div>
      <div className="cs-flag-name">
        <p>{name}</p>
      </div>
    </button>
  );
}

FlagButton.propTypes = {
  flagImage: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CountrySelectModal;
