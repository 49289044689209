import amplitude from 'amplitude-js/amplitude';

import { AMPLITUDE_API_KEY } from './environment';

export const initAmplitude = () =>
  amplitude.getInstance().init(AMPLITUDE_API_KEY);

export const setUserId = userId => amplitude.getInstance().setUserId(userId);

export const setUserProperties = userProperties =>
  amplitude.getInstance().setUserProperties(userProperties);

export const logEvent = (eventType, eventProperties) =>
  amplitude.getInstance().logEvent(eventType, eventProperties);

export const setUserProperty = (property, value) => {
  const identify = new amplitude.Identify().set(property, value);
  amplitude.getInstance().identify(identify);
};

export const OPEN_SIGNIN = 'open signin';
export const OPEN_SIGNUP = 'open signup';
export const COMPLETED_SIGNUP = 'complete signup';
export const COMPLETED_SIGNIN = 'complete signin';
export const OPEN_FORGOT_PASSWORD = 'open forgot password';
export const SENT_FORGOT_PASSWORD = 'send forgotten password';
export const START_ONBOARDING = 'start onboarding';
export const OPEN_CREATE_PASSWORD = 'open create password';
export const COMPLETE_CREATE_PASSWORD = 'complete create password';
