import React from 'react';
import Helmet from 'react-helmet';

const InjectComm100 = () => {
  return localStorage.getItem('selectedLanguage') === 'fr' ? (
    <>
      <div id="comm100-button-9762cbf9-47fa-4e8c-bd9a-5c002200f787"></div>
      <Helmet>
        <script type="text/javascript">
          {`var Comm100API=Comm100API||{};(function(t){function e(e){var a=document.createElement("script"),c=document.getElementsByTagName("script")[0];a.type="text/javascript",a.async=!0,a.src=e+t.site_id,c.parentNode.insertBefore(a,c)}t.chat_buttons=t.chat_buttons||[],t.chat_buttons.push({code_plan:"9762cbf9-47fa-4e8c-bd9a-5c002200f787",div_id:"comm100-button-9762cbf9-47fa-4e8c-bd9a-5c002200f787"}),t.site_id=80000136,t.main_code_plan="9762cbf9-47fa-4e8c-bd9a-5c002200f787",e("https://vue.comm100.com/livechat.ashx?siteId="),setTimeout(function(){t.loaded||e("https://standby.comm100vue.com/livechat.ashx?siteId=")},5e3)})(Comm100API||{})`}
        </script>
      </Helmet>
    </>
  ) : (
    <>
      <div id="comm100-button-e1743a9b-bb1c-41c5-b836-9503a672afa2"></div>
      <Helmet>
        <script type="text/javascript">
          {`var Comm100API=Comm100API||{};(function(t){function e(e){var a=document.createElement("script"),c=document.getElementsByTagName("script")[0];a.type="text/javascript",a.async=!0,a.src=e+t.site_id,c.parentNode.insertBefore(a,c)}t.chat_buttons=t.chat_buttons||[],t.chat_buttons.push({code_plan:"e1743a9b-bb1c-41c5-b836-9503a672afa2",div_id:"comm100-button-e1743a9b-bb1c-41c5-b836-9503a672afa2"}),t.site_id=80000136,t.main_code_plan="e1743a9b-bb1c-41c5-b836-9503a672afa2",e("https://vue.comm100.com/livechat.ashx?siteId="),setTimeout(function(){t.loaded||e("https://standby.comm100vue.com/livechat.ashx?siteId=")},5e3)})(Comm100API||{})`}
        </script>
      </Helmet>
    </>
  );
};

export default InjectComm100;
