import React from 'react';
import PropTypes from 'prop-types';

import { logEvent } from '../utils/amplitude';

class LogEventOnMount extends React.PureComponent {
  componentDidMount() {
    const { eventType, eventProperties } = this.props;

    logEvent(eventType, eventProperties);
  }

  render() {
    return <noscript />;
  }
}

LogEventOnMount.propTypes = {
  eventType: PropTypes.string.isRequired,
  eventProperties: PropTypes.object,
};

LogEventOnMount.defaultProps = {
  eventProperties: {},
};

export default LogEventOnMount;
