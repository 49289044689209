import queryString from 'query-string';
import { THERAPY_URL } from './environment';

export const redirectToTherapy = (token, additionalParams = null, therapyUrl = null) => {
  const params = {
    back_to: window.location.href,
    ...additionalParams,
  };

  const url = therapyUrl || THERAPY_URL;

  const inkblotUrl = `${url}/redirect/${token}?${queryString.stringify(params)}`;
  window.location.replace(inkblotUrl);
};

export const redirectTo = (path) => {
  window.location.replace(`${window.location.origin}${path}`)
}