import React, { Component } from 'react';
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import { validEmail } from 'src/utils/form';
import Consent from '../../auth/consent';
import { signUpData } from '../../../pagePartials/gallivan/signUpData';
import X from '../../../images/gallivan/x.svg';
import { whichSchool, auditProgress } from '../../../utils/pageUtils';
import { SERVER_URL } from '../../../utils/environment';
import { redirectToTherapy } from '../../../utils/redirect';
import { logEvent, COMPLETED_SIGNUP, OPEN_SIGNUP } from '../../../utils/amplitude';
import LogEventOnMount from '../../../components/LogEventOnMount';
import EyeIcon from '../../EyeIcon';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpDisabled: true,
      signUpError: null,
      error: {},
      recentlyUpdated: null,
      visiblePassword: {},
    };

    this.school = whichSchool();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.recentlyUpdated !== prevState.recentlyUpdated) {
      this.validateForm(this.state.recentlyUpdated);
    }
  }

  emailExists = email => {
    axios
      .get(`${SERVER_URL}/api/signup/check_email?email=${email}`)
      .then(() => { })
      .catch(e => {
        this.setState({
          error: {
            ...this.state.error,
            email: e.response.data.error,
          },
        });
      });
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: name === 'dob' ? this.normalizeDate(value) : value,
      recentlyUpdated: name,
    });
  };

  handleChangeCheckbox = event => {
    this.setState({
      privacyConsent: event.target.checked,
      recentlyUpdated: event.target.name,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.signUp();
  };

  signUp = () => {
    const { privacyConsent } = this.state;
    const values = _.omit(this.state, [
      'signUpDisabled',
      'error',
      'signUpError',
      'recentlyUpdated',
      'privacyConsent',
    ]);
    const mergedValues = {
      ...values,
      source: 'therapy',
      consent_yn: privacyConsent,
      corporate_account: this.school.key,
      gallivan_yn: true,
      school: this.school.value
    };

    axios
      .post(`${SERVER_URL}/api/signup`, mergedValues)
      .then(response => {
        const token = response.data.access_token;
        logEvent(COMPLETED_SIGNUP, {
          page: this.school.key,
          page_type: 'my wellness portal',
          flow_type: 'b2b registration',
        });
        auditProgress(this.school.key, 'completed_signup', token);
        redirectToTherapy(token);
      })
      .catch(e => {
        this.setState({ signUpError: e.response.data.error });
      });
  };

  switchDrawers = () => {
    this.props.toggleSignUpDrawer();
    this.props.toggleSignInDrawer();
  };

  validateForm = recentlyUpdated => {
    const {
      first_name,
      last_name,
      email,
      privacyConsent,
      password,
      password_confirm,
    } = this.state;
    const error = {};

    if (first_name === '') {
      error.first_name = 'This is a required field';
    }
    if (last_name === '') {
      error.last_name = 'This is a required field';
    }
    if (email === '') {
      error.email = 'This is a required field';
    }
    if (password === '') {
      error.password = 'This is a required field';
    }
    if (!privacyConsent) {
      error.privacyConsent = 'This is a required field';
    }
    if (email) {
      if (validEmail.test(email)) {
        // to reduce memory usage, we will call check_email api only when email field updated
        if (recentlyUpdated === 'email') {
          this.emailExists(email);
        }
      } else {
        error.email =
          'Please enter an email address - Example, user@example.com';
      }
    }
    if (password) {
      if (password.length < 8) {
        error.password = 'Password must be at least 8 characters';
      } else if (password !== password_confirm) {
        error.password_confirm = 'Passwords must match';
      }
    }

    this.setState({
      recentlyUpdated: null,
      error,
      signUpDisabled:
        !_.isEmpty(error) ||
        !first_name ||
        !last_name ||
        !email ||
        !privacyConsent ||
        !password ||
        !password_confirm,
    });
  };

  handleChangeRadio = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  renderConsent = () => {
    const { error } = this.state;
    return (
      <Consent error={error} handleChangeCheckbox={this.handleChangeCheckbox} />
    );
  };

  renderInputs = inputs => {
    const { error, visiblePassword } = this.state;
    return (
      <div className="input-row">
        {_.map(inputs, ({ id, name, defaultText, type, text, fullWidth }) => (
          <div
            key={id}
            className={
              fullWidth ? 'full-width-input input-container' : 'input-container'
            }
          >
            <p className="label">{text}</p>
            {type === 'password' ? (
              <div className="password-input">
                <div className="text-input">
                  <input
                    id={name}
                    onChange={event => this.handleChange(event)}
                    type={visiblePassword[name] ? 'text' : 'password'}
                    name={name}
                    placeholder={defaultText}
                    value={this.state[name]}
                  />
                </div>
                <EyeIcon isVisible={visiblePassword[name]} onClick={() => {
                  const visible = { ...visiblePassword };
                  visible[name] = !visiblePassword[name];
                  this.setState({ visiblePassword: { ...visible } });
                }} />
              </div>
            ) : (
              <input
                id={name}
                onChange={event => this.handleChange(event)}
                type={type}
                name={name}
                placeholder={defaultText}
                value={this.state[name]}
              />
            )}
            <p className={`error-text ${error[name] ? '' : 'hidden'}`}>
              {error[name]}
            </p>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { signUpOpen } = this.props;
    const { signUpError, signUpDisabled } = this.state;
    const hasScrollbar =
      window.innerWidth > document.documentElement.clientWidth;
    // eslint-disable-next-line
    const authStyle = signUpOpen
      ? hasScrollbar
        ? { right: '-15px' }
        : { right: '0px' }
      : hasScrollbar
      ? { right: '-615px', boxShadow: 'none' }
      : { right: '-600px', boxShadow: 'none' };

    return (
      <div>
        <MetaTags>
          <meta name="robots" content="noindex" />
        </MetaTags>
        <div className="gallivan-auth" style={authStyle}>
          <div className="sign-up">
            <h1 className="title">Sign Up</h1>
            <h2 className="subtitle">
              Already have an account?&nbsp;
              <span
                className="subtitle-link"
                onClick={() => this.switchDrawers()}
                role="presentation"
              >
                Sign in
              </span>
            </h2>
            <form
              className="form-container sign-up-form"
              onSubmit={this.handleSubmit}
            >
              {this.renderInputs(signUpData.slice(0, 2))}
              {this.renderInputs(signUpData.slice(3, 4))}
              {this.renderInputs(signUpData.slice(4, 6))}
              <div className="input-container">
                <p className="label">How did you hear about us?</p>
                <input
                  onChange={value => this.handleChange(value)}
                  type="text"
                  name="referral_source"
                  placeholder="Instagram, Pamphlet, Doctor, etc"
                />
                <p className={`error-text ${signUpError ? '' : 'hidden'}`}>
                  {signUpError}
                </p>
              </div>
              {this.renderConsent()}
              <button
                className={
                  signUpDisabled
                    ? 'center sign-up-button gallivan-button-green disabled-button'
                    : 'center sign-up-button gallivan-button-green'
                }
                type="submit"
                disabled={signUpDisabled}
              >
                <p className="">CREATE MY ACCOUNT</p>
              </button>
            </form>
            <div
              className="close"
              onClick={this.props.toggleSignUpDrawer}
              role="presentation"
            >
              <img src={X} alt="close" />
            </div>
          </div>
        </div>
        <div
          className="overlay"
          onClick={this.props.toggleSignUpDrawer}
          role="presentation"
          style={
            signUpOpen
              ? { visibility: 'visible', opacity: '1', right: '600px' }
              : { visibility: 'hidden', opacity: '0', right: 0 }
          }
        />
        {signUpOpen && (
          <LogEventOnMount
            eventType={OPEN_SIGNUP}
            eventProperties={{
              page: this.school.key,
              page_type: 'my wellness portal',
              flow_type: 'b2b registration',
            }}
          />
        )}
      </div>
    );
  }
}

SignUp.propTypes = {
  signUpOpen: PropTypes.bool.isRequired,
  toggleSignUpDrawer: PropTypes.func.isRequired,
  toggleSignInDrawer: PropTypes.func.isRequired,
};

export default SignUp;
