import React from 'react';
import PropTypes from 'prop-types';

import EyeOpenImg from '../../images/eye-open.svg';
import EyeHideImg from '../../images/eye-hide.svg';

function EyeIcon({ isVisible, onClick, ariaLabel="Show/hide" }) {
  return (
    // set type="button" to avoid auto-submit in form tag
    // https://stackoverflow.com/questions/2440043/button-in-the-form-cause-it-be-submitted-automatically
    
    <button className="eye-button" type="button" onClick={onClick} aria-label={ariaLabel} role="button">
      <img
        src={isVisible ? EyeHideImg : EyeOpenImg}
        aria-hidden="true"
        alt="eye icon"
      />
    </button>
  )
}

EyeIcon.propTypes = {
  onClick: PropTypes.func,
  isVisible: PropTypes.bool,
  ariaLabel: PropTypes.string
};

export default EyeIcon;
