export const signUpData = [
  {
    id: 1,
    text: 'First Name',
    defaultText: 'John',
    type: 'text',
    name: 'first_name',
  },
  {
    id: 2,
    text: 'Last Name',
    defaultText: 'Smith',
    type: 'text',
    name: 'last_name',
  },
  {
    id: 3,
    text: 'Date of Birth',
    defaultText: 'DD/MM/YYYY',
    type: 'text',
    name: 'dob',
  },
  {
    id: 4,
    text: 'Email',
    defaultText: 'example@email.com',
    type: 'email',
    name: 'email',
    fullWidth: true,
  },
  {
    id: 5,
    text: 'Password',
    defaultText: '**********',
    type: 'password',
    name: 'password',
  },
  {
    id: 6,
    text: 'Retype Password',
    defaultText: '**********',
    type: 'password',
    name: 'password_confirm',
  },
  {
    id: 7,
    text: 'Custom',
    defaultText: '',
    type: 'text',
    name: 'custom_gender',
  },
];

export const radioData = [
  {
    id: 1,
    label: 'Male',
    radioValue: 'male',
    defaultMessage: 'Male',
  },
  {
    id: 2,
    label: 'Female',
    radioValue: 'female',
    defaultMessage: 'Female',
  },
  {
    id: 3,
    label: 'Custom',
    radioValue: 'custom',
    defaultMessage: 'Custom',

  },
  {
    id: 4,
    label: 'Unspecified',
    radioValue: 'unspecified',
    defaultMessage: 'Prefer not to say',
  },

];

