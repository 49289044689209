/* eslint-disable react/no-did-mount-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import Bowser from 'bowser';

import inkblotLogoLight from '../../images/_inkblot-logo-light.png';
import inkblotLogoDark from '../../images/nav/inkblot-logo.svg';
import inkblotLogoMobile from '../../images/nav/inkblot-logo-mobile.svg';
import inkblotLogoIcon from '../../images/nav/inkblot-logo-icon.png';
import ilobLogo from '../../images/custom/logos/gsc-logo.png';
import ilobLogoFr from '../../images/custom/logos/gsc-logo-fr.png';
import SignUp from './SignUp';
import SignIn from '../auth/SignIn';
import close from '../../images/x.svg';
import { unauthAuditProgress } from '../../utils/pageUtils';
import { logEvent, OPEN_SIGNUP, OPEN_SIGNIN } from '../../utils/amplitude';
import { MetaTags } from 'react-meta-tags';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigationShow: false,
      signUpOpen: false,
      signInOpen: false,
      isScrollDown: this.getScrollTop() > 60,
    };
  }

  componentWillMount() {
    this.closeAuths();
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      this.setState({ isScrollDown: this.getScrollTop() > 60 });
    });
    const signUp = this.props.location.search === '?signup=true';
    const signIn = this.props.location.search === '?signin=true';

    if (signUp) {
      localStorage.setItem('signUp', 'open');
      this.setState({ signUpOpen: true });
    }
    if (signIn) {
      localStorage.setItem('signIn', 'open');
      this.setState({ signInOpen: true });
    }

    localStorage.getItem('signIn');
    localStorage.getItem('signUp');
  }

  componentDidUpdate(_prevProps, prevState) {
    const { signUpOpen, signInOpen } = this.state;

    if (signUpOpen && signUpOpen !== prevState.signUpOpen) {
      logEvent(OPEN_SIGNUP, { page: '' });
      unauthAuditProgress('', 'open_signup');
    }

    if (signInOpen && signInOpen !== prevState.signInOpen) {
      logEvent(OPEN_SIGNIN, { page: '' });
      unauthAuditProgress('', 'open_signin');
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.getScrollTop, false);
  }

  setLanguage = lang => {
    localStorage.setItem('selectedLanguage', lang);
    window.location.reload();
  };

  getScrollTop = () =>
    window.pageYOffset !== undefined
      ? window.pageYOffset
      : (document.documentElement || document.body.parentNode || document.body)
        .scrollTop;

  toggleElements = element => {
    this.setState(prevState => ({ [element]: !prevState[element] }));
    this.pushOver(element);
  };

  pushOver = element => {
    const browser = Bowser.getParser(window.navigator.userAgent);

    if (browser.getBrowser().name !== 'Safari') {
      if (element === 'signIn') {
        const amount = window.innerWidth < 400 ? window.innerWidth : 400;
        document.getElementById('___gatsby').style.right = `${amount}px`;
      } else if (element === 'signUp') {
        const amount = window.innerWidth < 600 ? window.innerWidth : 600;
        document.getElementById('___gatsby').style.right = `${amount}px`;
      }
    }
  };

  toggleElements = element => {
    this.setState(prevState => ({ [element]: !prevState[element] }));
    this.pushOver(element);
  };

  toggleSignInDrawer = () => {
    this.setState({
      navigationShow: false,
      signInOpen: true,
      signUpOpen: false,
    });
    localStorage.setItem('signIn', 'open');
    localStorage.setItem('signUp', 'close');
    this.pushOver('signIn');
    this.forceUpdate();
  };

  toggleSignUpDrawer = () => {
    this.setState({
      navigationShow: false,
      signInOpen: false,
      signUpOpen: true,
    });
    localStorage.setItem('signIn', 'close');
    localStorage.setItem('signUp', 'open');
    this.pushOver('signUp');
    this.forceUpdate();
  };

  closeAuths = () => {
    this.setState({
      signInOpen: false,
      signUpOpen: false,
    });
    localStorage.setItem('signIn', 'close');
    localStorage.setItem('signUp', 'close');
    document.getElementById('___gatsby').style.right = '0px';
    this.forceUpdate();
  };

  setLanguage = lang => {
    localStorage.setItem('selectedLanguage', lang);
    window.location.reload();
  };

  renderLinks = () => (
    <div className="nav-item-container">
      <div className="auth-buttons" style={{ width: '400px' }}>
        <div>
          <span
            className="nav-item"
            onClick={() => {
              this.setState({ navigationShow: false, signInOpen: true });
              localStorage.setItem('signUp', 'close');
            }}
            role="presentation"
          >
            <FormattedMessage id="navigation.signIn" defaultMessage="Sign In" />
          </span>
        </div>

        <button
          className="medium-blue-button"
          onClick={() => {
            this.setState({ navigationShow: false, signInOpen: false });
            localStorage.setItem('signUp', 'open');
          }}
        >
          <FormattedMessage
            id="navigation.createMyAccount"
            defaultMessage="Get Started"
          />
        </button>
      </div>
    </div>
  );

  render() {
    const { navigationShow, isScrollDown, signInOpen, signUpOpen } = this.state;
    const { location } = this.props;

    let navTheme = '';

    let inkblotLogo = inkblotLogoDark;
    if (location.pathname === '/avail' && !isScrollDown) {
      navTheme = 'navigation-bar-blue';
      inkblotLogo = inkblotLogoLight;
    }

    const signIn = localStorage.getItem('signIn') === 'open' || signInOpen;
    const signUp = localStorage.getItem('signUp') === 'open' || signUpOpen;

    const isFr = localStorage.getItem('selectedLanguage') === 'fr';
    return (
      <div>
        <MetaTags>
          <meta name="robots" content="noindex" />
        </MetaTags>
        <div
          className={`navigation-bar-container ${navTheme}`}
          style={{ marginTop: 0 }}
          role="navigation"
        >
          <Link
            to="/gsc-customer"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <img
              className="ink-logo"
              title="Inkblot"
              src={inkblotLogo}
              alt="Logo"
            />
            <img
              className="ink-logo-icon"
              title="Inkblot Icon"
              src={inkblotLogoIcon}
              alt="Logo Icon"
            />
            <img
              style={{ height: '51px', marginLeft: '44px' }}
              className="logo-icon"
              title="Omers"
              src={isFr ? ilobLogoFr : ilobLogo}
              alt="NSHN logo"
            />
          </Link>
          <div className="email-support" />
          <div className="ilob-bar-right">
            <div className="auth-buttons" style={{ width: '400px' }}>
              <div>
                <span
                  className="nav-item"
                  onClick={() => {
                    this.setState({ navigationShow: false, signInOpen: true });
                    localStorage.setItem('signUp', 'close');
                  }}
                  role="presentation"
                >
                  <FormattedMessage
                    id="navigation.signIn"
                    defaultMessage="Sign In"
                  />
                </span>
              </div>
              <button
                className="medium-blue-button"
                onClick={() => {
                  this.setState({ navigationShow: false, signInOpen: false });
                  localStorage.setItem('signUp', 'open');
                }}
              >
                <FormattedMessage
                  id="navigation.createMyAccount"
                  defaultMessage="Get Started"
                />
              </button>
              <div className="en-fr">
                {localStorage.getItem('selectedLanguage') === 'en' ? (
                  <div>
                    <span>EN</span>
                    <span
                      role="presentation"
                      onClick={() => this.setLanguage('fr')}
                      style={{ fontWeight: 300, marginLeft: '10px' }}
                    >
                      FR
                    </span>
                  </div>
                ) : (
                  <div>
                    <span
                      role="presentation"
                      onClick={() => this.setLanguage('en')}
                      style={{ fontWeight: 300, marginRight: '10px' }}
                    >
                      EN
                    </span>
                    <span>FR</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            style={navigationShow ? { display: 'block' } : { display: 'none' }}
            className="navigation-overlay-mobile"
          >
            <div
              role="presentation"
              onClick={() => this.toggleElements('navigationShow')}
            >
              <img className="navigation-mobile-close" src={close} alt="" />
            </div>
            <div
              className="navigation-overlay-mobile-content"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Link to="/">
                <img
                  className="ink-logo"
                  title="Inkblot"
                  src={inkblotLogoMobile}
                  alt="Logo"
                />
              </Link>
              {this.renderLinks()}
              <div className="en-fr">
                {localStorage.getItem('selectedLanguage') === 'en' ? (
                  <div>
                    <span>EN</span>
                    <span
                      role="presentation"
                      onClick={() => this.setLanguage('fr')}
                      style={{
                        fontWeight: 300,
                        cursor: 'pointer',
                        marginLeft: '20px',
                      }}
                    >
                      FR
                    </span>
                  </div>
                ) : (
                  <div>
                    <span
                      role="presentation"
                      onClick={() => this.setLanguage('en')}
                      style={{
                        fontWeight: 300,
                        cursor: 'pointer',
                        marginRight: '20px',
                      }}
                    >
                      EN
                    </span>
                    <span>FR</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <SignIn
            signInOpen={signIn}
            toggleSignInDrawer={() => this.closeAuths()}
            toggleSignUpDrawer={this.toggleSignUpDrawer}
          />
          <SignUp
            signUpOpen={signUp === true}
            toggleSignInDrawer={this.toggleSignInDrawer}
            toggleSignUpDrawer={() => this.closeAuths()}
            location={location}
          />
        </div>
      </div>
    );
  }
}

Navigation.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Navigation;
