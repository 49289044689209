import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { MARKETING_URL } from '../../utils/environment';

const Consent = ({ error, handleChangeCheckbox, company }) => (
  <div className="consent-check">
    <input
      type="checkbox"
      id="agree"
      name="consent"
      onChange={handleChangeCheckbox}
      aria-label="Terms and Conditions Agreement"
    />
    <label htmlFor="agree">
      <div className="terms-conditions">
        <FormattedHTMLMessage
          id="auth.consentTerms"
          defaultMessage={`<i> By creating an account, I agree to Inkblot's <a href='${MARKETING_URL}/conditions' target='_blank'> Terms and Conditions</a> and <a href='${MARKETING_URL}/privacy' target='_blank'> Privacy Policy </a> in their entirety. </i>`}
          values={{
            root_url: MARKETING_URL,
            url: `${company && company.toLowerCase() === 'benevity' ? `conditions?company=benevity` : `conditions`}`
          }}
        />
      </div>
      <div className="create-own">
        <FormattedMessage
          id="auth.createOwn"
          defaultMessage="You should create your own account for accessing Inkblot Therapy and
            keep your login and password information private. No one else should
            be able to access your account."
        />
      </div>
      <p className={`error-text ${error && error.privacyConsent ? '' : 'hidden'}`}>
        {error && error.privacyConsent}
      </p>
    </label>
  </div>
);

export default Consent;

