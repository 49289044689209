/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type, jsx-a11y/no-noninteractive-element-interactions */

import React, { Component } from 'react';
import { Link, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import queryString from 'query-string';
import inkblotLogoDark from '../../images/custom/logos/inkblot-logo-dark-blue.svg';
import inkblotLogoMobile from '../../images/custom/logos/inkblot-logo-mobile.svg';
import inkblotLogoEN from '../../images/custom/logos/inkblot-greenShield-logo-colour-en.png';
import inkblotLogoFR from '../../images/custom/logos/inkblot-greenShield-logo-colour-fr.png';
import { whichCompany, whichPathGetStarted } from '../../utils/pageUtils';
import { COUNTRY, OTHER_MARKETING_URL } from '../../utils/environment';
import CountrySelectModal from '../CountrySelectModal';
import CanadaFlag from '../../images/nav/canada-flag.png';
import UsFlag from '../../images/nav/us-flag.png';
import Hamburger from '../Hamburger';

// eslint-disable-next-line camelcase
const Store_Key_Country = 'selected-country-code';

class CustomNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigationShow: false,
      locale: localStorage.getItem('selectedLanguage'),
      countrySelectModalOpen: false,
      isScrollDown: this.getScrollTop() > 60,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const params = queryString.parse(location.search);
    const isCountrySelected = params.country_selected === 'true' || false;
    const company = whichCompany();

    window.addEventListener('scroll', () => {
      this.setState({ isScrollDown: this.getScrollTop() > 60 });
    });

    if (company.usOnly && COUNTRY === 'CA') {
      const sendingParams = {
        ...params,
        country_selected: true,
      };
      const sendingQuery = queryString.stringify(sendingParams);
      window.location.href = `${OTHER_MARKETING_URL}/${company.path}?${sendingQuery}`;
    }
    if (isCountrySelected) {
      localStorage.setItem(Store_Key_Country, COUNTRY);
      const sendingParams = { ...params };
      delete sendingParams.country_selected;
      const sendingQuery = queryString.stringify(sendingParams);
      navigate(
        sendingQuery
          ? `/${company.path}?${queryString.stringify(sendingParams)}`
          : `/${company.path}`,
      );
    } else {
      const storedCode = localStorage.getItem(Store_Key_Country);
      if (storedCode && storedCode !== COUNTRY) {
        const sendingParams = {
          ...params,
          country_selected: true,
        };
        const sendingQuery = queryString.stringify(sendingParams);
        window.location.href = sendingQuery
          ? `${OTHER_MARKETING_URL}/${company.path}?${sendingQuery}`
          : `${OTHER_MARKETING_URL}/${company.path}`;
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.getScrollTop, false);
  }

  getScrollTop = () =>
    window.pageYOffset !== undefined
      ? window.pageYOffset
      : (document.documentElement || document.body.parentNode || document.body)
          .scrollTop;

  setCountryCode = code => {
    const company = whichCompany();
    this.closeCountrySelectModal();
    localStorage.setItem(Store_Key_Country, code);
    if (code !== COUNTRY) {
      window.location.href = `${OTHER_MARKETING_URL}/${company.path}?country_selected=true`;
    }
  };

  openCountrySelectModal = () =>
    this.setState({ countrySelectModalOpen: true });

  closeCountrySelectModal = () =>
    this.setState({ countrySelectModalOpen: false });

  toggleElements = element => {
    this.setState(prevState => ({ [element]: !prevState[element] }));
  };

  toggleLocale = () => {
    const { locale } = this.state;
    localStorage.setItem('selectedLanguage', locale === 'fr' ? 'en' : 'fr');
    window.location.reload();
  };

  renderFlagButton = () => (
    <button
      aria-label="Country Switcher"
      className="country-flag"
      onClick={() => this.openCountrySelectModal()}
    >
      <img
        src={COUNTRY === 'US' ? UsFlag : CanadaFlag}
        alt="country flag"
        style={{ maxWidth: '50px' }}
      />
    </button>
  );

  renderLinks = () => {
    const company = whichCompany();
    const { locale } = this.state;
    return (
      <div
        style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}
      >
        {!company.hideSignIn && (
          <li
            onClick={() => this.setState({ navigationShow: false })}
            onKeyDown={() => this.setState({ navigationShow: false })}
          >
            <Link
              role="link"
              title="Sign In"
              to={`/sign_in?company=${company.path}`}
            >
              {this.props.intl.formatMessage({
                id: 'eap.signin',
                defaultMessage: 'Sign In',
              })}
            </Link>
          </li>
        )}
        {!company.hideSignUp && (
          <li
            onClick={() => this.setState({ navigationShow: false })}
            onKeyDown={() => this.setState({ navigationShow: false })}
          >
            <Link to={whichPathGetStarted()}>
              <div
                className="custom-button-blue"
                style={{ margin: '0 auto', cursor: 'pointer' }}
              >
                {this.props.intl.formatMessage({
                  id: 'eap.getStarted',
                  defaultMessage: 'Get Started',
                })}
              </div>
            </Link>
          </li>
        )}
        {company.showFlags && <li>{this.renderFlagButton()}</li>}
        {company.frenchPage && (
          <li className="en-fr">
            {locale === 'en' ? (
              <div>
                <span style={{ marginRight: '20px' }}>EN</span>
                <button
                  aria-label="Français"
                  onClick={() => this.toggleLocale()}
                  style={{
                    fontWeight: 300,
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                  }}
                >
                  FR
                </button>
              </div>
            ) : (
              <div>
                <button
                  aria-label="English"
                  onClick={() => this.toggleLocale()}
                  style={{
                    fontWeight: 300,
                    marginRight: '20px',
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                  }}
                >
                  EN
                </button>
                <span>FR</span>
              </div>
            )}
          </li>
        )}
      </div>
    );
  };

  renderLogos = () => {
    const { locale } = this.state;
    const company = whichCompany();
    const whichLogo = () => {
      const companyName = company.path;
      if (
        companyName === 'eiqc' ||
        companyName === 'gsc-access' ||
        companyName === 'gsc-eap'
      ) {
        return locale === 'fr' ? inkblotLogoFR : inkblotLogoEN;
      }
      return inkblotLogoDark;
    };
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          className="ink-logo"
          title="Inkblot"
          src={whichLogo()}
          alt="Logo"
          id="logoLarge"
        />
        <img
          className="ink-logo"
          title="Inkblot"
          src={inkblotLogoMobile}
          alt="Logo"
          id="logoSmall"
        />
        {this.renderTwoLogos(locale, company)}
        {!company.secondLogoNavHide &&
          company.secondLogo &&
          (locale === 'fr' && company.frSecondLogo ? (
            <img
              className={company.logoClass}
              src={company.frSecondLogo}
              alt={`${company.title} logo`}
            />
          ) : (
            <img
              className={
                !company.secondLogo
                  ? company.logoClass
                  : `${company.logoClass} second-custom-logo`
              }
              src={company.secondLogo}
              alt={`${company.title} logo`}
            />
          ))}
      </div>
    );
  };

  renderTwoLogos = (locale, company) => {
    if (locale === 'fr' && company.frLogo) {
      return (
        <img
          className={company.logoClass}
          src={company.frLogo}
          alt={`${company.title} logo`}
        />
      );
    }
    if (company.logo) {
      return (
        <img
          className={
            !company.secondLogo
              ? company.logoClass
              : `${company.logoClass} second-custom-logo`
          }
          src={company.logo}
          alt={`${company.title} logo`}
        />
      );
    }
  };

  render() {
    const { navigationShow, countrySelectModalOpen } = this.state;
    const company = whichCompany();
    return (
      <div>
        <div
          className="navigation-bar-container business-nav-white"
          style={{ marginTop: 0 }}
          role="navigation"
        >
          <Link to={`/${company.path}`}>{this.renderLogos()}</Link>
          <div>
            <ul
              role="presentation"
              className="navigation-bar-right navigation-bar-right-custom"
              style={{ marginLeft: '-70px', marginTop: '10px' }}
            >
              {this.renderLinks()}
            </ul>
          </div>
          <Hamburger onClick={() => this.toggleElements('navigationShow')} />
          <div
            style={navigationShow ? { display: 'block' } : { display: 'none' }}
            className="navigation-overlay-mobile"
          >
            <div
              role="presentation"
              onClick={() => this.toggleElements('navigationShow')}
            >
              <span className="navigation-mobile-close">X</span>
            </div>
            <ul className="navigation-overlay-mobile-content custom-mobile-nav">
              {this.renderLinks()}
            </ul>
          </div>
          {countrySelectModalOpen && (
            <CountrySelectModal
              onSelect={this.setCountryCode}
              onClose={this.closeCountrySelectModal}
            />
          )}
        </div>
      </div>
    );
  }
}

CustomNavigation.propTypes = {
  intl: PropTypes.object,
  location: PropTypes.object.isRequired,
};

CustomNavigation.defaultProps = {
  intl: {},
};

export default injectIntl(CustomNavigation);
