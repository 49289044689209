export const signUpData = [
  {
    id: 1,
    text: 'auth.firstName',
    defaultText: 'John',
    type: 'text',
    name: 'first_name',
  },
  {
    id: 2,
    text: 'auth.lastName',
    defaultText: 'Smith',
    type: 'text',
    name: 'last_name',
  },
  {
    id: 3,
    text: 'auth.dateOfBirth',
    defaultText: 'DD/MM/YYYY',
    type: 'text',
    name: 'dob',
  },
  {
    id: 4,
    text: 'auth.email',
    defaultText: 'example@email.com',
    type: 'email',
    name: 'email',
    fullWidth: true,
  },
  {
    id: 5,
    text: 'auth.password',
    defaultText: '**********',
    type: 'password',
    name: 'password',
  },
  {
    id: 6,
    text: 'auth.retypePassword',
    defaultText: '**********',
    type: 'password',
    name: 'password_confirm',
  },
  {
    id: 7,
    text: 'auth.custom',
    defaultText: '',
    type: 'text',
    name: 'custom_gender',
  },
];

export const radioData = [
  {
    id: 1,
    label: 'auth.male',
    radioValue: 'male',
    defaultMessage: 'Male',
  },
  {
    id: 2,
    label: 'auth.female',
    radioValue: 'female',
    defaultMessage: 'Female',
  },
  {
    id: 3,
    label: 'auth.custom',
    radioValue: 'custom',
    defaultMessage: 'Custom',

  },
  {
    id: 4,
    label: 'auth.unspecified',
    radioValue: 'unspecified',
    defaultMessage: 'Prefer not to say',
  },

];

export const atbSignupData = [
  {
    id: 1,
    text: 'eap.firstName',
    type: 'text',
    name: 'first_name',
    defaultText: 'First Name',
  },
  {
    id: 2,
    text: 'eap.lastName',
    type: 'text',
    name: 'last_name',
    defaultText: 'Last Name',
  },
  {
    id: 3,
    text: 'eap.email',
    type: 'email',
    name: 'email',
    defaultText: 'Email',
    fullWidth: true,
  },
  {
    id: 4,
    text: 'eap.employeeId',
    type: 'text',
    name: 'employee_id',
    defaultText: 'Employee ID',
    fullWidth: true,
  },
  {
    id: 5,
    text: 'eap.password',
    type: 'password',
    name: 'password',
    defaultText: 'Password',
  },
  {
    id: 6,
    text: 'eap.confirmPassword',
    type: 'password',
    name: 'password_confirm',
    defaultText: 'Retype Password',
  },
];

export const employeeIdData = [
  {
    id: 1,
    text: 'eap.email',
    type: 'email',
    name: 'email',
    defaultText: 'Email',
    fullWidth: true,
  },
  {
    id: 2,
    text: 'eap.employeeId',
    type: 'text',
    name: 'employee_id',
    defaultText: 'Employee ID',
    fullWidth: true,
  },
  {
    id: 3,
    text: 'eap.password',
    type: 'password',
    name: 'password',
    defaultText: 'Password',
  },
  {
    id: 4,
    text: 'eap.confirmPassword',
    type: 'password',
    name: 'password_confirm',
    defaultText: 'Retype Password',
  },
];

export const minSignupData = [
  {
    id: 1,
    text: 'eap.firstName',
    type: 'text',
    name: 'first_name',
    defaultText: 'First Name',
  },
  {
    id: 2,
    text: 'eap.lastName',
    type: 'text',
    name: 'last_name',
    defaultText: 'Last Name',
  },
  {
    id: 3,
    text: 'eap.email',
    type: 'email',
    name: 'email',
    defaultText: 'Email',
    fullWidth: true,
  },
  {
    id: 4,
    text: 'eap.password',
    type: 'password',
    name: 'password',
    defaultText: 'Password',
  },
  {
    id: 5,
    text: 'eap.confirmPassword',
    type: 'password',
    name: 'password_confirm',
    defaultText: 'Retype Password',
  },
];

export const indigoSignupData = [
  {
    id: 1,
    text: 'eap.firstName',
    type: 'text',
    name: 'first_name',
    defaultText: 'First Name',
  },
  {
    id: 2,
    text: 'eap.lastName',
    type: 'text',
    name: 'last_name',
    defaultText: 'Last Name',
  },
  {
    id: 3,
    text: 'auth.dateOfBirth',
    defaultText: 'DD/MM/YYYY',
    type: 'text',
    name: 'dob',
  },
  {
    id: 4,
    text: 'eap.email',
    type: 'email',
    name: 'email',
    defaultText: 'Email',
  },
  {
    id: 5,
    text: 'eap.password',
    type: 'password',
    name: 'password',
    defaultText: 'Password',
  },
  {
    id: 6,
    text: 'eap.confirmPassword',
    type: 'password',
    name: 'password_confirm',
    defaultText: 'Retype Password',
  },
];
