import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { IntlProvider, addLocaleData } from 'react-intl';
import { HelmetProvider } from 'react-helmet-async';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import enCa from '../translations/en-CA.json';
import frCa from '../translations/fr-CA.json';
import Navigation from './Navigation';
import Footer from './Footer';
import CustomNavigation from './custom/CustomNavigation';
import GallivanNavigation from './gallivan/Navigation';
import GallivanFooter from './gallivan/Footer';
import MeditationNavigation from './meditation/Navigation';
import BusinessNavigation from '../pagePartials/business/BusinessNavigation';
import EventsNavigation from '../pagePartials/business/EventsNavigation';
import LeslieNavigation from './Leslie/Navigation';
import OmersNavigation from './Omers/Navigation';
import CFSNavigation from './CFS/Navigation';
import ILOBNavigation from './ILOB/Navigation';
import {
  isGallivanPage,
  whichCompany,
  isMLF,
  isLeslie,
  isOmers,
  isCFS,
  isNSHN,
  isILOB,
  isPPCHealthTelus,
  isPPCHealthMaple,
  isPCMCHCHealthTelushealth,
  isPPCHealthAkira,
  isCSA,
  isPPCHealthLifeInc,
  isPPCHealthBCI,
  isATB,
  isCorporateNav,
  parseLocaleFromPath,
} from '../utils/pageUtils';
import { isBusiness, isEvents } from '../pagePartials/business';
import '../styles/index.scss';
import SEO from '../utils/SEO';
import { initAmplitude } from '../utils/amplitude';
import InjectComm100 from '../utils/InjectComm100';

const renderLayouts = (children, location) => {
  if (
    [
      'forgot_password',
      'reset_password',
      'onboarding',
      'sign_in',
      'sign_up',
      'dependants',
      'two_factor',
      'mobileapp',
    ].includes(location.pathname.split('/')[1])
  ) {
    return <div>{children}</div>;
  }

  if (whichCompany().path !== '') {
    return (
      <div>
        <CustomNavigation location={location} />
        {children}
        <Footer />
      </div>
    );
  }

  if (isGallivanPage(location.pathname)) {
    return (
      <div>
        <GallivanNavigation />
        {children}
        <GallivanFooter />
      </div>
    );
  }

  if (isBusiness(location.pathname)) {
    return (
      <div>
        <BusinessNavigation location={location} />
        {children}
        <Footer />
      </div>
    );
  }

  if (isEvents(location.pathname)) {
    return (
      <div>
        <EventsNavigation />
        {children}
        <Footer />
      </div>
    );
  }

  if (location.pathname.split('/')[1] === 'meditation') {
    return (
      <div>
        <MeditationNavigation location={location} />
        {children}
        <Footer />
      </div>
    );
  }

  if (location.pathname.split('/')[1] === 'avail') {
    return (
      <div>
        <Navigation location={location} />
        {children}
        <Footer />
      </div>
    );
  }

  if (isLeslie(location.pathname)) {
    return (
      <div>
        <LeslieNavigation location={location} />
        {children}
        <Footer />
      </div>
    );
  }
  if (isOmers(location.pathname)) {
    return (
      <div>
        <OmersNavigation location={location} />
        {children}
        <Footer />
      </div>
    );
  }

  if (isCFS(location.pathname) || isNSHN(location.pathname)) {
    return (
      <div>
        <CFSNavigation location={location} />
        {children}
        <Footer />
      </div>
    );
  }

  if (isILOB(location.pathname)) {
    return (
      <div>
        <ILOBNavigation location={location} />
        {children}
        <Footer />
      </div>
    );
  }

  if (isMLF(location.pathname)) {
    return <div>{children}</div>;
  }
  if (
    isPPCHealthTelus(location.pathname) ||
    isPPCHealthMaple(location.pathname) ||
    isCSA(location.pathname) ||
    isPPCHealthLifeInc(location.pathname) ||
    isPPCHealthBCI(location.pathname) ||
    isATB(location.pathname) ||
    isCorporateNav(location.pathname) ||
    isPPCHealthAkira(location.pathname) ||
    isPCMCHCHealthTelushealth(location.pathname)
  ) {
    return <div>{children}</div>;
  }

  // default
  return (
    <div>
      <Navigation location={location} />
      {children}
      <Footer />
    </div>
  );
};

class Layout extends React.Component {
  constructor() {
    super();

    if (typeof window !== 'undefined') {
      const locale = parseLocaleFromPath();
      if (locale && localStorage.getItem('selectedLanguage') === null) {
        localStorage.setItem('selectedLanguage', locale);
        localStorage.setItem('language', locale); // comm100 custom var
      } else if (localStorage.getItem('selectedLanguage') === null) {
        localStorage.setItem('selectedLanguage', 'en');
        localStorage.setItem('language', 'en'); // comm100 custom var
      }
    }
  }

  componentDidMount() {
    initAmplitude();
  }

  render() {
    const { children, location } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={() => {
          if (typeof window !== 'undefined') {
            addLocaleData([...en, ...fr]);

            const languagePreference = localStorage.getItem('selectedLanguage');
            let locale = 'en';
            let messages = enCa;

            if (languagePreference === 'en' || null) {
              locale = 'en';
              messages = enCa;
            }

            if (languagePreference === 'fr') {
              locale = 'fr';
              messages = frCa;
            }

            return (
              <div>
                <noscript>
                  <iframe
                    src="https://www.googletagmanager.com/ns.html?id=GTM-PPKWFTX"
                    height="0"
                    width="0"
                    style="display:none;visibility:hidden"
                  ></iframe>
                </noscript>
                {typeof window !== 'undefined' && (
                  <HelmetProvider>
                    <div className="layout-container">
                      <SEO
                        pathname={location.pathname.split('/')[1]}
                        lang={locale}
                      />
                      <IntlProvider locale={locale} messages={messages}>
                        {renderLayouts(children, location)}
                      </IntlProvider>
                    </div>
                  </HelmetProvider>
                )}
                <InjectComm100 />
              </div>
            );
          }
          return <div />;
        }}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  location: PropTypes.object.isRequired,
};

export default Layout;
