import React from 'react';
import { FormattedMessage } from 'react-intl';

export const validEmail = /^[A-Z0-9.'_%+-]+@[A-Z0-9.éëêèâàæôœùûüçîïÿ-]+\.[A-Z]{2,15}$/i;

export const normalizeDate = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length <= 2) {
    return onlyNums;
  }

  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2)}`;
  }

  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(
    4,
    8,
  )}`;
};

export const validateBirthday = dateString => {
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
    return (
      <FormattedMessage
        id="form.invalidDate"
        defaultMessage="Not a valid date"
      />
    );
  }

  // Parse the date parts to integers
  const parts = dateString.split('/');
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[0], 10);
  const year = parseInt(parts[2], 10);

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  // Check range of year
  if (year < 1880 || year > new Date().getFullYear()) {
    return (
      <FormattedMessage
        id="yearOutOfRange"
        defaultMessage="Year is out of range"
      />
    );
  }

  // Check the ranges of month
  if (month === 0 || month > 12) {
    return (
      <FormattedMessage
        id="monthOutOfRange"
        defaultMessage="Month is out of range"
      />
    );
  }

  // Check the range of the day
  if (!(day > 0 && day <= monthLength[month - 1])) {
    return (
      <FormattedMessage
        id="dayOutOfRange"
        defaultMessage="Day is out of range"
      />
    );
  }

  return false;
};
