import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CloseSvg from '../../images/close-banner.svg'
import { FormattedHTMLMessage } from 'react-intl';

const MEDI_SUPPORTED_LANG_BANNER_KEY = 'medi_supported_lang_banner_close';

class SupportedLangBanner extends Component {
  constructor(props) {
    super(props);

    const lastTime = localStorage.getItem(MEDI_SUPPORTED_LANG_BANNER_KEY);
    // 6 hours
    if (lastTime && (Date.now() - lastTime) < 1000 * 60 * 60 * 6) {
      props.setHeight(0);
    }
  }

  closeBanner = () => {
    localStorage.setItem(MEDI_SUPPORTED_LANG_BANNER_KEY, Date.now());
    this.props.setHeight(0);
  };

  render() {
    const { height } = this.props;

    return (
      <div className="temp-top-banner" style={{ height }}>
        <div className="ttb-contents">
          <FormattedHTMLMessage
              tagName="p"
              id="meditation.supportedLangBannerMain"
              defaultMessage="Inkblot Meditation sessions are only available in English at this time. Les séances de Méditation Inkblot ne sont présentement disponible qu’en anglais."
          />
        </div>
        <div
          className="ttb-close-button"
          onClick={() => this.closeBanner()}
          role="button"
          tabIndex="0"
        >
          <img src={CloseSvg} alt="close_button" />
        </div>
      </div>
    );
  }
}

SupportedLangBanner.propTypes = {
  height: PropTypes.number.isRequired,
  setHeight: PropTypes.func.isRequired,
};

export default SupportedLangBanner;
