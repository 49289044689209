import React from 'react';
import { Link } from 'gatsby';

import { whichSchool, unauthAuditProgress } from '../../utils/pageUtils';
import { logEvent, OPEN_SIGNUP } from '../../utils/amplitude';

const school = whichSchool();

const toggleSignUp = () => {
  localStorage.setItem('signUp', 'open');
  logEvent(OPEN_SIGNUP, school.key);
  unauthAuditProgress(school.key, 'open_signup');
  window.scrollTo({
    top: window.pageYOffset - 1,
    behavior: 'smooth',
  });
};

const GallivanFooter = () => (
  <div className="gallivan-footer-container" role="contentinfo">
    <p className="gallivan-header">
      Take the first step towards feeling better.
    </p>
    <button
      title="Find a Counsellor"
      className="gallivan-button-teal"
      onClick={() => toggleSignUp()}
    >
      <span>Find a Counsellor</span>
    </button>

    <div className="row">
      <div className="col-7">
        <ul>
          <li>
            <Link to={`/${school.key}/about`} title="About">
              About
            </Link>
          </li>
          <li>
            <Link to={`/${school.key}/faq`} title="FAQ">
              FAQ
            </Link>
          </li>
          {school.key !== 'greatplains' && (
            <li>
              <a
                href={school.insuranceInformationUrl}
                title="Insurance Information"
              >
                Insurance Information
              </a>
            </li>
          )}
        </ul>
      </div>
      <div className="col-5">
        <h6>
          © {new Date().getFullYear()} | Gallivan | a People Corporation Company
          | All rights reserved.
        </h6>
      </div>
    </div>
  </div>
);

export default GallivanFooter;
