import React, { Component } from 'react';
import { Link } from 'gatsby';
import inkblotLogoLight from '../../images/_inkblot-logo-light.png';
import inkblotLogoDark from '../../images/_inkblot-logo-dark.png';

const getScrollTop = () =>
  (window.pageYOffset !== undefined
    ? window.pageYOffset
    : (document.documentElement || document.body.parentNode || document.body).scrollTop);

class EventNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrollDown: getScrollTop() > 60,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      this.setState({ isScrollDown: getScrollTop() > 60 });
    });
  }

  render() {
    const { isScrollDown } = this.state;
    let navTheme = '';
    let inkblotLogo = inkblotLogoLight;
    if (isScrollDown) {
      navTheme = 'business-nav-white';
      inkblotLogo = inkblotLogoDark;
    }
    return (
      <div
        className={`navigation-bar-container business-nav evt-mix-nav ${navTheme}`}
        style={{ background: 'transparent' }}
        role="navigation"
      >
        <Link to="/">
          <img
            className="ink-logo"
            title="Inkblot"
            src={inkblotLogo}
            alt="Logo"
          />
        </Link>
        <a href="https://www.eventbrite.ca/e/employee-wellness-marketplace-mixer-tickets-51488140414">
          <button className="light-blue-button">Register</button>
        </a>
      </div>
    );
  }
}

export default EventNavigation;
