import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Scroll from 'react-scroll-to-element';
import { FormattedMessage } from 'react-intl';
import inkblotLogoLight from '../../images/_inkblot-logo-light.png';
import inkblotLogoDark from '../../images/_inkblot-logo-dark.png';
import HamburgerBlack from '../../images/_hamburger.svg';
import HamburgerWhite from '../../images/_hamburger-white.svg';
import close from '../../images/x.svg';
import Hamburger from '../../components/Hamburger';

const navItems = [
  {
    key: 'navigation.ourServices',
    defaultMessage: 'Our Services',
    linkTo: '/our_services',
  },
  { key: 'navigation.about', defaultMessage: 'About', linkTo: '/about' },
  {
    key: 'navigation.business',
    defaultMessage: 'Business',
    linkTo: '/business',
  },
];

class BusinessNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigationShow: false,
      isScrollDown: this.getScrollTop() > 60,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      this.setState({ isScrollDown: this.getScrollTop() > 60 });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.getScrollTop, false);
  }

  getScrollTop = () =>
    (window.pageYOffset !== undefined
      ? window.pageYOffset
      : (document.documentElement || document.body.parentNode || document.body).scrollTop);

  setLanguage = lang => {
    localStorage.setItem('selectedLanguage', lang);
    location.reload();
  };

  toggleElements = element => {
    this.setState(prevState => ({ [element]: !prevState[element] }));
  };

  renderLinks = () => (
    <div className="nav-item-container">
      {navItems.map((item, i) => (
        <div key={`key-${i + 1}`}>
          <Link
            title={item.label}
            to={item.linkTo}
            onClick={() => this.setState({ navigationShow: false })}
          >
            <FormattedMessage
              id={item.key}
              defaultMessage={item.defaultMessage}
            />
          </Link>
        </div>
      ))}
      <div>
        <a title="Sign In" href="https://ink.inkblottherapy.com/?signin=true">
          <span className="nav-item">
            <FormattedMessage id="navigation.signIn" defaultMessage="Sign In" />
          </span>
        </a>
      </div>
      <Scroll type="id" element="email">
        <a>
          <button className="light-blue-button">
            <FormattedMessage
              id="navigation.requestInformation"
              defaultMessage="Request information"
            />
          </button>
        </a>
      </Scroll>
      <div className="localeSelectors">
        <span
          className="nav-item"
          role="presentation"
          onClick={() => this.setLanguage('en')}
        >
          EN
        </span>
        <span
          className="nav-item"
          role="presentation"
          onClick={() => this.setLanguage('fr')}
        >
          FR
        </span>
      </div>
    </div>
  );

  render() {
    const { navigationShow, isScrollDown } = this.state;

    let navTheme = 'business-nav-white';
    let hamburger = HamburgerBlack;
    let inkblotLogo = inkblotLogoDark;
    if (!isScrollDown) {
      navTheme = 'business-nav';
      inkblotLogo = inkblotLogoLight;
      hamburger = HamburgerWhite;
    }

    return (
      <div
        className={`navigation-bar-container ${navTheme} `}
        role="navigation"
      >
        <Link to="/">
          <img
            className="ink-logo"
            title="Inkblot"
            src={inkblotLogo}
            alt="Logo"
          />
        </Link>
        <div className="navigation-bar-right">{this.renderLinks()}</div>
        <Hamburger
          onClick={() => this.toggleElements('navigationShow')}
          customHamburger={hamburger}
        />
        <div
          style={navigationShow ? { display: 'block' } : { display: 'none' }}
          className="navigation-overlay-mobile"
        >
          <div
            role="presentation"
            onClick={() => this.toggleElements('navigationShow')}
          >
            <img className="navigation-mobile-close" src={close} alt="" />
          </div>
          <div className="navigation-overlay-mobile-content">
            {this.renderLinks()}
          </div>
        </div>
      </div>
    );
  }
}

BusinessNavigation.propTypes = {
  location: PropTypes.object.isRequired,
};

export default BusinessNavigation;
