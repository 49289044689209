import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import X from '../../images/dark-x.svg';
import MailchimpSignup from './MailchimpSignUp';

const SignUp = ({ toggleSignUpDrawer, signUpOpen }) => {
  const hasScrollbar =
    window.innerWidth > document.documentElement.clientWidth;

  // eslint-disable-next-line
  const authStyle = signUpOpen
    ? hasScrollbar
      ? { right: '-15px' }
      : { right: '0px' }
    : hasScrollbar
      ? { right: '-615px', boxShadow: 'none' }
      : { right: '-600px', boxShadow: 'none' };

  return (
    <div>
      <div className="auth" style={authStyle}>
        <div className="sign-up">
          <h1 className="title">
            <FormattedMessage id="meditation.register" defaultMessage="Register" />
          </h1>
          <MailchimpSignup />
          <div
            className="close"
            onClick={toggleSignUpDrawer}
            role="presentation"
          >
            <img src={X} alt="close" />
          </div>
        </div>
      </div>
      <div
        className="overlay"
        onClick={toggleSignUpDrawer}
        role="presentation"
        style={
          signUpOpen
            ? { visibility: 'visible', opacity: '1', right: '600px' }
            : { visibility: 'hidden', opacity: '0', right: 0 }
        }
      />
    </div>
  );
};

SignUp.propTypes = {
  toggleSignUpDrawer: PropTypes.func.isRequired,
  signUpOpen: PropTypes.bool.isRequired,
};

export default SignUp;
