export const whatWeTreat = [
  {
    id: 'depression',
    name: 'Depression',
    link: '/depression',
  },
  {
    id: 'anxiety',
    name: 'Anxiety',
    link: '/anxiety',
  },
  {
    id: 'greif',
    name: 'Greif',
    link: '/greif',
  },
  {
    id: 'stress',
    name: 'Stress',
    link: '/stress',
  },
  {
    id: 'substanceAbuseDisorder',
    name: 'Substance abuse disorder',
    link: '/substance-abuse-disorder',
  },
  {
    id: 'postpartumDepression',
    name: 'Postpartum Depression & anxiety',
    link: '/postpartum-depression',
  },
  {
    id: 'eatingDisorder',
    name: 'Eating disorder',
    link: '/eating-disorder',
  },
  {
    id: 'bipolarDisorder',
    name: 'Bipolar disorder',
    link: '/bipolar-disorder',
  },
  {
    id: 'obsessiveCompulsiveDisorder',
    name: 'Obsessive compulsive disorder',
    link: '/obsessive-compulsive-disorder',
  },
  {
    id: 'borderlinePersonalityDisorder',
    name: 'Borderline personality disorder',
    link: '/borderline-personality-disorder',
  },
  {
    id: 'postTraumaticStressDisorder',
    name: 'Post traumatic stress disorder',
    link: '/post-traumatic-stress-disorder',
  },
  {
    id: 'ADHD',
    name: 'ADHD',
    link: '/ADHD',
  },
];

export const aboutUs = [
  {
    id: 'about',
    name: 'About',
    link: '/about',
    frLink: '/about',
  },
  {
    id: 'careers',
    name: 'Careers',
    link: '/careers',
    frLink: '/careers',
  },
  {
    id: 'helpCentre',
    name: 'Help Centre',
    link: 'https://inkblot.zendesk.com/hc/en-us',
    frLink: 'https://inkblot.zendesk.com/hc/fr-ca',
  },
  {
    id: 'accessibilityStatement',
    name: 'Accessibility Statement',
    link: '/accessibility-statement',
    frLink: '/accessibility-statement',
  },
];

export const legal = [
  // {
  //   id: 'useYourData',
  //   name: 'How we use your data',
  //   link: '/your-data',
  // },
  {
    id: 'privacyPolicy',
    name: 'Privacy Policy',
    link: '/privacy',
  },
  {
    id: 'termsAndConditions',
    name: 'Terms And Conditions',
    link: '/conditions',
  },
];

export const ourServices = [
  {
    id: 'services',
    name: 'Services',
    link: '/services',
  },
  {
    id: 'stepsToCare',
    name: 'Steps to Care',
    link: '/steps-to-care',
  },
  {
    id: 'mentalHealthAssessment',
    name: 'Mental Health Assessment',
    link: '/mental-health-assessment',
  },
];

export const learning = [
  // {
  //   id: 'discovery',
  //   name: 'Discovery',
  //   link: '/discovery',
  // },
  {
    id: 'blog',
    name: 'Blog',
    link: '/blog',
  },
];
